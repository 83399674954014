import React, { useState, useRef } from "react";
import {
  IonText,
  useIonPopover,
  IonList,
  IonItem,
  IonSpinner
} from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Uploader,
  Alert
} from "rsuite";
import { useHistory, Link } from "react-router-dom";
import "./Status.css";
import Swal from "sweetalert2";
import { API_URL } from "../../config/index";
// components
import Sidebar from "../../components/template/sidebar/Sidebar";

//redux
import { connect } from "react-redux";
import {createStatus, deleteStatus, changeStatus} from "../../stores/status/actions";

const PopoverList: React.FC<{
  onHide: () => void;
  history: any;
  handleDeleteStatus: Function;
  handleChangeStatus: Function;
  status: any
}> = ({ onHide, history, handleDeleteStatus, handleChangeStatus, status }) => {
  return (
    <IonList>
      <IonItem
        onClick={() => {
          history.push(`/admin/edit-status/${status._id}`);
          onHide();
        }}
        button
      >
        Edit
      </IonItem>
      <IonItem
        onClick={() => {
          handleChangeStatus();
        }}
        button
      >
        {status?.isDisabled ? "Enable" : "Disable"}
      </IonItem>
      <IonItem
        onClick={() => {
          handleDeleteStatus();
        }}
        button
      >
        Delete
      </IonItem>
    </IonList>
  );
};

const Status: React.FC<{
  statuses: any[];
  createStatus: Function;
  deleteStatus: Function;
  changeStatus: Function;
}> = ({ statuses, createStatus, deleteStatus, changeStatus }) => {
  const fileRef = useRef<any>(null);
  const history = useHistory();
  const [selectedStatus, setSelectedStatus] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>(null);
  const [imageKey, setImageKey] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    history,
    handleDeleteStatus: () => handleDeleteStatus(),
    handleChangeStatus: () => handleChangeStatus(),
    status: selectedStatus
  });
  const handleCreateStatus = async() =>{
    try{
      setBtnLoading(true);
      if(image){
        let formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);
        await createStatus(formData);
        setName("");
        setImage(null);
        fileRef.current.handleRemoveFile(imageKey);
        Alert.success("Status created successfully")
      }else{
        Alert.error("Status image is required")
      }
    }catch(err){
      console.log(err)
      Alert.error("Please try again with some different values")
    }finally{
      setBtnLoading(false);
    }
  }
  const handleDeleteStatus = () =>{
    try{
      Swal.fire({
        title: 'Do you want to delete this status?',
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then(async(result) => {
        if (result.isConfirmed) {
          await deleteStatus(selectedStatus._id);
        }
      }).catch((err)=>{
        let message = typeof err?.response?.data?.message === "string" ? err?.response?.data?.message : "Something went wrong!";
        Alert.error(message)
      })
    }catch(err){
      Alert.error("Something went wrong!")
    }finally{
      dismiss();
    }
  }
  const handleChangeStatus = async() =>{
    try{
      await changeStatus(selectedStatus._id);
      Alert.success("Status changed successfully");
    }catch(err){
      Alert.error("Something went wrong!")
    }finally{
      dismiss();
    }
  }
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <Sidebar title="Status" />
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid
            className="subHeader"
            justify="space-between"
            alignItems="center"
          >
            <IonText
              className="subHeaderTextAddLeads"
              style={{ width: "100%" }}
            >
              STATUS
            </IonText>
            {/* <Link to="/admin/add-status">
              <Button color="blue" style={{marginBottom: 10}} >New</Button>
            </Link> */}
          </FlexboxGrid>
          <FlexboxGrid className="w-100">
            <FlexboxGrid
              className="status-wrapper"
              justify="start"
              alignment="center"
              style={{ flex: 1 }}
            >
              {statuses.map((status, i) => (
                <FlexboxGrid
                  justify="center"
                  className="admin-tag-btn-wrapper"
                  onClick={(e: any) =>{
                    setSelectedStatus(status)
                    present({
                      event: e.nativeEvent,
                    })
                  }}
                  key={i}
                >
                  <img
                    src={`${API_URL}${status.image}`}
                    style={{
                      width: 40,
                      height: 40,
                      marginBottom: 5,
                      borderRadius: "50%",
                      opacity: status.isDisabled ? 0.5 : 1
                    }}
                  />
                  <IonText>{status.name}</IonText>
                </FlexboxGrid>
              ))}
            </FlexboxGrid>
            <FlexboxGrid style={{ flex: 1 }}>
              {/* <IonText className="subHeaderTextAddLeads">ADD NOTIFICATIONS</IonText> */}
              <Form 
                className="w-100 formCard" 
                style={{ marginBottom: 20 }} 
                onSubmit={handleCreateStatus}
              >
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">
                    Upload Photo
                  </ControlLabel>
                  <Uploader
                    accept=".png,.gif,.jpg,.jpeg,.webp"
                    listType="picture-text"
                    multiple={false}
                    autoUpload={false}
                    draggable
                    onChange={(e)=>{
                      setImageKey(e[0]?.fileKey as string);
                      setImage(e[0]?.blobFile)
                    }}
                    ref={(ref:any) => {
                     fileRef.current = ref;
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        color: "#aaa",
                        paddingTop: 100,
                        paddingBottom: 100,
                      }}
                    >
                      Click or Drag files to this area to upload
                    </div>
                  </Uploader>
                </FormGroup>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">
                    Add Status Name
                  </ControlLabel>
                  <FormControl 
                    name="text" 
                    type="text" 
                    className="input" 
                    value={name}
                    onChange={(value)=>setName(value)}
                    required
                  />
                </FormGroup>
              {/* */}
              <FlexboxGrid
                justify="center"
                className="w-100"
                style={{ margin: "15px 0px" }}
              >
                <Button 
                  color="blue" 
                  className="btn"
                  type="submit"
                  disabled={btnLoading}
                >
                  {btnLoading ? <IonSpinner name="dots" color="#fff"/> : "Add"}
                </Button>
              </FlexboxGrid>
              </Form>
            </FlexboxGrid>
          </FlexboxGrid>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
});
const mapDispatchToProps = {
  createStatus,
  deleteStatus,
  changeStatus
};
export default connect(mapStateToProps, mapDispatchToProps)(Status);
import { LEADS_LOADING, LEADS_SET_DATA, LEADS_SET_DETAILS, LEADS_REMOVE_LEAD, LEADS_SET_FILTERED_LEADS, LEADS_SET_APPOINTMENTS, LEADS_SET_LOCATION_PREDICTION, LEADS_SET_MAP_LEADS, LEADS_SET_MAP_FILTERS, LEADS_SET_PAGE, LEADS_SET_ALL_DATA } from "./constants";
const initialState = {
  loading: false,
  leads: [],
  lead: {},
  filteredLeads: [],
  appointments: [],
  locationPredictions: [],
  mapLeads: [],
  mapFilters: [],
  mapSellerId: "",
  allLeads: [],
  count: 0,
  activePage: 1,
  limit: 50,
  offset: 0,
  showUserLocations: false
};
export function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case LEADS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LEADS_SET_DATA:
      return {
        ...state,
        leads: action.payload,
      };
    case LEADS_SET_DETAILS:
      return {
        ...state,
        lead: action.payload,
      };
    case LEADS_REMOVE_LEAD:
      return {
        ...state,
        leads: [...state.leads.filter(lead => lead._id !== action.payload)],
        allLeads: [...state.allLeads.filter(lead => lead._id !== action.payload)],
      };
    case LEADS_SET_FILTERED_LEADS:
      return {
        ...state,
        filteredLeads: action.payload,
      };
    case LEADS_SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case LEADS_SET_LOCATION_PREDICTION:
      return {
        ...state,
        locationPredictions: action.payload,
      };
    case LEADS_SET_MAP_LEADS:
      return {
        ...state,
        mapLeads: action.payload,
      };
    case LEADS_SET_MAP_FILTERS:
      return {
        ...state,
        mapFilters: action.payload.statuses,
        mapSellerId: action.payload.sellerId,
        showUserLocations: action.payload.isUserLocationOn
      };
    case LEADS_SET_PAGE:
      return {
        ...state,
        activePage: action.payload.activePage,
        offset: action.payload.offset,
        limit: action.payload.limit
      };
    case LEADS_SET_ALL_DATA:
      return {
        ...state,
        allLeads: action.payload.leads,
        count: action.payload.count,
      };
    default:
      return state;
  }
}

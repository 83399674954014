import axios from "axios";
import { API_URL } from "../../config";
export function _fetchStatuses(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}status/list`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}status/add`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _deleteStatus(id) {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}status/delete?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _changeStatus(id) {
  return new Promise((resolve, reject) => {
    axios
    .put(`${API_URL}status/enable?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _editStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}status/edit`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
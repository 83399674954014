import React, { useState } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  Divider,
  IconButton,
  Icon,
  Checkbox,
  DatePicker,
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "./ChnagePassword.css";

//redux
import { connect } from "react-redux";
import { changePassword } from "../../stores/profile/actions";

const ChnagePassword: React.FC<{
  changePassword: Function;
  profile: any;
}> = ({ changePassword, profile }) => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    btnLoading: false,
  })
  const [error, setError] = useState("")
  const handleChangePassword = async () => {
    try {
      if(state.newPassword !== state.confirmPassword){
        setError("Confirm password is not matching with new password");
        return;
      }
      setState({ ...state, btnLoading: true })
      await changePassword({...state, email: profile.email});
      Swal.fire("Password changed successfully", "", "success").then(()=>{
        setError("");
        setState({
          ...state,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      });
      
    } catch (err:any) {
      if(err?.response?.data?.message){
        setError(err?.response?.data?.message);
      }
    } finally {
      setState({ ...state, btnLoading: false })
    }
  }
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Chnage Password</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <Form className="w-100" style={{ marginBottom: 20 }} onSubmit={handleChangePassword}>

          <FlexboxGrid className="AddLeadsInner">
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Old Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.oldPassword}
                onChange={(value) => setState({ ...state, oldPassword: value })}
                required
              />
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">New Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.newPassword}
                onChange={(value) => setState({ ...state, newPassword: value })}
                required
              />
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Confirm Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.confirmPassword}
                onChange={(value) => setState({ ...state, confirmPassword: value })}
                required
              />
            </FormGroup>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <strong className="text-danger">{error}</strong>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button 
              color="blue" 
              className="btn" 
              type="submit" 
              disabled={state.btnLoading}
            >
              {state.btnLoading ? <IonSpinner name="dots" color="#fff" /> : "Save"}
            </Button>
          </FlexboxGrid>
        </Form>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile
});
const mapDispatchToProps = {
  changePassword
};
export default connect(mapStateToProps, mapDispatchToProps)(ChnagePassword);
import { STATUS_LOADING, STATUS_SET_DATA } from "./constants";
import { _fetchStatuses, _createStatus, _deleteStatus, _changeStatus, _editStatus } from "./services";

export function statusSetLoading(loading) {
	return {
		type: STATUS_LOADING,
		payload: loading
	};
}
export function statusSetData(params) {
	return {
		type: STATUS_SET_DATA,
		payload: params
	};
}
export const fetchStatuses = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(statusSetLoading(true));
		_fetchStatuses(id).then((resp) => {
			if (Array.isArray(resp?.list)) {
				dispatch(statusSetData(resp.list));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statusSetLoading(false));
		})
	})
};
export const createStatus = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(statusSetLoading(true));
		_createStatus(formData).then((resp) => {
			dispatch(fetchStatuses());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statusSetLoading(false));
		})
	})
};
export const deleteStatus = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(statusSetLoading(true));
		_deleteStatus(id).then((resp) => {
			dispatch(fetchStatuses());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statusSetLoading(false));
		})
	})
};
export const changeStatus = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(statusSetLoading(true));
		_changeStatus(id).then((resp) => {
			dispatch(fetchStatuses());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statusSetLoading(false));
		})
	})
};
export const editStatus = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(statusSetLoading(true));
		_editStatus(formData).then((resp) => {
			dispatch(fetchStatuses());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statusSetLoading(false));
		})
	})
};
import React, { useState } from "react";
import { IonText } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  InputPicker,
  IconButton,
  Icon,
  Checkbox,
  Uploader,
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./AddStatus.css";

const AddStatus: React.FC<{}> = () => {
  const history = useHistory();
  const data = [
    {
      label: "Seller",
      value: "Seller",
      role: "Seller",
    },
    {
      label: "Admin",
      value: "Admin",
      role: "Admin",
    },
  ];
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Add Status</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="FlexContainerInner">
          {/* <IonText className="subHeaderTextAddLeads">ADD NOTIFICATIONS</IonText> */}
          <Form className="w-100 formCard" style={{ marginBottom: 20 }}>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Upload Photo</ControlLabel>
              <Uploader
                action="//jsonplaceholder.typicode.com/posts/"
                draggable
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    color: "#aaa",
                    paddingTop: 100,
                    paddingBottom: 100,
                  }}
                >
                  Click or Drag files to this area to upload
                </div>
              </Uploader>
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Add Status Name</ControlLabel>
              <FormControl name="text" type="text" className="input" />
            </FormGroup>
          </Form>
          {/* */}
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button color="blue" className="btn">
              Add
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};

export default AddStatus;

import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  Button,
  Alert,
  FormControl,
} from "rsuite";
import { useHistory } from "react-router-dom";

// components
import Sidebar from "../../components/template/sidebar/Sidebar";

//redux
import { connect } from "react-redux";
import { editEmail, changePassword } from "../../stores/profile/actions";

const AdminProfile: React.FC<{
  profile: any;
  editEmail: Function;
  changePassword: Function;
}> = ({ profile, editEmail, changePassword }) => {
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [btnEmailLoading, setBtnEmailLoading] = useState(false);
  const [btnPasswordLoading, setBtnPasswordLoading] = useState(false);
  const [present] = useIonAlert();
  const history = useHistory();
  const handleChangeEmail = async () => {
    try {
      setBtnEmailLoading(true);
      await editEmail({
        id: profile._id,
        email,
      });
      present({
        message: `Email updated successfully!`,
        buttons: [{ text: "Ok", handler: (d) => {} }],
      });
    } catch (err: any) {
      let message = "Something went wrong";
      if (err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }
      present({
        message,
        buttons: [{ text: "Ok", handler: (d) => console.log("ok pressed") }],
      });
    } finally {
      setBtnEmailLoading(false);
    }
  };
  const handleChangePassword = async () => {
    try {
      setBtnPasswordLoading(true);
      await changePassword({
        email: profile.email,
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
      });
      present({
        message: `Password updated successfully!`,
        buttons: [
          {
            text: "Ok",
            handler: (d) => {
              setState({
                ...state,
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
            },
          },
        ],
      });
    } catch (err: any) {
      let message = "Something went wrong";
      if (err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }
      present({
        message,
        buttons: [{ text: "Ok", handler: (d) => console.log("ok pressed") }],
      });
    } finally {
      setBtnPasswordLoading(false);
    }
  };
  useEffect(() => {
    if (profile?._id) {
      setEmail(profile.email);
    }
  }, [profile]);
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <Sidebar title="Profile" />
        <FlexboxGrid className="AddLeadsInner">
          <IonText className="subHeaderTextAddLeads">UPDATE EMAIL</IonText>
          <Form
            className="w-100 formCard"
            style={{ marginBottom: 20 }}
            onSubmit={handleChangeEmail}
          >
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Email</ControlLabel>
              <FormControl
                name="text"
                type="email"
                className="input"
                value={email}
                onChange={(value) => setEmail(value)}
                required
              />
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                appearance="primary"
                style={{ marginRight: 10 }}
                type="submit"
                disabled={btnEmailLoading}
              >
                Edit
              </Button>
            </FormGroup>
          </Form>
        </FlexboxGrid>
        <FlexboxGrid className="AddLeadsInner">
          <IonText className="subHeaderTextAddLeads">UPDATE PASSWORD</IonText>
          <Form 
            className="w-100 formCard" 
            style={{ marginBottom: 20 }}
            onSubmit={handleChangePassword}
          >
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Old Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.oldPassword}
                onChange={(value) => setState({ ...state, oldPassword: value })}
                required
              />
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">New Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.newPassword}
                onChange={(value) => setState({ ...state, newPassword: value })}
                required
              />
            </FormGroup>
            {/* <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Confirm New Password</ControlLabel>
                  <FormControl
                  name="text"
                  type="password"
                  className="input"
                  value={state.confirmPassword}
                  onChange={(value) => setState({ ...state, confirmPassword: value })}
                  required
                />
                </FormGroup> */}
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                appearance="primary"
                style={{ marginRight: 10 }}
                type="submit"
                disabled={btnPasswordLoading}
              >
                Edit
              </Button>
            </FormGroup>
          </Form>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile,
});
const mapDispatchToProps = {
  editEmail,
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);

import React, { useState, useEffect } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  Table,
  DateRangePicker,
  InputPicker,
  Pagination,
  Button
} from "rsuite";
import { FiRefreshCw } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./Sellers.css";

// components
import Sidebar from "../../components/template/sidebar/Sidebar";

//redux
import { connect } from "react-redux";
import {fetchStats, statsSetPage} from "../../stores/stats/actions";

const Sellers: React.FC<{
  statCount: number,
  limit: number,
  offset: number,
  activePage: number,
  fetchStats: Function,
  statsSetPage: Function,
  stats: any[],
  loading: boolean
}> = ({
  statCount,
  limit,
  offset,
  activePage,
  fetchStats,
  statsSetPage,
  stats,
  loading
}) => {
  const history = useHistory();
  const [statsData, setStatsData] = useState<any[]>([]);
  const handlePageChange = (pageNumber:number) => {
    let newOffset = ((pageNumber-1) * limit);
    fetchStats(limit, newOffset);
    statsSetPage({
      activePage: pageNumber, 
      offset:  newOffset
    })
  };
  const handleRefresh = async () => {
    await fetchStats(50, 0);
    statsSetPage({
      activePage: 1,
      offset: 0,
      limit: 50
    })
  }
  const calculateAPPPercentage = (user:any) =>{
    if(user.stats.APPT_count > 0){
      return (user.stats.INGL_count/user.stats.APPT_count)*100;
    }else{
      return 0;
    }
  }
  const calculateDEMOPercentage = (user:any) =>{
    if(user.stats.APPT_count > 0){
      return (user.stats.DEMO_count/user.stats.APPT_count)*100;
    }else{
      return 0;
    }
  }
  const calculateCLPercentage = (user:any) =>{
    if(user.stats.APPT_count > 0){
      return (user.stats.MYCL_count/user.stats.APPT_count)*100;
    }else{
      return 0;
    }
  }
  useEffect(() => {
    fetchStats(limit, offset);
  }, []);
  useEffect(()=>{
    let statsTemp:any[] = [];
    stats.map((user:any)=>{
      statsTemp.push({
        id: user._id,
        name: `${user.firstName} ${user.lastName}`,
        dk: 0,
        cm: user.stats.INGL_count,
        appt: user.stats.APPT_count,
        demo: user.stats.DEMO_count,
        sale: user.stats.WIN_count,
        app: `${calculateAPPPercentage(user)}%`,
        dem: `${calculateDEMOPercentage(user)}%`,
        cl: `${calculateCLPercentage(user)}%`,
        type: user.role,
      })
    })
    setStatsData([...statsTemp])
  }, [stats])
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <Sidebar title='Sellers' />
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center" >
            <IonText className="subHeaderLabel">Date Range:</IonText>
            <DateRangePicker style={{ width: 224 }} />
            <FiRefreshCw className="mx-5" onClick={() => handleRefresh()} style={{cursor: "pointer"}}/>
            {/* <InputPicker data={fakeData2} style={{ width: 224 }} /> */}
          </FlexboxGrid>
          <Table
            virtualized
            height={600}
            className="table"
            data={statsData}
            onRowClick={(data) => {
              console.log(data);
            }}
            loading={loading}
          >
            <Table.Column width={200} align="center" >
              <Table.HeaderCell className='table-row'>SALES REP</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="name" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DK</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="dk" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>INGL</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="cm" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>APPT</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="appt" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DEMO</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="demo" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>Sale</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="sale" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>APP%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="app" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DEM%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="dem" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>CL%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="cl" />
            </Table.Column>
          </Table>
          <FlexboxGrid className="pagination-wrapper" justify="end" alignItems="center">
          <Pagination 
            pages={Math.ceil(statCount/limit) || 1} 
            activePage={activePage} 
            prev
            last
            next
            first
            onSelect={handlePageChange}
          />
          </FlexboxGrid>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state:any) => ({
  statCount: state.stats.count,
  limit: state.stats.limit,
  offset: state.stats.offset,
  activePage: state.stats.activePage,
  stats: state.stats.stats,
  loading: state.stats.loading,
});
const mapDispatchToProps = {
  fetchStats, 
  statsSetPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Sellers);
import React, { useEffect, useState } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  IconButton,
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config";
import { useHistory } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {updateBulkStatus} from "../../stores/leads/actions"
const BulkStatusUpdate: React.FC<{
  statuses: any[];
  location: any;
  updateBulkStatus: Function;
}> = ({
  statuses,
  location,
  updateBulkStatus
}) => {
    const history = useHistory();
    const [selectedStatus, setSelectedStatus] = useState<any>({});
    const [leadIds, setLeadIds] = useState<any[]>([]);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    useEffect(()=>{
      const query = new URLSearchParams(location.search);
      const ids = query.get('ids');
      if(ids){
        setLeadIds(ids.split(','))
      }
    }, [])
    const handleUpdateStatus = async() =>{
      try{
        setBtnLoading(true);
        await updateBulkStatus({
          ids: leadIds,
          status_id: selectedStatus._id
        });
        history.push("/home");
      }catch(err){

      }finally{
        setBtnLoading(false);
      }
    }
    return (
      <>
        <FlexboxGrid className="AddLeads">
          <FlexboxGrid className="header">
            <FlexboxGrid className="">
              <IconButton
                className="back-btn"
                icon={<FiArrowLeft style={{ fontSize: 18 }} />}
                circle
                size="xs"
                onClick={() => {
                  history.goBack();
                }}
              />
              <IonText className="header_text">Change Status</IonText>
            </FlexboxGrid>
          </FlexboxGrid>
          <FlexboxGrid justify="center" className="row">
            {statuses.map((status, i) => (
              <FlexboxGrid 
                justify="center" 
                className={`tag-btn-wrapper ${selectedStatus._id === status._id ? "status-selected" : ""}`}
                key={i}
                onClick={() => setSelectedStatus(status)}
              >
                <img
                  src={`${API_URL}${status.image}`}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 5,
                    borderRadius: "50%",
                  }}
                />
                <IonText>{status.name}</IonText>
              </FlexboxGrid>
            ))}
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button 
              color="blue" 
              className="btn" 
              onClick={() => handleUpdateStatus()}
              disabled={btnLoading}
            >
              {btnLoading ? <IonSpinner color={"#fff"}/> : "Update Status"}
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </>
    );
  };
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
});
const mapDispatchToProps = {
  updateBulkStatus
};
export default connect(mapStateToProps, mapDispatchToProps)(BulkStatusUpdate);
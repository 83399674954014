import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  IconButton,
  InputPicker,
  Toggle,
  Icon,
  CheckPicker,
  DatePicker
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config/";
import {
  FaTruckMoving,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "./Filter.css";

//redux
import { connect } from "react-redux";
import { leadsSetMapFilters } from "../../stores/leads/actions"
import { fetchAllSellers } from "../../stores/users/actions"

const Filter: React.FC<{
  statuses: any[];
  leadsSetMapFilters: Function;
  fetchAllSellers: Function;
  profile: any;
  allSellers: any[];
}> = ({
  statuses,
  leadsSetMapFilters,
  fetchAllSellers,
  profile,
  allSellers
}) => {
    const history = useHistory();
    const [selectedStatuses, setSelectedStatuses] = useState<any[]>([]);
    const [sellerMod, setSellerMod] = useState<any[]>([]);
    const [selectedSellerId, setSelectedSellerId] = useState<string>("");
    const [isUserLocationOn, serUserLocationOn] = useState<Boolean>(false);
    
    const handleSelectRow = (item: any, checked: boolean) => {
      if (checked) {
        setSelectedStatuses((status: any) => {
          let statusArr: any[] = [...status];
          let filteredStatuses = status.filter((sts: any) => sts._id === item._id);
          if (!filteredStatuses.length) {
            statusArr.push(item);
          }
          return statusArr;
        });
      } else {
        setSelectedStatuses((status: any) => {
          let filteredStatuses = status.filter(
            (status: any) => status._id !== item._id
          );
          return filteredStatuses;
        });
      }
    };
    const handleSelectAll = () => {
      setSelectedStatuses(statuses);
    }
    const handleSetFilters = () => {
      leadsSetMapFilters({ 
        statuses: selectedStatuses.map((status: any) => status._id), 
        sellerId: selectedSellerId,
        isUserLocationOn 
      })
      history.push("/home")
    }
    useEffect(() => {
      if (profile?.role === "ADMIN" || profile?.role === "MANAGER") {
        fetchAllSellers()
      }
    }, [profile])
    useEffect(() => {
      let sellersTemp: any = [];
      allSellers.map((seller: any) => {
        sellersTemp.push({
          value: seller._id,
          label: `${seller.firstName} ${seller.lastName}`
        })
      })
      setSellerMod(sellersTemp)
    }, [allSellers])
    return (
      <>
        <FlexboxGrid className="AddLeads">
          <FlexboxGrid className="header">
            <FlexboxGrid className="">
              <IconButton
                className="back-btn"
                icon={<FiArrowLeft style={{ fontSize: 18 }} />}
                circle
                size="xs"
                onClick={() => {
                  history.goBack();
                }}
              />
              <IonText className="header_text">Filter</IonText>
            </FlexboxGrid>
          </FlexboxGrid>
          <FlexboxGrid
            justify="space-between"
            alignment="center"
            className="w-100 row"
          >
            <IonText></IonText>
            <Button className="" onClick={() => handleSelectAll()}>Select all</Button>
          </FlexboxGrid>
          <FlexboxGrid justify="center" className="row">
            {statuses.map((status, i) => (
              <FlexboxGrid
                justify="center"
                className={`tag-btn-wrapper ${selectedStatuses.filter((sts: any) => sts._id === status._id).length ? "status-selected" : ""}`}
                key={i}
                onClick={() => handleSelectRow(status, !selectedStatuses.filter((sts: any) => sts._id === status._id).length)}
              >
                <img
                  src={`${API_URL}${status.image}`}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 5,
                    borderRadius: "50%",
                  }}
                />
                <IonText>{status.name}</IonText>
              </FlexboxGrid>
            ))}
          </FlexboxGrid>
          {(profile?.role === "ADMIN" || profile?.role === "MANAGER") && (
            <>
              <FlexboxGrid justify="center" className="row" style={{ width: "100%" }}>
                <InputPicker
                  data={sellerMod}
                  onSelect={(value) => setSelectedSellerId(value)}
                  placeholder="Choose Seller"
                />
              </FlexboxGrid>
              <Button
                className="homebtn"
                style={{ border: "none" }}
              >
                <Icon icon="map" className="homebtnIcon" /> User Locations
                <Toggle style={{marginLeft: "auto"}} onChange={(value)=>serUserLocationOn(value)}/>
              </Button>
              {isUserLocationOn && (
                <>
                <Button
                className="homebtn"
                >
                  <Icon icon="user" className="homebtnIcon" /> Users
                  <CheckPicker 
                    data={sellerMod} 
                    style={{ width: 224, marginLeft: "auto" }} 
                    placement="bottom"
                  />
                </Button>
                <Button
                className="homebtn"
                >
                  <Icon icon="calendar" className="homebtnIcon" /> From Date
                  <DatePicker 
                    style={{ width: 224, marginLeft: "auto" }}
                    placement="auto"
                  />
                </Button>
                <Button
                className="homebtn"
                >
                  <Icon icon="calendar" className="homebtnIcon" /> To Date
                  <DatePicker 
                    style={{ width: 224, marginLeft: "auto" }}
                    placement="auto"
                  />
                </Button>
                </>
              )}
            </>
          )}
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button color="blue" className="btn" onClick={() => handleSetFilters()}>
              Filter
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </>
    );
  };
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  profile: state.profile.profile,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  leadsSetMapFilters,
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
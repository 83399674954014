
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer,autoRehydrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage'

// Reducers imported
import { profileReducer } from "./profile/reducers";
import { authReducer } from "./auth/reducers";
import { leadsReducer } from "./leads/reducers";
import { statusReducer } from "./status/reducers";
import { routeReducer } from "./routes/reducers";
import { userReducer } from "./users/reducers";
import { statsReducer } from "./stats/reducers";
import { notificationsReducer } from "./notifications/reducers";
import { mapsReducer } from "./map/reducers";

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  leads: leadsReducer,
  statuses: statusReducer,
  routes: routeReducer,
  users: userReducer,
  stats: statsReducer,
  notifications: notificationsReducer,
  mapsReducer: mapsReducer
});
// // Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method
  storage,
  // Merge two-levels deep.
  stateReconciler: autoMergeLevel2,
  // Whitelist (Save Specific Reducers)
  whitelist: ["auth"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    
  ],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  let store = createStore(persistedReducer,composeWithDevTools(middleWareEnhancer))
  let persistor = persistStore(store)
  return { store, persistor }
}
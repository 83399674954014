import React, { useState, useEffect } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  InputPicker,
  IconButton,
  Alert,
  CheckPicker
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./EditUsers.css";

//redux
import { connect } from "react-redux";
import { fetchUserDetails, editUser, fetchAllSellers } from "../../stores/users/actions";

const EditUsers: React.FC<{
  fetchUserDetails: Function,
  user: any,
  editUser: Function,
  fetchAllSellers: Function,
  profile: any,
  allSellers: any[]
}> = ({ fetchUserDetails, user, editUser, fetchAllSellers, profile, allSellers }) => {
  const history = useHistory();
  const match = useRouteMatch<any>();
  const data = [
    {
      label: "Seller",
      value: "SELLER",
      role: "Seller",
    },
    {
      label: "Admin",
      value: "ADMIN",
      role: "Admin",
    },
    {
      label: "Manager",
      value: "MANAGER",
      role: "Manager",
    },
  ];
  const verificationOptions = [
    {
      label: "Verified",
      value: true,
    },
    {
      label: "Not Verified",
      value: false,
    },
  ];
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "SELLER",
    password: "",
    id: "",
    isVerified: false
  })
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [sellerMod, setSellerMod] = useState<any[]>([]);
  const [selectedSellerIds, setSelectedSellerIds] = useState<any[]>([]);

  const handleEditUser = async () => {
    try {
      setBtnLoading(true);
      await editUser({
        ...state,
        assignedSellers: selectedSellerIds
      });
      Alert.success("User edited successfully");
    } catch (err: any) {
      console.log(err)
      let message = typeof err?.response?.data?.message === "string" ? err?.response?.data?.message : "Something went wrong!";
      Alert.error(message)
    } finally {
      setBtnLoading(false);
    }
  }
  useEffect(() => {
    let id = match?.params?.id;
    fetchUserDetails(id)
  }, [])
  useEffect(() => {
    if (user?._id) {
      setState({
        ...state,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        id: user._id,
        isVerified: user.isVerified
      })
      setSelectedSellerIds(user.assignedSellers)
    }
  }, [user])
  useEffect(() => {
    if (profile?.role === "ADMIN") {
      fetchAllSellers()
    }
  }, [profile])
  useEffect(() => {
    let sellersTemp: any = [];
    allSellers.map((seller: any) => {
      sellersTemp.push({
        value: seller._id,
        label: `${seller.firstName} ${seller.lastName}`
      })
    })
    setSellerMod(sellersTemp)
  }, [allSellers])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Edit Users</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="AddLeadsInner">
          <IonText className="subHeaderTextAddLeads">USER INFO</IonText>
          <Form
            className="w-100"
            style={{ marginBottom: 20 }}
            onSubmit={handleEditUser}
          >
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Email</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.email}
                onChange={(value) => setState({ ...state, email: value })}
                required
              />
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Password</ControlLabel>
              <FormControl
                name="text"
                type="password"
                className="input"
                value={state.password}
                onChange={(value) => setState({ ...state, password: value })}
              />
            </FormGroup>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">First Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.firstName}
                    onChange={(value) => setState({ ...state, firstName: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Last Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.lastName}
                    onChange={(value) => setState({ ...state, lastName: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Role</ControlLabel>
              <InputPicker
                data={data}
                onChange={(value) => setState({ ...state, role: value })}
                value={state.role}
              />
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Verification Status</ControlLabel>
              <InputPicker
                data={verificationOptions}
                onChange={(value) => setState({ ...state, isVerified: value })}
                value={state.isVerified}
              />
            </FormGroup>
            {state.role === "MANAGER" && (
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Assign Users</ControlLabel>
                <CheckPicker
                  data={sellerMod}
                  className="input"
                  onChange={(docs) => setSelectedSellerIds(docs)}
                  value={selectedSellerIds}
                />
              </FormGroup>
            )}
            <FlexboxGrid
              justify="center"
              className="w-100"
              style={{ margin: "15px 0px" }}
            >
              <Button
                color="blue"
                className="btn"
                type="submit"
                disabled={btnLoading}
              >
                {btnLoading ? <IonSpinner name="dots" color="#fff" /> : "Update User"}
              </Button>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  user: state.users.user,
  profile: state.profile.profile,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  fetchUserDetails,
  editUser,
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUsers);
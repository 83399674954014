import React from "react";
import { IonPage, IonHeader, IonToolbar, IonContent } from "@ionic/react";

// components
import Header from "../../components/template/header/header";
import Bottom from "../../components/template/bottom/Bottom";
import "./mainView.css";

const MainView: React.FC<{ children: any }> = ({ children }) => {
  return (
    <IonPage>
      {/* <Header /> */}
      <IonContent className="mainView-Content" fullscreen>
        {children}
      </IonContent>
      <Bottom />
    </IonPage>
  );
};

export default MainView;

import { NOTIFICATIONS_LOADING, NOTIFICATIONS_SET_DATA } from "./constants";
import { _fetchNotifications, _createNotification, _deleteNotification, _editNotification } from "./services";

export function notificationSetLoading(loading) {
	return {
		type: NOTIFICATIONS_LOADING,
		payload: loading
	};
}
export function notificationSetData(params) {
	return {
		type: NOTIFICATIONS_SET_DATA,
		payload: params
	};
}
export const fetchNotifications = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(notificationSetLoading(true));
		_fetchNotifications(id).then((resp) => {
			if (Array.isArray(resp?.list)) {
				dispatch(notificationSetData(resp.list));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(notificationSetLoading(false));
		})
	})
};
export const createNotification = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(notificationSetLoading(true));
		_createNotification(formData).then((resp) => {
			dispatch(fetchNotifications());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(notificationSetLoading(false));
		})
	})
};
export const deleteNotification = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(notificationSetLoading(true));
		_deleteNotification(id).then((resp) => {
			dispatch(fetchNotifications());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(notificationSetLoading(false));
		})
	})
};
export const editNotification = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(notificationSetLoading(true));
		_editNotification(formData).then((resp) => {
			dispatch(fetchNotifications());
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(notificationSetLoading(false));
		})
	})
};
import React, { useEffect, useState } from "react";
import { IonText, IonSpinner, useIonAlert } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  IconButton,
  DatePicker,
  Alert
} from "rsuite";
import {
  FiArrowLeft,
  FiMessageSquare,
  FiPhoneCall,
  FiGitPullRequest,
} from "react-icons/fi";
import { IoLocateOutline } from "react-icons/io5";
import { useHistory, useRouteMatch } from "react-router-dom";
import AutoCompleteComponent from "../../components/common/AutoComplete";
import moment from "moment";
import { API_URL } from "../../config/";
import "./EditLeads.css";

//redux
import { connect } from "react-redux";
import { fetchLeadDetails, deleteLead, updateLead, fetchLocationFromLongLat } from "../../stores/leads/actions";

const EditLeads: React.FC<{
  fetchLeadDetails: Function;
  lead: any;
  statuses: any[]
  updateLead: Function;
  deleteLead: Function;
  fetchLocationFromLongLat: Function;
}> = ({ fetchLeadDetails, lead, statuses, updateLead, deleteLead, fetchLocationFromLongLat }) => {
  const [present] = useIonAlert();
  const match = useRouteMatch<any>();
  const history = useHistory();
  const [state, setState] = useState<any>({
    business_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    phone2: "",
    email: "",
    street_address: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    longitude: "",
    latitude: "",
    appointment_date: "",
    appointment_time: "",
    status_id: "",
    owned_by: "",
    note: "",
  })
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const handleDeleteLead = async() =>{
    try{
      present({
        cssClass: "my-css",
        header: "Delete Lead",
        message: "Are you sure you want to delete this Lead. The action is irreversible",
        buttons: [
          "Cancel",
          {
            text: "Ok", handler: async(d) => {
              setLoadingDelete(true)
              await deleteLead(lead._id)
              Alert.success('Lead deleted successfully!');
              history.goBack();
            }
          },
        ],
        onDidDismiss: (e) => console.log("did dismiss"),
      })
    }catch(err){
      Alert.error('Something went wrong!');
    }finally{
      setLoadingDelete(false)
    }
  }
  const handleUpdateLead = async() =>{
    try{
      setLoading(true);
      await updateLead({...state, user_id: lead.created_by, id: lead._id});
      Alert.success('Lead updated successfully!');
      history.goBack();
    }catch(err){
      Alert.error('Something went wrong!');
    }finally{
      setLoading(false);
    }
  }
  const fetchCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      async position => {
        const resp = await fetchLocationFromLongLat(`${position.coords.latitude}, ${position.coords.longitude}`);
        setState({
          ...state,
          city: resp?.city,
          state: resp?.state,
          zip: resp?.postalCode,
          country: resp?.country,
          street_address: resp?.location,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      },
      err => console.log(err));
  }
  useEffect(() => {
    if (match.params?.id) {
      fetchLeadDetails(match.params.id);
    }
  }, [])
  useEffect(() => {
    if (lead?._id) {
      setState({
        business_name: lead.business_name,
        first_name: lead.first_name,
        last_name: lead.last_name,
        phone: lead.phone,
        phone2: lead.phone2,
        email: lead.email,
        street_address: lead.street_address,
        apt: lead.apt,
        city: lead.city,
        state: lead.state,
        zip: lead.zip,
        country: lead.country,
        longitude: lead.longitude,
        latitude: lead.latitude,
        appointment_date: lead.appointment_date,
        appointment_time: lead.appointment_time,
        status_id: typeof lead.status_id === "object" ? lead.status_id._id : "",
        owned_by: lead.owned_by,
        note: lead.note,
      })
    }
  }, [lead])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Edit Leads</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <Form className="w-100" style={{ marginBottom: 20 }} onSubmit={handleUpdateLead}>
          <FlexboxGrid className="AddLeadsInner">
            <FlexboxGrid
              className="w-100"
              justify="space-around"
              style={{ marginBottom: 20 }}
            >
              <IconButton
                className=""
                icon={<FiMessageSquare style={{ fontSize: 18 }} />}
                circle
                size="md"
                color="blue"
              />
              <a href={`tel: ${state.phone}`}>
              <IconButton
                className=""
                icon={<FiPhoneCall style={{ fontSize: 18 }} />}
                circle
                size="md"
                color="blue"
              />
              </a>
              <IconButton
                className=""
                icon={<FiGitPullRequest style={{ fontSize: 18 }} />}
                circle
                size="md"
                color="blue"
                onClick={()=>{
                  // history.push(`/map-page?geometry=${state.latitude},${state.longitude}`)
                  window.location.href = `http://maps.google.com/maps?q=loc:${state.latitude},${state.longitude}(${state.street_address})`
                }}
              />
            </FlexboxGrid>
            <IonText className="subHeaderTextAddLeads">CONTACT INFO</IonText>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Business Name</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.business_name}
                onChange={(value) => setState({ ...state, business_name: value })}
              />
            </FormGroup>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">First Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.first_name}
                    onChange={(value) => setState({ ...state, first_name: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Last Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.last_name}
                    onChange={(value) => setState({ ...state, last_name: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">
                    Phone Number
                  </ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.phone}
                    onChange={(value) => setState({ ...state, phone: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">
                    Alternative Number
                  </ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.phone2}
                    onChange={(value) => setState({ ...state, phone2: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Email</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.email}
                onChange={(value) => setState({ ...state, email: value })}
              />
            </FormGroup>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                <ControlLabel className="inputLable">
                    Street Address
                    <IoLocateOutline size={20}
                      style={{
                        marginLeft: 10,
                        color: "#1F4690",
                        border: "1px solid rgb(33, 150, 243)",
                        borderRadius: "50%"
                      }}
                      onClick={() => fetchCurrentLocation()}
                    />
                  </ControlLabel>
                  <AutoCompleteComponent
                    className="input"
                    onSelect={(value: any) => {
                      setState({ ...state, city: value?.city, state: value?.state, zip: value?.postalCode, country: value?.country, longitude: value?.geometry?.lng, latitude: value?.geometry?.lat, street_address: value?.street_address })
                    }}
                    value={state.street_address}
                    onChange={(value:any) => setState({ ...state, street_address: value })}
                    placeholder=""
                  />
                  {/* <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.street_address}
                    onChange={(value) => setState({ ...state, street_address: value })}
                  /> */}
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Apt/Suite</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.apt}
                    onChange={(value) => setState({ ...state, apt: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">City</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.city}
                    onChange={(value) => setState({ ...state, city: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">State</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.state}
                    onChange={(value) => setState({ ...state, state: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Zip Code</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.zip}
                    onChange={(value) => setState({ ...state, zip: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Country</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.country}
                    onChange={(value) => setState({ ...state, country: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <IonText className="subHeaderTextAddLeads">
              APOINTMENT/CALLBACK TIME
            </IonText>
            <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <DatePicker 
                defaultValue={moment(state.appointment_date).isValid() ? moment(state.appointment_date).toDate() : moment().toDate()} 
                onChange={(value) => setState({ ...state, appointment_date: value })}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <DatePicker 
                format="HH:mm" 
                defaultValue={moment(state.appointment_time).isValid() ? moment(state.appointment_time).toDate() : moment().toDate()}
                onChange={(value) => setState({ ...state, appointment_time: value })}
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <IonText className="subHeaderTextAddLeads">STATUS</IonText>
            <FlexboxGrid
              className="status-wrapper"
              justify="start"
              alignment="center"
              style={{ padding: 0 }}
            >
              {statuses.map((status, i) => (
                <FlexboxGrid
                  justify="center"
                  className={`tag-btn-wrapper ${state.status_id === status._id ? "status-selected" : ""}`}
                  key={i}
                  onClick={() => setState({ ...state, status_id: status._id })}
                >
                  <img
                    src={`${API_URL}${status.image}`}
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: 5,
                      borderRadius: "50%",
                    }}
                  />
                  <IonText>{status.name}</IonText>
                </FlexboxGrid>
              ))}
            </FlexboxGrid>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Note</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  onChange={(value) => setState({ ...state, note: value })}
                  value={state.note}
                />
              </FormGroup>
            </Form>
            {/* <IonText className="subHeaderTextAddLeads">LEAD OWNERSHIP</IonText>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Owned by</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  placeholder="Jorge Martinez"
                  onChange={(value) => setState({ ...state, owned_by: value })}
                  value={state.owned_by}
                />
              </FormGroup>
            </Form> */}
            <IonText className="subHeaderTextAddLeads">EVENTS</IonText>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Create</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  value={moment(lead.created_on).format("DD/MM/YYYY HH:mm")}
                />
              </FormGroup>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Last Updated</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  value={moment(lead.updated_on).format("DD/MM/YYYY HH:mm")}
                />
              </FormGroup>
            </Form>
            <IonText className="subHeaderTextAddLeads">Lead History</IonText>
            {lead.history?.map((history: any, i: number) => (
              <FlexboxGrid className="flex-row" key={i}>
                <FlexboxGrid justify="center" className="tag-btn-wrapper">
                  {typeof history.status_id === "object" && (
                    <img
                      src={`${API_URL}${history?.status_id?.image}`}
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 5,
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </FlexboxGrid>
                <FlexboxGrid className="route_column">
                  <IonText className="textFlex" style={{ fontWeight: "bold" }}>
                    {history.note}
                  </IonText>
                  <IonText className="textFlex">{history.first_name} {history.last_name}</IonText>
                  <IonText className="textFlex">
                    {history.street_address?.length > 0 ? history.street_address : ""}
                    {/* {history.apt?.length > 0 ? `, ${history.apt}` : ""}
                    {history.city?.length > 0 ? `, ${history.city}` : ""}
                    {history.state?.length > 0 ? `, ${history.state}` : ""}
                    {history.zip?.length > 0 ? `, ${history.zip}` : ""}
                    {history.country?.length > 0 ? `, ${history.country}` : ""} */}
                  </IonText>
                  <IonText className="textFlex" style={{ fontSize: 12 }}>
                    {moment(history.created_on).format("DD/MM/YYYY HH:mm")}
                  </IonText>
                </FlexboxGrid>
              </FlexboxGrid>
            ))}

          </FlexboxGrid>

          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button
              color="blue"
              className="btn"
              style={{ marginRight: 10 }}
              disabled={loading}
              type="submit"
            >
              {loading ? <IonSpinner name="dots" color="#fff"/> : "Edit"}
            </Button>
            <Button color="red" className="btn" onClick={()=>handleDeleteLead()}>
              {loadingDelete ? <IonSpinner name="dots" color="#fff"/> : "Delete"}
            </Button>
          </FlexboxGrid>
        </Form>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  lead: state.leads.lead,
  profile: state.profile.profile,
  statuses: state.statuses.statuses,
});
const mapDispatchToProps = {
  fetchLeadDetails,
  deleteLead,
  updateLead,
  fetchLocationFromLongLat
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLeads);
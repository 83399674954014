import React, { useState } from "react";
import { IonSpinner } from "@ionic/react";
import {
	FlexboxGrid,
	Form,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
	InputPicker,
	Modal,
	Alert
} from "rsuite";

//redux
import { connect } from "react-redux";
import { createNotification } from "../../stores/notifications/actions";
const AddNotificationModal: React.FC<{
	show: boolean;
	setShow: Function;
	createNotification: Function;
	profile: any
}> = ({
	show,
	setShow,
	createNotification,
	profile
}) => {
		const data = [
			{
				label: "Day",
				value: "DAY"
			},
			{
				label: "Hour",
				value: "HOUR"
			},
		];
		const types = [
			{
				label: "SMS",
				value: "SMS"
			},
			{
				label: "Push Notification",
				value: "NOTIFICATION"
			},
		];
		const [state, setState] = useState<any>({
			name: "",
			interval: "",
			unit: "",
			type: "",
			message: ""
		})
		const [btnLoading, setBtnLoading] = useState<boolean>(false);
		const handleCreateNotification = async () => {
			try {
				setBtnLoading(true);
				await createNotification({ ...state, created_by: profile._id });
				setShow(false);
				Alert.success("Notification created successfully");
				setState({
					...state,
					name: "",
					interval: "",
					unit: "",
					type: "",
					message: ""
				})
			} catch (err) {
				Alert.error("Something went wrong!");
			} finally {
				setBtnLoading(false);
			}
		}
		return (
			<Modal
				show={show}
				onHide={() => {
					setShow(!show);
				}}
			>
				<Form className="w-100" style={{ marginBottom: 20 }} onSubmit={handleCreateNotification}>
					<Modal.Header>
						<Modal.Title>Create new notification</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FlexboxGrid
							className="status-wrapper"
							justify="center"
							alignment="center"
							style={{ paddingBottom: 20 }}
						>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Name</ControlLabel>
								<FormControl
									name="text"
									type="text"
									className="input"
									value={state.name}
									onChange={(value) => setState({ ...state, name: value })}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Interval</ControlLabel>
								<FormControl
									name="text"
									type="number"
									className="input"
									value={state.interval}
									onChange={(value) => setState({ ...state, interval: value })}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Unit</ControlLabel>
								<InputPicker
									data={data}
									onChange={(value) => setState({ ...state, unit: value })}
									value={state.unit}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Type</ControlLabel>
								<InputPicker
									data={types}
									onChange={(value) => setState({ ...state, type: value })}
									value={state.type}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Message</ControlLabel>
								<FormControl
									name="text"
									type="text"
									className="input"
									onChange={(value) => setState({ ...state, message: value })}
									value={state.message}
									required
								/>
							</FormGroup>
						</FlexboxGrid>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setShow(!show);
							}}
							appearance="subtle"
						>
							Cancel
						</Button>
						<Button
							appearance="primary"
							type="submit"
							disabled={btnLoading}
						>
							{btnLoading ? <IonSpinner name="dots" color="#fff" /> : "Submit"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	};
const mapStateToProps = (state: any) => ({
	profile: state.profile.profile
});
const mapDispatchToProps = {
	createNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNotificationModal);
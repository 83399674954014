import { ROUTES_LOADING, ROUTES_SET_DATA, ROUTES_SET_DETAILS, ROUTES_REMOVE_ROUTE, ROUTES_SET_FORMDATA, ROUTES_RESET_FORMDATA} from "./constants";
import { _fetchRoutes, _deleteRoute, _createRoute, _fetchRouteDetails, _updateRoute } from "./services";

export function routesSetLoading(loading) {
	return {
		type: ROUTES_LOADING,
		payload: loading
	};
}
export function routesSetData(params) {
	return {
		type: ROUTES_SET_DATA,
		payload: params
	};
}
export function routesSetDetails(params) {
	return {
		type: ROUTES_SET_DETAILS,
		payload: params
	};
}
export function routesRemoveRoute(id) {
	return {
		type: ROUTES_REMOVE_ROUTE,
		payload: id
	};
}
export function routesSetFormData(formData) {
	return {
		type: ROUTES_SET_FORMDATA,
		payload: formData
	};
}
export function routesResetFormData() {
	return {
		type: ROUTES_RESET_FORMDATA
	};
}
export const fetchRoutes = (user_id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(routesSetLoading(true));
		_fetchRoutes(user_id).then((resp)=>{
			if(resp?.routes){
				dispatch(routesSetData(resp.routes));
			}
			resolve(resp)
		}).catch(err=>{
			reject(err);
		}).finally(()=>{
			dispatch(routesSetLoading(false));
		})
	})
};
export const deleteRoute = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(routesSetLoading(true));
		_deleteRoute(id).then((resp)=>{
			dispatch(routesRemoveRoute(id));
			resolve(resp)
		}).catch(err=>{
			reject(err);
		}).finally(()=>{
			dispatch(routesSetLoading(false));
		})
	})
};
export const createRoute = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(routesSetLoading(true));
		_createRoute(formData).then((resp)=>{
			dispatch(fetchRoutes(formData.user_id));
			resolve(resp)
		}).catch(err=>{
			reject(err);
		}).finally(()=>{
			dispatch(routesSetLoading(false));
		})
	})
};
export const fetchRouteDetails = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(routesSetLoading(true));
		const {formData} = getState().routes;
		_fetchRouteDetails(id).then((resp)=>{
			if(resp?.route){
				dispatch(routesSetDetails(resp.route));
				dispatch(routesSetFormData({
					...formData,
					name: resp.route.name,
					starting_address: resp.route.starting_address,
					ending_address: resp.route.ending_address,
					starting_address_long: resp.route.starting_address_long,
					starting_address_lat: resp.route.starting_address_lat,
					ending_address_long: resp.route.ending_address_long,
					ending_address_lat: resp.route.ending_address_lat,
					leads: resp.route.leads,
					selectedStatuses: []
				}));
			}
			resolve(resp)
		}).catch(err=>{
			reject(err);
		}).finally(()=>{
			dispatch(routesSetLoading(false));
		})
	})
};
export const updateRoute = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(routesSetLoading(true));
		_updateRoute(formData).then((resp)=>{
			dispatch(fetchRoutes(formData.user_id));
			resolve(resp)
		}).catch(err=>{
			reject(err);
		}).finally(()=>{
			dispatch(routesSetLoading(false));
		})
	})
};
import React, { useState, useEffect } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  InputPicker,
  IconButton,
  Alert,
  CheckPicker
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./AddUsers.css";

//redux
import { connect } from "react-redux";
import { createUser, fetchAllSellers } from "../../stores/users/actions";
const AddUsers: React.FC<{
  createUser: Function;
  fetchAllSellers: Function;
  profile:any;
  allSellers: any[];
}> = ({
  createUser,
  fetchAllSellers,
  profile,
  allSellers
}) => {
    const history = useHistory();
    const data = [
      {
        label: "Seller",
        value: "SELLER",
        role: "Seller",
      },
      {
        label: "Admin",
        value: "ADMIN",
        role: "Admin",
      },
      {
        label: "Manager",
        value: "MANAGER",
        role: "Manager",
      },
    ];
    const [state, setState] = useState({
      email: "",
      firstName: "",
      lastName: "",
      role: "SELLER",
      password: ""
    })
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [sellerMod, setSellerMod] = useState<any[]>([]);
    const [selectedSellerIds, setSelectedSellerIds] = useState<any[]>([]);

    const handleCreateUser = async () => {
      try {
        setBtnLoading(true);
        await createUser({
          ...state,
          assignedSellers: selectedSellerIds
        });
        Alert.success("User created successfully");
        setState({
          email: "",
          firstName: "",
          lastName: "",
          role: "SELLER",
          password: ""
        })
        setSelectedSellerIds([]);
      } catch (err:any) {
        console.log(err)
        let message = typeof err?.response?.data?.message === "string" ? err?.response?.data?.message : "Something went wrong!";
        Alert.error(message)
      } finally {
        setBtnLoading(false);
      }
    }
    useEffect(()=>{
      if(profile?.role === "ADMIN"){
        fetchAllSellers()
      }
    }, [profile])
    useEffect(()=>{
      let sellersTemp:any=[];
      allSellers.map((seller:any)=>{
        sellersTemp.push({
          value: seller._id,
          label: `${seller.firstName} ${seller.lastName}`
        })
      })
      setSellerMod(sellersTemp)
    }, [allSellers])
    return (
      <>
        <FlexboxGrid className="AddLeads">
          <FlexboxGrid className="header">
            <FlexboxGrid className="">
              <IconButton
                className="back-btn"
                icon={<FiArrowLeft style={{ fontSize: 18 }} />}
                circle
                size="xs"
                onClick={() => {
                  history.goBack();
                }}
              />
              <IonText className="header_text">Add Users</IonText>
            </FlexboxGrid>
          </FlexboxGrid>
          <FlexboxGrid className="AddLeadsInner">
            <IonText className="subHeaderTextAddLeads">USER INFO</IonText>
            <Form
              className="w-100"
              style={{ marginBottom: 20 }}
              onSubmit={handleCreateUser}
            >
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Email</ControlLabel>
                <FormControl
                  name="text"
                  type="email"
                  className="input"
                  value={state.email}
                  onChange={(value) => setState({ ...state, email: value })}
                  required
                />
              </FormGroup>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Password</ControlLabel>
                <FormControl
                  name="text"
                  type="password"
                  className="input"
                  value={state.password}
                  onChange={(value) => setState({ ...state, password: value })}
                  required
                />
              </FormGroup>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item style={{ width: "47%" }}>
                  <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                    <ControlLabel className="inputLable">First Name</ControlLabel>
                    <FormControl
                      name="text"
                      type="text"
                      className="input"
                      value={state.firstName}
                      onChange={(value) => setState({ ...state, firstName: value })}
                      required
                    />
                  </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item style={{ width: "47%" }}>
                  <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                    <ControlLabel className="inputLable">Last Name</ControlLabel>
                    <FormControl
                      name="text"
                      type="text"
                      className="input"
                      value={state.lastName}
                      onChange={(value) => setState({ ...state, lastName: value })}
                      required
                    />
                  </FormGroup>
                </FlexboxGrid.Item>
              </FlexboxGrid>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Role</ControlLabel>
                <InputPicker
                  data={data}
                  onChange={(value) => setState({ ...state, role: value })}
                  value={state.role}
                />
              </FormGroup>
              {state.role === "MANAGER" && (
                <FormGroup className="w-100">
                <ControlLabel className="inputLable">Assign Users</ControlLabel>
                <CheckPicker 
                  data={sellerMod} 
                  className="input"
                  onChange={(docs)=>setSelectedSellerIds(docs)}
                />
              </FormGroup>
              )}
              <FlexboxGrid
                justify="center"
                className="w-100"
                style={{ margin: "15px 0px" }}
              >
                <Button
                  color="blue"
                  className="btn"
                  type="submit"
                  disabled={btnLoading}
                >
                  {btnLoading ? <IonSpinner name="dots" color="#fff" /> : "Add User"}
                </Button>
              </FlexboxGrid>
            </Form>
          </FlexboxGrid>
        </FlexboxGrid>
      </>
    );
  };
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  createUser,
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);
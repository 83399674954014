import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  Table,
  Pagination,
  IconButton,
  Icon,
  Divider
} from "rsuite";
import { FiRefreshCw, FiSettings, FiEdit2, FiTrash, FiX } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";
import "./Users.css";
// components
import Sidebar from "../../components/template/sidebar/Sidebar";
import DeleteUserPopup from "./DeleteUserPopup";
//redux
import { connect } from "react-redux";
import { fetchUsers, userSetPage, getLeadsCount, deleteUser } from "../../stores/users/actions";
const Users: React.FC<{
  userCount: number,
  limit: number,
  offset: number,
  activePage: number,
  fetchUsers: Function,
  userSetPage: Function,
  users: any[],
  loading: boolean,
  getLeadsCount: Function,
  deleteUser: Function
}> = ({ userCount, limit, offset, activePage, fetchUsers, userSetPage, users, loading, getLeadsCount, deleteUser }) => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [usersData, setUsersData] = useState<any[]>([]);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState<any>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);
  const [leadsCount, setLeadsCount] = useState<number>(0);
  const [selectedSeller, setSelectedSeller] = useState<any>({});

  const handlePageChange = (pageNumber: number) => {
    let newOffset = ((pageNumber - 1) * limit);
    fetchUsers(limit, newOffset);
    userSetPage({
      activePage: pageNumber,
      offset: newOffset
    })
  };
  const handleDeleteSeller = async (seller: any) => {
    try {
      setDeleteBtnLoading(true);
      const leadsCountTemp = await getLeadsCount(seller.id);
      if (leadsCountTemp?.leadsCount > 0) {
        setSelectedSeller(seller);
        setLeadsCount(leadsCountTemp?.leadsCount);
        setOpenDeleteModal(true);
      } else {
        present({
          cssClass: "my-css",
          header: "Delete Seller",
          message: "Are you sure you want to delete this seller? This action is irreversible",
          buttons: [
            "Cancel",
            {
              text: "Ok", handler: async (d) => {
                await deleteUser(seller.id)
              }
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteBtnLoading(false);
    }
  }
  useEffect(() => {
    fetchUsers(limit, offset);
  }, []);
  useEffect(() => {
    let usersTemp: any[] = [];
    users.map((user: any) => {
      usersTemp.push({
        id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        type: user.role,
        isVerified: user.isVerified
      })
    })
    setUsersData([...usersTemp])
  }, [users])
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <Sidebar title="Users" />
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center">
            <Link to="/admin/add-users">
              <Button color="blue">New</Button>
            </Link>
          </FlexboxGrid>
          <Table
            loading={loading}
            virtualized
            height={600}
            className="table"
            data={usersData}
          // onRowClick={(data) => {
          //   // console.log(data);
          //   history.push(`/admin/edit-user/${data.id}`)
          // }}
          >
            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">
                ID
              </Table.HeaderCell>
              <Table.Cell className="table-row" dataKey="id" />
            </Table.Column>
            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">
                First Name
              </Table.HeaderCell>
              <Table.Cell className="table-row" dataKey="firstName" />
            </Table.Column>

            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">
                Last Name
              </Table.HeaderCell>
              <Table.Cell className="table-row" dataKey="lastName" />
            </Table.Column>

            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">Email</Table.HeaderCell>
              <Table.Cell className="table-row" dataKey="email" />
            </Table.Column>

            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">Type</Table.HeaderCell>
              <Table.Cell className="table-row" dataKey="type" />
            </Table.Column>

            <Table.Column width={250}>
              <Table.HeaderCell className="table-row">Verification Status</Table.HeaderCell>
              <Table.Cell>
                {(rowData: any) => {
                  return (
                    rowData.isVerified ? <span className="text-success">Verified</span> : <span className="text-danger">Not Verified</span>
                  )
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100}>
              <Table.HeaderCell className="table-row">
                <FiSettings />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData: any) => {
                  return (
                    <>
                      <Button style={{ marginTop: -7 }} onClick={() => history.push(`/admin/edit-user/${rowData.id}`)}>
                        <FiEdit2 />
                      </Button>
                      <Button
                        style={{ marginTop: -7, borderRadius: 0 }}
                        onClick={() => handleDeleteSeller(rowData)}
                        disabled={deleteBtnLoading}
                      >
                        <FiTrash color={deleteBtnLoading ? "#ccc" : "#f00"} />
                      </Button>
                    </>
                  )
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
          <FlexboxGrid className="pagination-wrapper" justify="end" alignItems="center">
            <Pagination
              pages={Math.ceil(userCount / limit) || 1}
              activePage={activePage}
              prev
              last
              next
              first
              onSelect={handlePageChange}
            />
          </FlexboxGrid>
        </FlexboxGrid>
        {openDeleteModal && (
          <DeleteUserPopup
            open={openDeleteModal}
            close={() => setOpenDeleteModal(false)}
            count={leadsCount}
            seller={selectedSeller}
          />
        )}
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  userCount: state.users.count,
  limit: state.users.limit,
  offset: state.users.offset,
  activePage: state.users.activePage,
  users: state.users.users,
  loading: state.users.loading,
});
const mapDispatchToProps = {
  fetchUsers,
  userSetPage,
  getLeadsCount,
  deleteUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import { FlexboxGrid, Button, IconButton, Steps, Progress } from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./Upload3.css";
//redux
import { connect } from "react-redux";
import { createLeadBulk } from "../../stores/leads/actions";

const Upload3: React.FC<{
  profile: any;
  createLeadBulk: Function;
}> = ({ profile, createLeadBulk }) => {
  const [present] = useIonAlert();
  const { Line } = Progress;
  const history = useHistory();
  const [headersSelected, setHeadersSelected] = useState<any>({});
  const [csvData, setCSVData] = useState<any[]>([]);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [headersMod, setHeadersMod] = useState<any[]>([]);

  const mapData = [
    // {
    //   name: "Business Name",
    //   sample: ""
    // },
    {
      name: "First Name*",
      sample: ""
    },{
      name: "Last Name*",
      sample: ""
    },
    // {
    //   name: "Primary Phone",
    //   sample: ""
    // },{
    //   name: "Alternate Phone",
    //   sample: ""
    // },{
    //   name: "Email",
    //   sample: ""
    // },
    {
      name: "Address*",
      sample: ""
    },
    // {
    //   name: "APT",
    //   sample: ""
    // },
    {
      name: "City*",
      sample: ""
    },{
      name: "State*",
      sample: ""
    },{
      name: "Zip*",
      sample: ""
    },
    // {
    //   name: "Country",
    //   sample: ""
    // },
    // {
    //   name: "Appointment Date (DD/MM/YYYY)",
    //   sample: ""
    // },{
    //   name: "Appointment Time (h:mm:a)",
    //   sample: ""
    // },
    // {
    //   name: "Note",
    //   sample: ""
    // },
    {
      name: "Lead Owner",
      sample: ""
    },
    // {
    //   name: "Status",
    //   sample: ""
    // },
    // {
    //   name: "Assigned To",
    //   sample: ""
    // }
    ];
  const handleImport = async () => {
    try {
      setBtnLoading(true);
      let isLeadOwnerExist = headersMod.filter((header: any) => header.label.toLowerCase() === "lead owner");
      let itemArr: any[] = [];
      for (let i = 1; i < csvData.length; i++) {
        if (
          csvData[i]?.[headersSelected[1]] &&
          csvData[i]?.[headersSelected[2]]
        ) {
          let statusId: any = sessionStorage.getItem("CSV_SELECTED_STATUS");
          let sellerId: any = sessionStorage.getItem("CSV_SELECTED_SELLER");
          
          itemArr.push({
            business_name: "N/A",
            first_name: csvData[i]?.[headersSelected[0]] || "N/A",
            last_name: csvData[i]?.[headersSelected[1]] || "N/A",
            phone: "",
            phone2: "",
            email: "",
            street_address: csvData[i]?.[headersSelected[2]] || "N/A",
            apt: "N/A",
            city: csvData[i]?.[headersSelected[3]] || "N/A",
            state: csvData[i]?.[headersSelected[4]] || "N/A",
            zip: csvData[i]?.[headersSelected[5]] || "N/A",
            country: "",
            longitude: "",
            latitude: "",
            appointment_date: "",
            appointment_time: "",
            status_id: csvData[i]?.[headersSelected[7]],
            // status_id: csvData[i]?.[headersSelected[14]] || "N/A",
            owned_by: csvData[i]?.[headersSelected[6]] || "N/A",
            note: "N/A",
            created_on: Date.now(),
            updated_on: Date.now(),
            created_by: isLeadOwnerExist.length > 0 ? csvData[i]?.[headersSelected[7]+1] : sellerId,
            // created_by:
            //     typeof csvData[i]?.[headersSelected[17]] === "string"
            //       ? csvData[i]?.[headersSelected[17]]
            //       : profile._id,
            profile_id: profile._id,
          });
        }
      }
      if (itemArr?.length) {
        setShowProgress(true);
        let i,
          j,
          chunks,
          chunkSize = 15;
        let percent = 0;
        let itemResp: any[] = [];
        for (i = 0, j = itemArr.length; i < j; i += chunkSize) {
          chunks = itemArr.slice(i, i + chunkSize);
          console.log(chunks);
          let respUploads = await createLeadBulk(chunks);
          itemResp.push(respUploads);
          percent = (i / itemArr.length) * 100;
          setProgressPercent(parseInt(percent.toString()));
        }
        setProgressPercent(parseInt("100"));
        present({
          message: `Import completed successfully!`,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {
                history.push("/admin/leads");
                sessionStorage.removeItem("CSV_HEADERS");
                sessionStorage.removeItem("CSV_DATA");
                sessionStorage.removeItem("CSV_HEADERS_SELECTED");
                sessionStorage.removeItem("CSV_SELECTED_STATUS");
                sessionStorage.removeItem("CSV_SELECTED_SELLER");
              },
            },
          ],
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    let csvHeaders: any = sessionStorage.getItem("CSV_HEADERS");
    let csvDataTemp: any = sessionStorage.getItem("CSV_DATA");
    let csvHeadersSelected: any = sessionStorage.getItem(
      "CSV_HEADERS_SELECTED"
    );
    if (
      csvHeaders === null ||
      csvDataTemp === null ||
      csvHeadersSelected === null
    ) {
      history.push("/admin/upload");
    } else {
      csvHeaders = JSON.parse(csvHeaders);
      csvHeadersSelected = JSON.parse(csvHeadersSelected);
      csvDataTemp = JSON.parse(csvDataTemp);
      setCSVData([...csvDataTemp]);
      setHeadersSelected(csvHeadersSelected);
      let headersTemp: any[] = [];
      csvHeaders.map((header: any, i: number) => {
        headersTemp.push({
          label: header,
          value: { header, i },
        });
      });
      setHeadersMod([...headersTemp]);
    }
  }, []);
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Review</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="FlexContainerInner">
          {/*<FlexboxGrid
            style={{
              width: "100%",
              borderBottom: "1px solid #0001",
              padding: 15,
            }}
          >
            <FlexboxGrid
              className=""
              style={{
                width: "100%",
                marginBottom: -5,
                flex: 2,
              }}
            >
              <img
                src="https://www.iconbunny.com/icons/media/catalog/product/1/2/1225.9-truck-icon-iconbunny.jpg"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  borderRadius: "50%",
                }}
              />
              <FlexboxGrid>
                <IonText
                  className="haeder"
                  style={{ border: "none", marginBottom: -10 }}
                >
                  Steve Steve
                </IonText>
                <IonText
                  className="text"
                  style={{ border: "none", marginBottom: 0 }}
                >
                  13610 N 49th PI
                </IonText>
              </FlexboxGrid>
            </FlexboxGrid>
             <FlexboxGrid style={{ flex: 1 }} justify="end">
              <Button style={{ marginRight: 7 }}>
                <FiChevronLeft style={{ fontSize: 16, margin: 0 }} />
              </Button>
              <Button>
                <FiChevronRight style={{ fontSize: 16, margin: 0 }} />
              </Button>
            </FlexboxGrid> 
          </FlexboxGrid>*/}

          <FlexboxGrid
            style={{ width: "100%" }}
            justify="space-between"
            alignItems="center"
          >
            <FlexboxGrid.Item style={{ flex: 1, padding: "0px 10px" }}>
              {mapData.map((item, i) => (
                <IonText
                  className="text text-muted text-right"
                  key={`name_${i}`}
                >
                  {item.name}
                </IonText>
              ))}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              style={{
                flex: 2,
                borderLeft: "1px solid #0000",
                borderRight: "1px solid #0000",
                padding: "0px 10px",
              }}
            >
              {mapData.map((item, i) => (
                <IonText className="text" key={i}>
                  {csvData[1]?.[headersSelected[i]] || ""}
                </IonText>
              ))}
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <FlexboxGrid
            className="subHeader"
            justify="center"
            alignItems="center"
          >
            <Button color="blue" onClick={() => handleImport()} disabled={btnLoading}>
              Import
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
        {showProgress && <Line percent={progressPercent} />}
        <FlexboxGrid style={{ padding: "50px 15px", width: "100%" }}>
          <Steps current={2} style={{ flex: 1 }}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Match Fields" />
            <Steps.Item title="Review" />
          </Steps>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile,
});
const mapDispatchToProps = {
  createLeadBulk,
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload3);

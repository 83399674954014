import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import { FlexboxGrid, Button, IconButton, Steps, Divider, InputPicker } from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./Upload4.css";
//redux
import { connect } from "react-redux";
import { fetchAllSellers } from "../../stores/users/actions";

const Upload4: React.FC<{
  profile: any;
  fetchAllSellers: Function;
  allSellers: any[];
}> = ({ profile, fetchAllSellers, allSellers }) => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [headersSelected, setHeadersSelected] = useState<any>({});
  const [csvData, setCSVData] = useState<any[]>([]);
  const [leadOwners, setLeadOwners] = useState<any[]>([]);
  const [sellerMod, setSellerMod] = useState<any[]>([]);

  const handleNext = () => {
    let isMapped = true;
    for (let i = 1; i < csvData.length; i++) {
      let leadIdTemp = csvData[i][headersSelected["6"]+1];
      if(leadIdTemp?.length === 0 && csvData[i].length > 0){
        isMapped = false;
      }
    }
    if(isMapped){
      sessionStorage.setItem("CSV_DATA", JSON.stringify(csvData));
      history.push("/admin/upload-review");
    }else{
      present({
        message: `Please map all the lead owners.`,
        buttons: [
          {
            text: "Ok",
            handler: (d) => {},
          },
        ],
      });
    }
  }
  const mapLeadOwnerIdWithName = (id:String, name:String) =>{    
    for (let i = 1; i < csvData.length; i++) {
      let nameTemp = csvData[i][headersSelected["6"]];
      if (nameTemp) {
        if(nameTemp == name){
          if(headersSelected["7"] > 0){
            csvData[i][headersSelected["7"]+1] = id
          }else{
            csvData[i][csvData[i].length - 1] = id
          }
        }
      }
    }
    setCSVData([...csvData])
  }
  const renderLeadOwners = (params: String) => {
    let jsx: any[] = [];
    leadOwners.map((owner:any, i:number)=>{
      if (params === "label") {
        jsx.push(
          <IonText
            className="text text-muted text-right"
            key={i}
          >
            {`${owner.name} (${owner.count})`}
          </IonText>
        )
      } else {
        jsx.push(
          <InputPicker
            data={sellerMod}
            onSelect={(value) => {
              mapLeadOwnerIdWithName(value, owner.name)
              owner.value = value
            }}
            key={i}
            value={owner.value}
          />
        )
      }
    })
    
    return jsx;
  }
  useEffect(()=>{
    fetchAllSellers();
  }, [])
  useEffect(() => {
    let sellersTemp: any = [];
    allSellers.map((seller: any) => {
      sellersTemp.push({
        value: seller._id,
        label: `${seller.firstName} ${seller.lastName}`
      })
    })
    setSellerMod(sellersTemp)
    if(allSellers.length){
      let csvHeaders: any = sessionStorage.getItem("CSV_HEADERS");
      let csvDataTemp: any = sessionStorage.getItem("CSV_DATA");
      let csvHeadersSelected: any = sessionStorage.getItem(
        "CSV_HEADERS_SELECTED"
      );
      if (
        csvHeaders === null ||
        csvDataTemp === null ||
        csvHeadersSelected === null
      ) {
        history.push("/admin/upload");
      } else {
        csvHeadersSelected = JSON.parse(csvHeadersSelected);
        csvDataTemp = JSON.parse(csvDataTemp);
        let leadOwenerArr: any = [];
        for (let i = 0; i < csvDataTemp.length; i++) {
          let name = csvDataTemp[i][csvHeadersSelected["6"]];
          if (name) {
            if(i>0){
              leadOwenerArr.push(csvDataTemp[i][csvHeadersSelected["6"]])
              csvDataTemp[i].push("")
            }else{
              csvDataTemp[i].push("Owner ID")
            }
          }
        }
        let leadOwenerCount: any = {};
        let leadOwenerCountArr: any[] = [];
        for (const num of leadOwenerArr) {
          leadOwenerCount[num] = leadOwenerCount[num] ? leadOwenerCount[num] + 1 : 1;
        }
        for (const [key, value] of Object.entries(leadOwenerCount)) {
          let nameFiltered = allSellers.filter((seller:any)=>  `${seller.firstName} ${seller.lastName}` === key)
          leadOwenerCountArr.push({
            name: key,
            count: value,
            value: nameFiltered.length > 0 ? nameFiltered[0]?._id : ""
          })
          if(nameFiltered.length){
            for (let i = 1; i < csvDataTemp.length; i++) {
              let nameTemp = csvDataTemp[i][csvHeadersSelected["6"]];
              if (nameTemp) {
                if(nameTemp == key){
                  csvDataTemp[i][csvHeadersSelected["7"] + 1] = nameFiltered[0]?._id
                }
              }
            }
          }
        }
        setLeadOwners(leadOwenerCountArr)
        setCSVData([...csvDataTemp]);
        setHeadersSelected(csvHeadersSelected);
      }
    }
  }, [allSellers])
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Map Lead Owners</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center">
            <Button color="blue" onClick={() => handleNext()}>Next</Button>
          </FlexboxGrid>
          <Divider />
          <FlexboxGrid
            style={{ width: "100%" }}
            justify="space-between"
            alignItems="center"
          >
            <FlexboxGrid.Item style={{ flex: 1, padding: "0px 10px" }}>
              {renderLeadOwners("label")}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              style={{
                flex: 2,
                borderLeft: "1px solid #0000",
                borderRight: "1px solid #0000",
                padding: "0px 10px",
              }}
            >
              {renderLeadOwners("dropdown")}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "50px 15px", width: "100%" }}>
          <Steps current={1} style={{ flex: 1 }}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Match Fields" />
            <Steps.Item title="Review" />
          </Steps>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  fetchAllSellers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload4);

import { STATS_LOADING, STATS_SET_DATA, STATS_SET_PAGE } from "./constants";
import { _fetchStats } from "./services";

export function statsSetLoading(loading) {
	return {
		type: STATS_LOADING,
		payload: loading
	};
}
export function statsSetData(params) {
	return {
		type: STATS_SET_DATA,
		payload: params
	};
}
export function statsSetPage(data) {
	return {
		type: STATS_SET_PAGE,
		payload: data
	};
}
export const fetchStats = (limit, offset) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(statsSetLoading(true));
		const token = getState().auth.token
		_fetchStats(limit, offset, token).then((resp) => {
			if (Array.isArray(resp?.stats)) {
				dispatch(statsSetData(resp));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(statsSetLoading(false));
		})
	})
};

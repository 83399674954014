import React, {useState} from "react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "rsuite";
import "./Login.css";
import { useHistory } from "react-router-dom";
import {IonSpinner} from "@ionic/react";

//redux
import { connect } from "react-redux";
import {checkLogin} from "../../stores/auth/actions";

const Login: React.FC<{
  checkLogin: Function
}> = ({checkLogin}) => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    email: "",
    password: "",
    btnLoading: false
  })
  const [error, setError] = useState<any>("");
  const handleLogin = async(e: any) =>{
    try{
      setState({...state, btnLoading: true});
      await checkLogin(state);
    }catch(err: any){
      let message = "Email/Password is incorrect"
      if(err?.response?.data?.messgae){
        message = err?.response?.data?.messgae;
      }
      setError(message);
    }finally{
      setState({...state, btnLoading: false});
    }
  }
  return (
    <>
      <FlexboxGrid justify="center" className="logincontainer">
        <Form className="loginCard" onSubmit={handleLogin}>
          <h1 className="header_text_login">Login</h1>
          {/* <Divider /> */}
          <FormGroup>
            <ControlLabel className="inputLable">Email</ControlLabel>
            <FormControl 
              name="email" 
              type="email" 
              className="input" 
              value={state.email}
              onChange={(value)=>setState({...state, email: value})}
              required
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel className="inputLable">Password</ControlLabel>
            <FormControl
              name="password"
              type="password"
              className="input"
              value={state.password}
              onChange={(value)=>setState({...state, password: value})}
              required
            />
          </FormGroup>
          <FormGroup>
            <strong className="text-danger">{error}</strong>
          </FormGroup>
          <FormGroup>
            <Button 
              appearance="primary" 
              className="btn-login" 
              color="blue" 
              type="submit"
              disabled={state.btnLoading}
            >
              {state.btnLoading ? <IonSpinner name="dots" /> : "Continue"}
            </Button>
            <Button onClick={() => {history.push('/forgot-password')}} className="btn-forgot" >Forgot Password</Button>
          </FormGroup>
        </Form>
      </FlexboxGrid>
    </>
  );
};

const mapStateToProps = (state:any) => ({
  token: state.auth.token,
});
const mapDispatchToProps = {
  checkLogin
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
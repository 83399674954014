import React, { useState, useEffect } from "react";
import { FlexboxGrid, Divider, ButtonToolbar, IconButton, Icon } from "rsuite";
import { IonText, useIonAlert, IonSpinner } from "@ionic/react";
import { FiMousePointer } from "react-icons/fi";
import "./Routes.css";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import { useHistory } from "react-router-dom";
// const AnyReactComponent = ({ text }) => <div>{text}</div>;
import moment from "moment";
//redux
import { connect } from "react-redux";
import { fetchRoutes, deleteRoute, routesResetFormData } from "../../stores/routes/actions";

import { getDistanceInKm } from "../../utils";
const Routes: React.FC<{
  routes: any;
  profile: any;
  fetchRoutes: Function;
  deleteRoute: Function;
  routesResetFormData: Function
}> = ({ routes, profile, fetchRoutes, deleteRoute, routesResetFormData }) => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const handleFetchRoutes = async () => {
    try {
      await fetchRoutes(profile._id);
    } catch (err) {

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (profile?._id) {
      handleFetchRoutes();
    }
  }, [profile])
  return (
    <>
      <ButtonToolbar className="home-floating-btns">
        <IconButton
          icon={<Icon icon="plus" />}
          size="lg"
          onClick={() => {
            routesResetFormData();
            history.push("/add-route");
          }}
        />
        {/* <IconButton
          icon={<Icon icon="filter" />}
          size="lg"
          onClick={() => {
            history.push("/filter");
          }}
        /> */}
      </ButtonToolbar>
      <FlexboxGrid style={{ height: 110 }} />
      {loading ? (
        <FlexboxGrid justify="center" >
          <IonSpinner name="bubbles" color="#000" />
        </FlexboxGrid>
      ) : (
        <>
          {routes.length === 0 && (
            <FlexboxGrid justify="center" >
              <IonText style={{ fontSize: 18, width: "100%", textAlign: "center" }}>No routes found!</IonText>
            </FlexboxGrid>
          )}
          <SwipeableList>
            {routes.map((item: any, i: number) => (
              <SwipeableListItem
                key={i}
                swipeLeft={{
                  content: (
                    <FlexboxGrid className="routes-item-delete">
                      {" "}
                      <IonText className="routes-item-delete-text">
                        {" "}
                        Delete Route{" "}
                      </IonText>{" "}
                    </FlexboxGrid>
                  ),
                  action: () =>
                    present({
                      cssClass: "my-css",
                      header: "Alert",
                      message: "Are you sure you want to delete the Lead. The action is irreversible",
                      buttons: [
                        "Cancel",
                        { text: "Ok", handler: (d) => deleteRoute(item._id) },
                      ],
                      onDidDismiss: (e) => console.log("did dismiss"),
                    }),
                }}
              >
                <FlexboxGrid className="routes-item" onClick={() => { history.push(`/add-route/${item._id}`) }}>
                  <FlexboxGrid className="routes-item-row">
                    <IonText className="routes-item-header">
                      {item.name}
                    </IonText>
                  </FlexboxGrid>
                  <FlexboxGrid className="routes-item-row">
                    <IonText className="routes-item-sub"> {moment(item.created_on).format("DD/MM/YYYY HH:mm")} </IonText>
                  </FlexboxGrid>
                  <FlexboxGrid className="routes-item-row">
                    <IonText className="routes-item-sub-colored">{getDistanceInKm(parseFloat(item.starting_address_lat), parseFloat(item.starting_address_long), parseFloat(item.ending_address_lat), parseFloat(item.ending_address_long))} KM</IonText>
                    <IonText className="routes-item-sub-colored">
                      , 3 hrs 0 min ,   
                    </IonText>
                    <IonText className="routes-item-sub-colored"> {Array.isArray(item.leads) ? item.leads.length : 0} stops</IonText>
                  </FlexboxGrid>
                </FlexboxGrid>
              </SwipeableListItem>
            ))}
          </SwipeableList>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  routes: state.routes.routes,
  profile: state.profile.profile,
});
const mapDispatchToProps = {
  fetchRoutes,
  deleteRoute,
  routesResetFormData
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
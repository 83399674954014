import React, { useState, useEffect } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  IconButton,
  Checkbox,
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import {
  FaTrashAlt,
} from "react-icons/fa";
import "./AddLeads2.css";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../config/";

//redux
import { connect } from "react-redux";
import { fetchLeadsByStatus } from "../../stores/leads/actions";
import { routesSetFormData } from "../../stores/routes/actions";

const AddLeads2: React.FC<{
  formData: any;
  fetchLeadsByStatus: Function;
  leads: any[];
  routesSetFormData: Function;
  profile: any;
}> = ({ formData, fetchLeadsByStatus, leads, routesSetFormData, profile }) => {
  const history = useHistory();
  const [selectedLeads, setSelectedLeads] = useState<any[]>([]);

  const handleSelectRow = (item: any, checked: boolean) => {
    if (checked) {
      setSelectedLeads((lead: any) => {
        let leadsArr: any[] = [...lead];
        let filteredLeads = lead.filter((ld: any) => ld._id === item._id);
        if (!filteredLeads.length) {
          leadsArr.push(item);
        }
        return leadsArr;
      });
    } else {
      setSelectedLeads((lead: any) => {
        let filteredLeads = lead.filter(
          (lead: any) => lead._id !== item._id
        );
        return filteredLeads;
      });
    }
  };
  const handleSelectAll = () =>{
    setSelectedLeads(leads);
  }
  useEffect(() => {
    if (formData?.selectedStatuses?.length === 0) {
      history.push("/add-route")
    } else {
      fetchLeadsByStatus({ status_id: formData.selectedStatuses.map((status: any) => status._id), user_id: profile._id}, "route")
      if(formData.leads?.length){
        setSelectedLeads([...formData.leads])
      }
    }
  }, [formData])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => { history.goBack() }}
            />
            <IonText className="header_text">Add Leads</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid
          justify="space-between"
          alignment="center"
          className="w-100 row"
        >
          <IonText>{selectedLeads.length}/{leads.length}</IonText>
          <Button 
            className=""
            onClick={()=>handleSelectAll()}
          >
            Select all
          </Button>
        </FlexboxGrid>
        <FlexboxGrid justify="center" className="row">
          {leads.map((item, i) => (
            <FlexboxGrid className="flex-row" key={i}>
              <Checkbox 
                onChange={(value, checked)=>{
                  handleSelectRow(item, checked)
                }}
                checked={selectedLeads.filter((ld: any) => ld._id === item._id).length ? true : false}
              />
              <FlexboxGrid justify="center" className="tag-btn-wrapper">
                <img
                  src={`${API_URL}${item?.status_id?.image}`}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 5,
                    borderRadius: "50%",
                  }}
                />
              </FlexboxGrid>
              <FlexboxGrid className="route_column">
                <IonText className="textFlex">{item.first_name} {item.last_name}</IonText>
                <IonText className="textFlex">
                  {item.street_address?.length > 0 ? item.street_address : ""}
                  {/* {item.apt?.length > 0 ? `, ${item.apt}` : ""}
                  {item.city?.length > 0 ? `, ${item.city}` : ""}
                  {item.state?.length > 0 ? `, ${item.state}` : ""}
                  {item.zip?.length > 0 ? `, ${item.zip}` : ""}
                  {item.country?.length > 0 ? `, ${item.country}` : ""} */}
                </IonText>
              </FlexboxGrid>
            </FlexboxGrid>
          ))}
        </FlexboxGrid>
        <FlexboxGrid
          justify="center"
          className="w-100"
          style={{ margin: "15px 0px" }}
        >
          <Button
            color="blue"
            className="btn"
            onClick={() => {
              history.push("/add-route");
              routesSetFormData({...formData, leads: [...selectedLeads]})
            }}
            disabled={selectedLeads.length === 0}
          >
            Add
          </Button>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  formData: state.routes.formData,
  leads: state.leads.filteredLeads,
  profile: state.profile.profile,
});
const mapDispatchToProps = {
  fetchLeadsByStatus,
  routesSetFormData
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLeads2);
import { LEADS_LOADING, LEADS_SET_DATA, LEADS_SET_DETAILS, LEADS_REMOVE_LEAD, LEADS_SET_FILTERED_LEADS, LEADS_SET_APPOINTMENTS, LEADS_SET_LOCATION_PREDICTION, LEADS_SET_MAP_LEADS, LEADS_SET_MAP_FILTERS, LEADS_SET_PAGE, LEADS_SET_ALL_DATA } from "./constants";
import { _fetchLeads, _deleteLead, _createLead, _fetchLeadDetails, _updateLead, _fetchLeadsByStatus, _fetchAppointments, _fetchLocationDetails, _fetchLocationPredictions, _fetchLocationFromLongLat, _updateBulkStatus, _fetchAllLeads, _searchLeads, _createLeadBulk, _deleteLeadBulk, _assignSellerBulk, _filterLeads } from "./services";

export function leadsSetLoading(loading) {
	return {
		type: LEADS_LOADING,
		payload: loading
	};
}
export function leadsSetData(params) {
	return {
		type: LEADS_SET_DATA,
		payload: params
	};
}
export function leadsSetDetails(params) {
	return {
		type: LEADS_SET_DETAILS,
		payload: params
	};
}
export function leadsRemoveLead(id) {
	return {
		type: LEADS_REMOVE_LEAD,
		payload: id
	};
}
export function leadsSetFilteredLeads(params) {
	return {
		type: LEADS_SET_FILTERED_LEADS,
		payload: params
	};
}
export function leadsSetAppointments(params) {
	return {
		type: LEADS_SET_APPOINTMENTS,
		payload: params
	};
}
export const setLocationPredictions = (predictions) => ({
	type: LEADS_SET_LOCATION_PREDICTION,
	payload: predictions,
});
export function leadsSetMapLeads(params) {
	return {
		type: LEADS_SET_MAP_LEADS,
		payload: params
	};
}
export function leadsSetMapFilters(params) {
	return {
		type: LEADS_SET_MAP_FILTERS,
		payload: params
	};
}
export function leadsSetPage(data) {
	return {
		type: LEADS_SET_PAGE,
		payload: data
	};
}
export function leadsSetAllData(data) {
	return {
		type: LEADS_SET_ALL_DATA,
		payload: data
	};
}
export const fetchLeads = (user_id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_fetchLeads(user_id).then((resp) => {
			if (resp?.leads) {
				dispatch(leadsSetData(resp.leads));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const deleteLead = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_deleteLead(id).then((resp) => {
			dispatch(leadsRemoveLead(id));
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const createLead = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_createLead(formData).then((resp) => {
			dispatch(fetchLeads(formData.user_id));
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const fetchLeadDetails = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_fetchLeadDetails(id).then((resp) => {
			if (resp?.lead) {
				dispatch(leadsSetDetails(resp.lead));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const updateLead = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_updateLead(formData).then((resp) => {
			dispatch(fetchLeads(formData.user_id));
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const updateBulkStatus = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		const { profile } = getState().profile;
		_updateBulkStatus(formData).then(async (resp) => {
			await dispatch(fetchLeadsByStatus({ status_id: [], user_id: profile._id }, "map"));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const fetchLeadsByStatus = (formData, source) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_fetchLeadsByStatus(formData).then((resp) => {
			if (resp?.leads) {
				if (source === "map") {
					dispatch(leadsSetMapLeads(resp.leads));
				} else {
					dispatch(leadsSetFilteredLeads(resp.leads));
				}
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const fetchAppointments = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_fetchAppointments(formData).then((resp) => {
			if (resp?.leads) {
				dispatch(leadsSetAppointments(resp.leads));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const fetchLocationDetails = (location) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		_fetchLocationDetails(location)
			.then(async (res) => {
				let state = "",
					city = "",
					postalCode = "",
					country = "",
					geometry = {};
				if (res.data?.results?.length) {
					for (
						var i = 0;
						i < res.data.results[0].address_components.length;
						i++
					) {
						if (
							res.data.results[0].address_components[i].types.includes(
								"administrative_area_level_1"
							)
						) {
							state = res.data.results[0].address_components[i].short_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"administrative_area_level_2"
							)
						) {
							city = res.data.results[0].address_components[i].long_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"postal_code"
							)
						) {
							postalCode = res.data.results[0].address_components[i].long_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"country"
							)
						) {
							country = res.data.results[0].address_components[i].long_name;
						}
					}
					geometry = res.data?.results[0]?.geometry?.location;
				}
				resolve({ city, postalCode, state, country, geometry })
			})
			.catch(async (err) => {
				reject(err)
				console.log(err);
			});
	})
};
export const fetchLocationPredictions = (destination) => async (
	dispatch,
	getState
) => {
	_fetchLocationPredictions(destination)
		.then(async (res) => {
			await dispatch(setLocationPredictions(res.data.predictions));
		})
		.catch(async (err) => { });
};
export const fetchLocationFromLongLat = (geometry) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		_fetchLocationFromLongLat(geometry)
			.then(async (res) => {
				let state = "",
					city = "",
					postalCode = "",
					country = "",
					geometry = {};
				if (res.data?.results?.length) {
					console.log(res.data);
					for (
						var i = 0;
						i < res.data.results[0].address_components.length;
						i++
					) {
						if (
							res.data.results[0].address_components[i].types.includes(
								"administrative_area_level_1"
							)
						) {
							state = res.data.results[0].address_components[i].short_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"administrative_area_level_2"
							)
						) {
							city = res.data.results[0].address_components[i].long_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"postal_code"
							)
						) {
							postalCode = res.data.results[0].address_components[i].long_name;
						} else if (
							res.data.results[0].address_components[i].types.includes(
								"country"
							)
						) {
							country = res.data.results[0].address_components[i].long_name;
						}
					}
					geometry = res.data?.results[0]?.geometry?.location;
				}
				resolve({ city, postalCode, state, country, geometry, location: res.data?.results[0]?.formatted_address })
			})
			.catch(async (err) => {
				reject(err)
				console.log(err);
			});
	})
};
export const fetchAllLeads = (limit, offset, params = null) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const token = getState().auth.token
		dispatch(leadsSetLoading(true));
		_fetchAllLeads(limit, offset, params, token).then((resp) => {
			if (resp?.leads) {
				dispatch(leadsSetAllData(resp));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const searchLeads = (searchKey, searchValue) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_searchLeads(searchKey, searchValue).then((resp) => {
			if (resp?.leads) {
				dispatch(leadsSetAllData(resp));
				dispatch(leadsSetPage({
					activePage: 1,
					offset: 0,
					limit: resp.count
				}))
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const filterLeads = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_filterLeads(params).then((resp) => {
			if (resp?.leads) {
				dispatch(leadsSetAllData(resp));
				dispatch(leadsSetPage({
					activePage: 1,
					offset: 0,
					limit: resp.count
				}))
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const createLeadBulk = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_createLeadBulk(formData).then((resp) => {
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const deleteLeadBulk = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		_deleteLeadBulk(formData).then((resp) => {
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
export const assignSellerBulk = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(leadsSetLoading(true));
		const { profile } = getState().profile;
		_assignSellerBulk(formData).then(async (resp) => {
			await dispatch(fetchLeadsByStatus({ status_id: [], user_id: profile._id }, "map"));
			await dispatch(fetchLeads(profile._id));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(leadsSetLoading(false));
		})
	})
};
import { USERS_LOADING, USERS_SET_DATA, USERS_SET_PAGE, USERS_SET_DETAILS, USERS_SET_ALL_SELLERS} from "./constants";
import { _fetchUsers, _createUser, _deleteUser, _editUser, _fetchUserDetails, _fetchAllSellers, _getLeadsCount, _deleteUserWithAllRecord, _deleteUserWithReassignLeads } from "./services";

export function userSetLoading(loading) {
	return {
		type: USERS_LOADING,
		payload: loading
	};
}
export function userSetData(params) {
	return {
		type: USERS_SET_DATA,
		payload: params
	};
}
export function userSetPage(data) {
	return {
		type: USERS_SET_PAGE,
		payload: data
	};
}
export function userSetDetails(data) {
	return {
		type: USERS_SET_DETAILS,
		payload: data
	};
}
export function userSetAllSellers(data) {
	return {
		type: USERS_SET_ALL_SELLERS,
		payload: data
	};
}
export const fetchUsers = (limit, offset) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_fetchUsers(limit, offset).then((resp) => {
			if (Array.isArray(resp?.users)) {
				dispatch(userSetData(resp));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const createUser = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_createUser(formData).then((resp) => {
			const {limit, offset} = getState().users;
			dispatch(fetchUsers(limit, offset));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const deleteUser = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_deleteUser(id).then((resp) => {
			const {limit, offset} = getState().users;
			dispatch(fetchUsers(limit, offset));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const editUser = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_editUser(formData).then((resp) => {
			const {limit, offset} = getState().users;
			dispatch(fetchUsers(limit, offset));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const fetchUserDetails = (id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_fetchUserDetails(id).then((resp) => {
			dispatch(userSetDetails(resp));
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const fetchAllSellers = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const token = getState().auth.token
		dispatch(userSetLoading(true));
		_fetchAllSellers(token).then((resp) => {
			if (Array.isArray(resp?.users)) {
				dispatch(userSetAllSellers(resp));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const getLeadsCount = (user_id) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_getLeadsCount(user_id).then((resp) => {
			resolve(resp)
		}).catch(err => {
			reject(err);
		})
	})
};
export const deleteUserWithAllRecord = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_deleteUserWithAllRecord(id).then((resp) => {
			const {limit, offset} = getState().users;
			dispatch(fetchUsers(limit, offset));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
export const deleteUserWithReassignLeads = (id, assign_to) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(userSetLoading(true));
		_deleteUserWithReassignLeads(id, assign_to).then((resp) => {
			const {limit, offset} = getState().users;
			dispatch(fetchUsers(limit, offset));
			resolve(resp);
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(userSetLoading(false));
		})
	})
};
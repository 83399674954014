import React, { useEffect, useState } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  IconButton,
  InputPicker,
  Alert
} from "rsuite";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config";
import { useHistory } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {assignSellerBulk} from "../../stores/leads/actions"
import {fetchAllSellers} from "../../stores/users/actions"
const BulkAssignSeller: React.FC<{
  statuses: any[];
  location: any;
  assignSellerBulk: Function;
  fetchAllSellers: Function;
  allSellers: any[];
}> = ({
  statuses,
  location,
  assignSellerBulk,
  fetchAllSellers,
  allSellers
}) => {
    const history = useHistory();
    const [leadIds, setLeadIds] = useState<any[]>([]);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [sellerMod, setSellerMod] = useState<any[]>([]);
    const [selectedSellerId, setSelectedSellerId] = useState<string>("");

    useEffect(()=>{
      const query = new URLSearchParams(location.search);
      const ids = query.get('ids');
      if(ids){
        setLeadIds(ids.split(','))
      }
      fetchAllSellers();
    }, [])
    const handleUpdateSeller = async() =>{
      try{
        if(selectedSellerId.length === 0){
          Alert.error('Please select a seller to continue');
        }else{
          setBtnLoading(true);
          await assignSellerBulk({
            ids: leadIds,
            created_by: selectedSellerId
          });
          Alert.success('Seller assigned successfully!');
          history.push("/home");
        }
      }catch(err){

      }finally{
        setBtnLoading(false);
      }
    }
    useEffect(()=>{
      let sellersTemp:any=[];
      allSellers.map((seller:any)=>{
        sellersTemp.push({
          value: seller._id,
          label: `${seller.firstName} ${seller.lastName}`
        })
      })
      setSellerMod(sellersTemp)
    }, [allSellers])
    
    return (
      <>
        <FlexboxGrid className="AddLeads">
          <FlexboxGrid className="header">
            <FlexboxGrid className="">
              <IconButton
                className="back-btn"
                icon={<FiArrowLeft style={{ fontSize: 18 }} />}
                circle
                size="xs"
                onClick={() => {
                  history.goBack();
                }}
              />
              <IonText className="header_text">Assign Seller</IonText>
            </FlexboxGrid>
          </FlexboxGrid>
          <FlexboxGrid justify="center" className="row" style={{width: "100%"}}>
            <InputPicker 
              data={sellerMod} 
              onSelect={(value) => setSelectedSellerId(value)}
              placeholder="Choose Seller"
            />
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button 
              color="blue" 
              className="btn" 
              onClick={() => handleUpdateSeller()}
              disabled={btnLoading}
            >
              {btnLoading ? <IonSpinner color={"#fff"}/> : "Submit"}
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </>
    );
  };
const mapStateToProps = (state: any) => ({
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  assignSellerBulk,
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(BulkAssignSeller);
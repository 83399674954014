import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  Divider,
  IconButton,
  InputPicker,
  Steps,
} from "rsuite";
import { FiRefreshCw, FiArrowLeft, FiEdit2 } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";
import "./Upload2.css";

//redux
import { connect } from "react-redux";
import { fetchAllSellers } from "../../stores/users/actions";

const Upload2: React.FC<{
  statuses: any[];
  fetchAllSellers: Function;
  allSellers: any[];
}> = ({ statuses, allSellers, fetchAllSellers }) => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [headersMod, setHeadersMod] = useState<any[]>([]);
  const [csvDataMod, setCSVDataMod] = useState<any[]>([]);
  const [headersSelected, setHeadersSelected] = useState<any>({});
  const [statusMod, setStatusMod] = useState<any[]>([]);
  const [sellerMod, setSellerMod] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedSelller, setSelectedSeller] = useState<string>("");

  const [mapData, setMapData] = useState([
    // {
    //   name: "Business Name",
    //   sample: ""
    // },
    {
      name: "First Name*",
      sample: "",
      value: ""
    }, {
      name: "Last Name*",
      sample: "",
      value: ""
    },
    // {
    //   name: "Primary Phone",
    //   sample: ""
    // },{
    //   name: "Alternate Phone",
    //   sample: ""
    // },{
    //   name: "Email",
    //   sample: ""
    // },
    {
      name: "Address*",
      sample: "",
      value: ""
    },
    // {
    //   name: "APT",
    //   sample: ""
    // },
    {
      name: "City*",
      sample: "",
      value: ""
    }, {
      name: "State*",
      sample: "",
      value: ""
    }, {
      name: "Zip*",
      sample: "",
      value: ""
    },
    // {
    //   name: "Country",
    //   sample: ""
    // },
    // {
    //   name: "Appointment Date (DD/MM/YYYY)",
    //   sample: ""
    // },{
    //   name: "Appointment Time (h:mm:a)",
    //   sample: ""
    // },
    // {
    //   name: "Note",
    //   sample: ""
    // },
    {
      name: "Lead Owner*",
      sample: "",
      value: ""
    },
    {
      name: "Status",
      sample: ""
    },
    // {
    //   name: "Assigned To",
    //   sample: ""
    // }
  ])
  const handleNext = () => {
    var props = [0, 1, 2, 3, 4, 5, 6, 7];
    var hasAll = props.every(prop => headersSelected.hasOwnProperty(prop));
    if (hasAll) {
      sessionStorage.setItem("CSV_HEADERS_SELECTED", JSON.stringify(headersSelected));
      sessionStorage.setItem("CSV_SELECTED_STATUS", selectedStatus);
      sessionStorage.setItem("CSV_SELECTED_SELLER", selectedSelller);

      let isLeadOwnerExist = headersMod.filter((header: any) => header.label.toLowerCase() === "lead owner");
      if(isLeadOwnerExist.length){
        history.push("/admin/upload-map-owner");
      }else{
        history.push("/admin/upload-review");
      }
    } else {
      present({
        message: `Please map all the required columns!`,
        buttons: [
          { text: "Ok", handler: (d) => console.log("ok pressed") },
        ],
      });
    }
  }
  const handleSelectField = (value: any, i: number, index2:any) => {
    let tempMapData = [...mapData];
    if (tempMapData[i]) {
      tempMapData[i].sample = csvDataMod[1]?.[value.i]
      if(typeof index2 === 'number'){
        tempMapData[i].value = headersMod[index2]?.value
      }else{
        tempMapData[i].value = headersMod[i]?.[value.i]
      }
      setMapData([...tempMapData])
      setHeadersSelected((headersSelectedTemp:any)=>{
        return {
          ...headersSelectedTemp,
          [i]: value.i,
        }
      })
    }
  }
  useEffect(() => {
    let csvHeaders: any = sessionStorage.getItem("CSV_HEADERS");
    let csvData: any = sessionStorage.getItem("CSV_DATA");
    if (csvHeaders === null || csvData === null) {
      history.push("/admin/upload")
    } else {
      csvHeaders = JSON.parse(csvHeaders);
      csvData = JSON.parse(csvData);
      setCSVDataMod([...csvData])
      let headersTemp: any[] = [];
      csvHeaders.map((header: any, i: number) => {
        headersTemp.push({
          label: header,
          value: { header, i },
        });
      });
      setHeadersMod([...headersTemp]);
      fetchAllSellers();
    }
  }, [])
  useEffect(() => {
    headersMod.map((header: any, i: number) => {
      switch(header.label){
        case "First Name":
          handleSelectField(header.value, 0, i)
          break;
        case "Last Name":
          handleSelectField(header.value, 1, i)
          break;
        case "Address":
          handleSelectField(header.value, 2, i)
          break;
        case "City":
          handleSelectField(header.value, 3, i)
          break;
        case "State":
          handleSelectField(header.value, 4, i)
          break;
        case "Zip":
          handleSelectField(header.value, 5, i)
          break;
        case "Lead Owner":
          handleSelectField(header.value, 6, i)
          break;
        case "Status":
          handleSelectField(header.value, 7, i)
          break;
      }      
    })
  }, [headersMod])
  useEffect(() => {
    let statusTemp: any[] = [];
    statuses.map((status: any) => {
      statusTemp.push({
        label: status.name,
        value: status._id
      })
    })
    setStatusMod(statusTemp)
  }, [statuses])
  useEffect(() => {
    let sellersTemp: any = [];
    allSellers.map((seller: any) => {
      sellersTemp.push({
        value: seller._id,
        label: `${seller.firstName} ${seller.lastName}`
      })
    })
    setSellerMod(sellersTemp)
  }, [allSellers])
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Matching Fields</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center">
            <Button color="blue" onClick={() => handleNext()}>Next</Button>
          </FlexboxGrid>
          <Divider />

          <FlexboxGrid
            style={{ width: "100%" }}
            justify="space-between"
            alignItems="center"
          >
            <FlexboxGrid.Item style={{ flex: 1, padding: "0px 10px" }}>
              <IonText className="haeder">CSV</IonText>
              {mapData.map((item, i) => (
                <IonText className="text" key={`name_${i}`}>{item.name}</IonText>
              ))}
              {/* <IonText className="text">Status</IonText>
              <IonText className="text">Assigned To</IonText> */}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              style={{
                flex: 1,
                borderLeft: "1px solid #0000",
                borderRight: "1px solid #0000",
                padding: "0px 10px",
              }}
            >
              <IonText className="haeder">Sample</IonText>
              {mapData.map((item, i) => (
                <IonText className="text" key={`sample_${i}`}>{item.sample}</IonText>
              ))}
              {/* <IonText className="text" ></IonText>
              <IonText className="text" ></IonText> */}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item style={{ flex: 1, padding: "0px 10px" }}>
              <IonText className="haeder">Fields</IonText>
              {mapData.map((item, i) => {
                let isLeadOwnerExist = headersMod.filter((header: any) => header.label.toLowerCase() === "lead owner");
                if (item.name === "Lead Owner*" && isLeadOwnerExist.length == 0) {
                  return (
                    <InputPicker
                      data={sellerMod}
                      onSelect={(value) => {
                        setSelectedSeller(value)
                        handleSelectField(value, i, null)
                      }}
                    />
                  )
                } else {
                  return (
                    <InputPicker
                      data={headersMod}
                      key={`fields_${i}`}
                      onSelect={(value) => {
                        handleSelectField(value, i, null)
                      }}
                      // value={headersMod[i]?.value}
                      value={mapData[i]?.value}
                    />
                  )
                }
              })}
              {/* <InputPicker 
                  data={statusMod} 
                  onSelect={(value) => {
                    setSelectedStatus(value)
                  }}
                />
                <InputPicker 
                  data={sellerMod} 
                  onSelect={(value) => {
                    setSelectedSeller(value)
                  }}
                /> */}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "50px 15px", width: "100%" }}>
          <Steps current={1} style={{ flex: 1 }}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Match Fields" />
            <Steps.Item title="Review" />
          </Steps>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload2);
import React, { useState, useEffect } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  IconButton,
} from "rsuite";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config/";
import "./AddLeads.css";

//redux
import { connect } from "react-redux";
import { routesSetFormData } from "../../stores/routes/actions";

const AddLeads: React.FC<{
  statuses: any;
  routesSetFormData: Function;
  formData: any[];
}> = ({ statuses, routesSetFormData, formData }) => {
  const history = useHistory();
  const [selectedStatuses, setSelectedStatuses] = useState<any[]>([]);

  const handleSelectRow = (item: any, checked: boolean) => {
    if (checked) {
      setSelectedStatuses((status: any) => {
        let statusArr: any[] = [...status];
        let filteredStatuses = status.filter((sts: any) => sts._id === item._id);
        if (!filteredStatuses.length) {
          statusArr.push(item);
        }
        return statusArr;
      });
    } else {
      setSelectedStatuses((status: any) => {
        let filteredStatuses = status.filter(
          (status: any) => status._id !== item._id
        );
        return filteredStatuses;
      });
    }
  };
  const handleSelectAll = () =>{
    setSelectedStatuses(statuses);
  }
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Add Leads</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid
          justify="space-between"
          alignment="center"
          className="w-100 row"
        >
          <IonText>Leads Statuses</IonText>
          <Button className="" onClick={()=>handleSelectAll()}>Select all</Button>
        </FlexboxGrid>
        {statuses?.length > 0 && (
          <FlexboxGrid justify="center" className="row">
            {statuses.map((status: any, i: number) => (
              <FlexboxGrid
                justify="center"
                className={`tag-btn-wrapper ${selectedStatuses.filter((sts: any) => sts._id === status._id).length ? "status-selected" : ""}`}
                key={i}
                style={{ margin: 5 }}
                onClick={() => handleSelectRow(status, !selectedStatuses.filter((sts: any) => sts._id === status._id).length)}
              >
                <img
                  src={`${API_URL}${status?.image}`}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 5,
                    borderRadius: "50%",
                  }}
                />
                <IonText>{status.name}</IonText>
              </FlexboxGrid>
            ))}
          </FlexboxGrid>
        )}
        <FlexboxGrid
          justify="center"
          className="w-100"
          style={{ margin: "15px 0px" }}
        >
          <Button
            onClick={() => {
              routesSetFormData({...formData, selectedStatuses})
              history.push("/add-leads-2");
            }}
            color="blue"
            className="btn"
            disabled={selectedStatuses.length == 0}
          >
            Next
          </Button>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  formData: state.routes.formData,
});
const mapDispatchToProps = {
  routesSetFormData
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLeads);
import { FC, useState } from "react";
import { useIonAlert } from "@ionic/react";
import { Modal, Button, FlexboxGrid, Alert } from "rsuite";
import { IonText } from "@ionic/react";
import { API_URL } from "../../config/";
//redux
import { connect } from "react-redux";
import { fetchAllLeads, updateBulkStatus } from "../../stores/leads/actions";

const BulkStatusUpdateModal: FC<{
  open: boolean;
  statuses: any[];
  handleClose: () => void;
  selectedRows: any[];
  fetchAllLeads: Function;
  updateBulkStatus: Function;
  limit: number;
  offset: number;
}> = ({
  statuses,
  open,
  handleClose,
  selectedRows,
  fetchAllLeads,
  updateBulkStatus,
  limit,
  offset,
}) => {
  const [present] = useIonAlert();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const handleSelectRow = (item: any) => {
    setSelectedStatus(item);
  };
  const handleCloseModal = () => {
    handleClose();
    setSelectedStatus(null);
  };
  const handleSubmit = async () => {
    try {
      handleClose();
      present({
        cssClass: "my-css",
        header: "Update Bulk Status",
        message: `Are you sure you want to update ${selectedRows.length} leads to ${selectedStatus.name} status?`,
        buttons: [
          "Cancel",
          {
            text: "Ok",
            handler: async (d) => {
              setBtnLoading(true);
              await updateBulkStatus({
                ids: selectedRows.map((row) => row.id),
                status_id: selectedStatus._id,
              });
              fetchAllLeads(limit, offset);
              Alert.success("Status Updated Successfully!");
            },
          },
        ],
        onDidDismiss: () => {},
      });
    } catch (err) {
        Alert.error("Something went wrong!");
    } finally {
      setBtnLoading(false);
    }
  };
  return (
    <Modal size={"sm"} show={open} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center" }}>
          Update Lead Status
        </Modal.Title>
        <small style={{ textAlign: "center", display: "block" }}>
          {selectedRows.length} leads will be updated to the selected status
        </small>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <FlexboxGrid
          justify="space-between"
          alignment="center"
          className="w-100 row"
        >
          <IonText className="header_text">Lead Statuses</IonText>
        </FlexboxGrid>
        <FlexboxGrid justify="center" className="row">
          {statuses.map((status, i) => (
            <FlexboxGrid
              justify="center"
              className={`tag-btn-wrapper ${
                selectedStatus?._id === status._id
                  ? "status-selected"
                  : "status-images-wrapper"
              }`}
              key={i}
              onClick={() => handleSelectRow(status)}
            >
              <img src={`${API_URL}${status.image}`} />
              <IonText className="status-name">{status.name}</IonText>
            </FlexboxGrid>
          ))}
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          appearance="primary"
          loading={btnLoading}
          disabled={selectedStatus === null}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  limit: state.leads.limit,
  offset: state.leads.offset,
});
const mapDispatchToProps = {
  fetchAllLeads,
  updateBulkStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkStatusUpdateModal);

import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  Button,
  Alert
} from "rsuite";
import { useHistory } from "react-router-dom";
import "./Notification.css";

// components
import Sidebar from "../../components/template/sidebar/Sidebar";
import AddNotificationModal from "./AddNotificationModal";
import EditNotificationModal from "./EditNotificationModal";

//redux
import { connect } from "react-redux";
import { fetchNotifications, deleteNotification } from "../../stores/notifications/actions";
const Notification: React.FC<{
  fetchNotifications: Function;
  notifications: any[];
  deleteNotification: Function;
}> = ({
  fetchNotifications,
  notifications,
  deleteNotification
}) => {
    const [present] = useIonAlert();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [notificationObj, setNotificationObj] = useState<any>({});

    const handleDeleteNotification = async(item:any) =>{
      try{
        present({
          cssClass: "my-css",
          header: "Delete Notification",
          message: "Are you sure you want to delete this notification. The action is irreversible",
          buttons: [
            "Cancel",
            {
              text: "Ok", handler: async(d) => {
                await deleteNotification(item._id)
                Alert.success('Notification deleted successfully!');
              }
            },
          ],
          onDidDismiss: (e) => console.log("did dismiss"),
        })
      }catch(err){
        Alert.error('Something went wrong!');
      }
    }
    useEffect(() => {
      fetchNotifications();
    }, [])
    return (
      <>
        <FlexboxGrid className="AddLeads">
          <Sidebar title="Notifications" />
          <FlexboxGrid className="AddLeadsInner">
            <IonText className="subHeaderTextAddLeads">MANAGE NOTIFICATIONS</IonText>
            {notifications.map((notification: any, i: number) => (
              <Form className="w-100 formCard" style={{ marginBottom: 20 }} key={i}>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Name</ControlLabel>
                  <IonText className="text">{notification.name}</IonText>
                </FormGroup>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Interval</ControlLabel>
                  <IonText className="text" style={{textTransform: "lowercase"}}>{notification.interval} {notification.unit}</IonText>
                </FormGroup>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Type</ControlLabel>
                  <IonText className="text">{notification.type}</IonText>
                </FormGroup>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Message</ControlLabel>
                  <IonText className="text">{notification.message}</IonText>
                </FormGroup>
                <FormGroup style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Button 
                    appearance="primary" 
                    style={{marginRight: 10}}
                    onClick={()=>{
                      setNotificationObj(notification);
                      setShowEdit(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button 
                    appearance="default" 
                    color="red"
                    onClick={()=>handleDeleteNotification(notification)}
                  >Delete</Button>
                </FormGroup>
              </Form>
            ))}
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button color="blue" className="btn" onClick={() => setShow(true)}>
              ADD NEW
            </Button>
          </FlexboxGrid>
          <AddNotificationModal
            show={show}
            setShow={setShow}
          />
          <EditNotificationModal
            show={showEdit}
            setShow={setShowEdit}
            notification={notificationObj}
          />
        </FlexboxGrid>
      </>
    );
  };
const mapStateToProps = (state: any) => ({
  loading: state.notifications.loading,
  notifications: state.notifications.notifications,
});
const mapDispatchToProps = {
  fetchNotifications,
  deleteNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  ButtonToolbar,

  IconButton,
  Icon,
} from "rsuite";
import { useHistory, useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config";
//redux
import { connect } from "react-redux";
import { fetchLeadsByStatus } from "../../stores/leads/actions";
import { routesSetFormData } from "../../stores/routes/actions";

const MapPage: React.FC<{}> = ({ }) => {
  const location = useLocation();
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const history = useHistory();
  const fetchCurrentLocation = async () => {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(
        async position => {
          let lat = position.coords.latitude,
            lng = position.coords.longitude;
          resolve({
            lat,
            lng
          })
        },
        err => reject(err));
    })
  }
  async function initialize(lat: string, lng: string) {
    let center: any = await fetchCurrentLocation();
    var mapOptions = {
      zoom: 14,
      center: new (window as any).google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
      mapTypeId: (window as any).google.maps.MapTypeId.ROADMAP,
      draggable: true
    };
    let map = new (window as any).google.maps.Map(document.getElementById('map_canvas'),
      mapOptions);
    let marker = new (window as any).google.maps.Marker({
      position: { lat: parseFloat(lat), lng: parseFloat(lng) },
      map,
      // icon: {
      //   url: `${API_URL}${lead.status_id?.image}`,
      //   scaledSize: new (window as any).google.maps.Size(50, 50),
      // }
    });
    marker.setMap(map);
    setMarkers([marker]);
    setMapInstance(map);
  }
  const resetMarkers = () => {
    markers.map((marker: any) => {
      marker.setMap(null);
    })
  }
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let geometry: any = query.get('geometry');
    if (geometry) {
      geometry = geometry.split(',');
      (window as any).google.maps.event.addDomListener(window, 'load', initialize(geometry[0], geometry[1]));
    }
  }, [location]);
  useEffect(() => {
    return ()=>{
      resetMarkers();
    }
  }, [])
  return (
    <>
      <FlexboxGrid className="home">
        <FlexboxGrid className="header">
          <IconButton
            className="back-btn"
            icon={<FiArrowLeft style={{ fontSize: 18 }} />}
            circle
            size="xs"
            onClick={() => {
              history.goBack();
            }}
          />
          <IonText className="header_text">Map Page</IonText>
        </FlexboxGrid>
        <FlexboxGrid className="map">
          <div id="map_canvas"></div>
        </FlexboxGrid>
        {/* <ButtonToolbar className="home-floating-btns">
          <IconButton
            icon={<Icon icon="plus" />}
            size="lg"
            onClick={() => {
              history.push("/add-leads-3");
            }}
          />
        </ButtonToolbar> */}
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
});
const mapDispatchToProps = {
  fetchLeadsByStatus,
  routesSetFormData
};
export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
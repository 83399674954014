import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Popover } from "rsuite";
import { FiX } from "react-icons/fi";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// import default style
import "rsuite/dist/styles/rsuite-default.css"; // or 'rsuite/dist/styles/rsuite-default.css'

import "./App.css"
// Layouts
import RouteWithLayout from "./components/routeWithLayout/RouteWithLayout.jsx";
import MainView from "./layouts/mainView";
import MinimalView from "./layouts/minimalView";
import AdminView from "./layouts/adminView";

// pages
import {
  Login,
  ForgotPassword,
  Home,
  Routes,
  Profile,
  Leads,
  CreateRoute,
  AddLeads,
  AddLeads2,
  Filter,
  AddLeads3,
  EditLeads,
  EditProfile,
  ChnagePassword,
  AddRoute,
  BulkStatusUpdate,
  MapPage,
  DrawRoutes,
  BulkAssignSeller
} from "./pages";

import {
  OfficeResults,
  Sellers,
  LeadsAdmin,
  AddLeadsAdmin,
  EditLeadsAdmin,
  AddUsers,
  EditUsers,
  Users,
  Notification,
  Status,
  AddStatus,
  EditStatus,
  Upload,
  Upload2,
  Upload3,
  Upload4,
  AdminProfile
} from "./admin";

const App = () => {
  const [showIosPopup, setShowIosPopup] = useState(false);
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  // useEffect(() => {
  //   if (isIos() && !isInStandaloneMode()) {
  //     setShowIosPopup(true);
  //   } else {
  //     setShowIosPopup(false);
  //   }
  // }, [])
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Switch>
            <RouteWithLayout
              path="/"
              component={Login}
              exact={true}
              layout={MinimalView}
              access="public"
              restricted={true}
            />
            <RouteWithLayout
              path="/forgot-password"
              component={ForgotPassword}
              exact={true}
              layout={MinimalView}
              access="public"
              restricted={true}
            />
            <RouteWithLayout
              path="/home"
              component={Home}
              exact={true}
              layout={MainView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/routes"
              component={Routes}
              exact={true}
              layout={MainView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/profile"
              component={Profile}
              exact={true}
              layout={MainView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/leads"
              component={Leads}
              exact={true}
              layout={MainView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/map-page"
              component={MapPage}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/draw-routes"
              component={DrawRoutes}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/create-route"
              component={CreateRoute}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/add-leads-1"
              component={AddLeads}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/add-leads-2"
              component={AddLeads2}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/filter"
              component={Filter}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/bulk-status-update"
              component={BulkStatusUpdate}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/add-route/:id?"
              component={AddRoute}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/add-leads-3"
              component={AddLeads3}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/edit-leads/:id"
              component={EditLeads}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/edit-profile"
              component={EditProfile}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/change-password"
              component={ChnagePassword}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/bulk-assign-seller"
              component={BulkAssignSeller}
              exact={true}
              layout={MinimalView}
              access="private"
              scope="SELLER,MANAGER"
            />
            <RouteWithLayout
              path="/admin/office-results"
              component={OfficeResults}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/sellers"
              component={Sellers}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/leads"
              component={LeadsAdmin}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/add-leads"
              component={AddLeadsAdmin}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/edit-leads/:id"
              component={EditLeadsAdmin}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/add-users"
              component={AddUsers}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/edit-user/:id"
              component={EditUsers}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/view-users"
              component={Users}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/notification"
              component={Notification}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/status"
              component={Status}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/add-status"
              component={AddStatus}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/edit-status/:id"
              component={EditStatus}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN"
            />
            <RouteWithLayout
              path="/admin/upload"
              component={Upload}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/upload-mapping"
              component={Upload2}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/upload-review"
              component={Upload3}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
            <RouteWithLayout
              path="/admin/upload-map-owner"
              component={Upload4}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
             <RouteWithLayout
              path="/admin/profile"
              component={AdminProfile}
              exact={true}
              layout={AdminView}
              access="private"
              scope="ADMIN,MANAGER"
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
      {showIosPopup && (
        <div style={{ height: 80, position: 'relative', margin: "0px 10px", top: "76vh" }}>
          <Popover title="" visible style={{ width: "100%" }}>
            <FiX 
              style={{position: "absolute", top: 2, right: 10}} 
              size={12}
              onClick={()=>setShowIosPopup(false)}
            />
            <p>
              Install this webapp on your iPhone: tap  <img src="/assets/icon/safari_tray.png" style={{ height: 25, borderRadius: "50%" }} />  and then "Add to Home Screen"
            </p>
          </Popover>
        </div>
       )} 
    </IonApp>
  )
};

export default App;

import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Divider,
} from "rsuite";
import "./ForgotPassword.css";
import { useHistory } from "react-router-dom";

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <FlexboxGrid justify="center" className="logincontainer">
        <Form className="forgotCard">
          <h1 className="header_text_forgot">Forgot Password</h1>
          {/* <Divider /> */}
          <FormGroup>
            <ControlLabel className="inputLable">Email</ControlLabel>
            <FormControl name="email" type="email" className="input" />
          </FormGroup>
  
          <FormGroup>
            <Button appearance="primary" className="btn-login" color="blue" onClick={() => {history.push('/home')}} >
              Continue
            </Button>
            <Button className="btn-forgot" onClick={() => {history.push('/')}} >Back to login</Button>
          </FormGroup>
        </Form>
      </FlexboxGrid>
    </>
  );
};

export default ForgotPassword;

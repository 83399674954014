import { USERS_LOADING, USERS_SET_DATA, USERS_SET_PAGE, USERS_SET_DETAILS, USERS_SET_ALL_SELLERS } from "./constants";
const initialState = {
  loading: false,
  users: [],
  user: {},
  count: 0,
  activePage: 1,
  limit: 50,
  offset: 0,
  allSellers: []
};
export function userReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case USERS_SET_DATA:
      return {
        ...state,
        users: action.payload.users,
        count: action.payload.count,
      };
    case USERS_SET_PAGE:
      return {
        ...state,
        activePage: action.payload.activePage,
        offset: action.payload.offset,
      };
    case USERS_SET_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    case USERS_SET_ALL_SELLERS:
      return {
        ...state,
        allSellers: action.payload.users,
      };
    default:
      return state;
  }
}

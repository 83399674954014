import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import {
	FlexboxGrid,
	Form,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
	InputPicker,
	Modal,
	Alert
} from "rsuite";

//redux
import { connect } from "react-redux";
import { editNotification } from "../../stores/notifications/actions";
const EditNotificationModal: React.FC<{
	show: boolean;
	setShow: Function;
	editNotification: Function;
	notification:any;
}> = ({
	show,
	setShow,
	editNotification,
	notification
}) => {
		const data = [
			{
				label: "Day",
				value: "DAY"
			},
			{
				label: "Hour",
				value: "HOUR"
			},
		];
		const types = [
			{
				label: "SMS",
				value: "SMS"
			},
			{
				label: "Push Notification",
				value: "NOTIFICATION"
			},
		];
		const [state, setState] = useState<any>({
			name: "",
			interval: "",
			unit: "",
			type: "",
			message: ""
		})
		const [btnLoading, setBtnLoading] = useState<boolean>(false);
		const handleEditNotification = async () => {
			try {
				setBtnLoading(true);
				await editNotification({...state, id: notification._id});
				setShow(false);
				Alert.success("Notification created successfully");
			} catch (err) { 
				Alert.error("Something went wrong!");
			} finally{
				setBtnLoading(false);
			}
		}
		useEffect(()=>{
			if(notification._id){
				setState({
					...state,
					name: notification.name,
					interval: notification.interval,
					unit: notification.unit,
					type: notification.type,
					message: notification.message
				})
			}
		}, [notification])
		return (
			<Modal
				show={show}
				onHide={() => {
					setShow(!show);
				}}
			>
				<Form className="w-100" style={{ marginBottom: 20 }} onSubmit={handleEditNotification}>
					<Modal.Header>
						<Modal.Title>Create new notification</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FlexboxGrid
							className="status-wrapper"
							justify="center"
							alignment="center"
							style={{ paddingBottom: 20 }}
						>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Name</ControlLabel>
								<FormControl
									name="text"
									type="text"
									className="input"
									value={state.name}
									onChange={(value) => setState({ ...state, name: value })}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Interval</ControlLabel>
								<FormControl
									name="text"
									type="number"
									className="input"
									value={state.interval}
									onChange={(value) => setState({ ...state, interval: value })}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Unit</ControlLabel>
								<InputPicker
									data={data}
									onChange={(value) => setState({ ...state, unit: value })}
									value={state.unit}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Type</ControlLabel>
								<InputPicker
									data={types}
									onChange={(value) => setState({ ...state, type: value })}
									value={state.type}
									required
								/>
							</FormGroup>
							<FormGroup className="w-100">
								<ControlLabel className="inputLable">Message</ControlLabel>
								<FormControl
									name="text"
									type="text"
									className="input"
									onChange={(value) => setState({ ...state, message: value })}
									value={state.message}
									required
								/>
							</FormGroup>
						</FlexboxGrid>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setShow(!show);
							}}
							appearance="subtle"
						>
							Cancel
						</Button>
						<Button
							appearance="primary"
							type="submit"
							disabled={btnLoading}
						>
							{btnLoading ? <IonSpinner name="dots" color="#fff" /> : "Submit"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	};
const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = {
	editNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditNotificationModal);
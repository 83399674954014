import React, { useState, useEffect } from "react";
import { IonSpinner, IonText } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  Divider,
  IconButton,
  Icon,
  Checkbox,
  Alert,
} from "rsuite";
import { FiArrowLeft, FiMessageSquare, FiPhoneCall, FiGitPullRequest } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./EditProfile.css";

//redux
import { connect } from "react-redux";
import { editProfile, fetchProfile } from "../../stores/profile/actions";

const EditProfile: React.FC<{
  profile: any;
  editProfile: Function;
  fetchProfile: Function;
}> = ({profile, editProfile, fetchProfile}) => {
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    btnLoading: false
  })
  const handleEditProfile = async() =>{
    try{
      setState({...state, btnLoading: true});
      await editProfile({...state, id: profile._id});
      Alert.success("Profile updated successfully");
      fetchProfile(profile._id);
    }catch(err){
      Alert.error("Something went wrong");
    }finally{
      setState({...state, btnLoading: false});
    }
  }
  useEffect(()=>{
    if(profile?._id){
      setState({
        ...state,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName
      })
    }
  }, [profile])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Edit Profile</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <Form className="w-100" style={{ marginBottom: 20 }} onSubmit={handleEditProfile}>
          <FlexboxGrid className="AddLeadsInner">
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Email</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.email}
                disabled
              />
            </FormGroup>
            <FlexboxGrid justify="space-between" className="w-100">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">First Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.firstName}
                    onChange={value => setState({ ...state, firstName: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Last Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.lastName}
                    onChange={value => setState({ ...state, lastName: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button 
              color="blue" 
              className="btn" 
              type="submit" 
              disabled={state.btnLoading}
            >
              {state.btnLoading ? <IonSpinner name="dots" color="#fff"/> : "Save"}
            </Button>
          </FlexboxGrid>
        </Form>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile
});
const mapDispatchToProps = {
  editProfile,
  fetchProfile
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
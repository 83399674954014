import React, {useState, useEffect} from "react";
import { AutoComplete } from 'rsuite';
import axios from "axios";

//redux
import { connect } from "react-redux";
import { fetchLocationPredictions, fetchLocationDetails } from "../../stores/leads/actions";

const AutoCompleteComponent: React.FC<{
    className: string;
    onSelect: Function;
    fetchLocationPredictions: Function;
    predictions: any;
    fetchLocationDetails: Function;
    value: string;
    onChange: Function;
    placeholder: string;
}> = ({ className, onSelect, fetchLocationPredictions, predictions, fetchLocationDetails, value, onChange, placeholder }) => {
    const [data, setData] = useState<any[]>([]);
    useEffect(()=>{
        if(predictions?.length){
            setData([...predictions.map((item:any) => item.description)]);
        }
    }, [predictions])
    useEffect(()=>{
        fetchLocationPredictions(value);
    }, [value])
    useEffect(()=>{
        axios.get("https://cors-handle.herokuapp.com/")
      }, [])
    return (
        <AutoComplete
            data={data}
            className={className}
            onSelect={async(item: any) => {
                const resp = await fetchLocationDetails(item.value);
                onSelect({...resp, street_address: item.value})
            }}
            onChange={(value)=>onChange(value)}
            autoComplete="rutjfkde"
            value={value}
            placeholder={placeholder}
        />
    )
}
const mapStateToProps = (state: any) => ({
    predictions: state.leads.locationPredictions
});
const mapDispatchToProps = {
    fetchLocationPredictions,
    fetchLocationDetails
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteComponent);
import React, { useEffect, useState } from "react";
import { IonText, IonSpinner, useIonAlert } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  Divider,
  IconButton,
  Icon,
  Alert
} from "rsuite";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FiArrowLeft, FiGitPullRequest } from "react-icons/fi";
import { FaTruckMoving, FaTrashAlt } from "react-icons/fa";
import { IoLocateOutline, IoFlagSharp, IoMap } from "react-icons/io5";
import AutoCompleteComponent from "../../components/common/AutoComplete";
import "./AddRoute.css";
import moment from "moment";
import { API_URL } from "../../config/";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
//redux
import { connect } from "react-redux";
import { routesSetFormData, createRoute, fetchRouteDetails, updateRoute } from "../../stores/routes/actions";
import { fetchLocationFromLongLat } from "../../stores/leads/actions";

const AddRoute: React.FC<{
  routesSetFormData: Function;
  formData: any;
  createRoute: Function;
  profile: any;
  fetchRouteDetails: Function;
  route: any;
  updateRoute: Function;
  fetchLocationFromLongLat: Function
}> = ({ routesSetFormData, formData, createRoute, profile, fetchRouteDetails, route, updateRoute, fetchLocationFromLongLat }) => {
  const [present] = useIonAlert();
  const history = useHistory();
  const match = useRouteMatch<any>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [routeId, setRouteId] = useState<any>(null);
  const handleCreateRoute = async () => {
    try {
      setBtnLoading(true);
      await createRoute({
        name: formData.name,
        starting_address: formData.starting_address,
        starting_address_long: formData.starting_address_long,
        starting_address_lat: formData.starting_address_lat,
        ending_address: formData.ending_address,
        ending_address_long: formData.ending_address_long,
        ending_address_lat: formData.ending_address_lat,
        leads: formData.leads.map((ld: any) => ld._id),
        user_id: profile._id
      });
      Alert.success("Route created successfully")
      history.push("/Routes");
    } catch (err) {
      Alert.error("Something went wrong!")
    } finally {
      setBtnLoading(false);
    }
  }
  const handleUpdateRoute = async () => {
    try {
      setBtnLoading(true);
      await updateRoute({
        name: formData.name,
        starting_address: formData.starting_address,
        starting_address_long: formData.starting_address_long,
        starting_address_lat: formData.starting_address_lat,
        ending_address: formData.ending_address,
        ending_address_long: formData.ending_address_long,
        ending_address_lat: formData.ending_address_lat,
        leads: formData.leads.map((ld: any) => ld._id),
        id: route._id,
        user_id: profile._id
      });
      Alert.success("Route updated successfully")
      history.push("/Routes");
    } catch (err) {
      Alert.error("Something went wrong!")
    } finally {
      setBtnLoading(false);
    }
  }
  const fetchCurrentLocation = async (key: string) => {
    await navigator.geolocation.getCurrentPosition(
      async position => {
        const resp = await fetchLocationFromLongLat(`${position.coords.latitude}, ${position.coords.longitude}`);
        routesSetFormData({
          ...formData,
          [key]: resp?.location,
          [`${key}_long`]: position.coords.longitude,
          [`${key}_lat`]: position.coords.latitude,
        })
      },
      err => console.log(err));
  }
  const handleRemoveLead = async(id:string) =>{
    let newLeads = formData.leads.filter((lead:any)=>lead._id !== id);
    routesSetFormData({...formData, leads: newLeads});
  }
  const handleClick = () =>{
    let lat = "",lng = "";
    if(formData.starting_address_lat && formData.starting_address_long){
      lat = formData.starting_address_lat;
      lng = formData.starting_address_long;
    }else if (formData.ending_address_lat && formData.ending_address_long){
      lat = formData.ending_address_lat;
      lng = formData.ending_address_long;
    }else if(formData.leads?.length > 0){
      lat = formData.leads[0]?.latitude;
      lng = formData.leads[0]?.longitude;
    }
    history.push(`/draw-routes?routeId=${routeId}&lat=${lat}&lng=${lng}`)
  }
  useEffect(() => {
    if (match?.params?.id) {
      fetchRouteDetails(match?.params?.id);
      setRouteId(match?.params?.id)
    }
  }, [])
  return (
    <>
      <FlexboxGrid className="AddRoute">
        <FlexboxGrid className="header">
          <IconButton
            className="back-btn"
            icon={<FiArrowLeft style={{ fontSize: 18 }} />}
            circle
            size="xs"
            onClick={() => {
              history.goBack();
            }}
          />
          <IonText className="header_text">{routeId ? moment(route.created_on).format("MMMM DD, YYYY") : moment().format("MMMM DD, YYYY")}</IonText>
          <IconButton
            className=""
            icon={<FiGitPullRequest style={{ fontSize: 18 }} />}
            circle
            size="md"
            color="blue"
            style={{ marginLeft: "auto" }}
            onClick={() => handleClick()}
          />
        </FlexboxGrid>
        <FlexboxGrid className="CreateRoute-inner">
          <Form className="w-100">
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Route Name</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={formData.name}
                onChange={value => routesSetFormData({ ...formData, name: value })}
              />
            </FormGroup>
            <FlexboxGrid className="inputFlex">
              <IoFlagSharp
                style={{ fontSize: 20, marginRight: 10, color: "#0005" }}
              />
              {/* <FormControl
                name="text"
                type="text"
                className="inputFlex-input"
                placeholder="Starting address"
                value={formData.starting_address}
                onChange={value => routesSetFormData({ ...formData, starting_address: value })}
              /> */}
              <AutoCompleteComponent
                className="inputFlex-input2"
                onSelect={(value: any) => {
                  routesSetFormData({
                    ...formData,
                    starting_address: value?.street_address,
                    starting_address_long: value?.geometry?.lng,
                    starting_address_lat: value?.geometry?.lat
                  })
                }}
                value={formData.starting_address}
                onChange={(value: any) => routesSetFormData({ ...formData, starting_address: value })}
                placeholder="Starting address"
              />
              <ButtonToolbar>
                <IconButton
                  icon={<IoLocateOutline style={{ fontSize: 20 }} />}
                  circle
                  size="sm"
                  onClick={() => fetchCurrentLocation("starting_address")}
                />
              </ButtonToolbar>
            </FlexboxGrid>
            <FlexboxGrid className="inputFlex">
              <IoFlagSharp
                style={{ fontSize: 20, marginRight: 10, color: "#0005" }}
              />
              {/* <FormControl
                name="text"
                type="text"
                className="inputFlex-input"
                placeholder="Ending address"
                value={formData.ending_address}
                onChange={value => routesSetFormData({ ...formData, ending_address: value })}
              /> */}
              <AutoCompleteComponent
                className="inputFlex-input2"
                onSelect={(value: any) => {
                  routesSetFormData({
                    ...formData,
                    ending_address: value?.street_address,
                    ending_address_long: value?.geometry?.lng,
                    ending_address_lat: value?.geometry?.lat
                  })
                }}
                value={formData.ending_address}
                onChange={(value: any) => routesSetFormData({ ...formData, ending_address: value })}
                placeholder="Ending address"
              />
              <ButtonToolbar>
                <IconButton
                  icon={<IoLocateOutline style={{ fontSize: 20 }} />}
                  circle
                  size="sm"
                  onClick={() => fetchCurrentLocation("ending_address")}
                />
              </ButtonToolbar>
            </FlexboxGrid>
            <FlexboxGrid justify="end">
              <Button
                color="blue"
                className="btn"
                onClick={() => {
                  history.push("/add-leads-1");
                }}
              >
                <Icon icon="plus" /> Add Leads
              </Button>
            </FlexboxGrid>
          </Form>
          <div className="route-leads">
            <SwipeableList>
              {formData.leads?.map((item: any, i: number) => (
                <SwipeableListItem
                  key={i}
                  swipeLeft={{
                    content: (
                      <FlexboxGrid className="routes-item-delete">
                        {" "}
                        <IonText className="routes-item-delete-text">
                          {" "}
                          Delete Lead{" "}
                        </IonText>{" "}
                      </FlexboxGrid>
                    ),
                    action: () =>
                      present({
                        cssClass: "my-css",
                        header: "Alert",
                        message: "Are you sure want to remove this Lead from this Route?",
                        buttons: [
                          "Cancel",
                          { text: "Ok", handler: (d) => handleRemoveLead(item._id) },
                        ],
                        onDidDismiss: (e) => console.log("did dismiss"),
                      }),
                  }}
                >
                  <FlexboxGrid className="flex-row" key={i}>
                    <IonText className="flexOrder">{i + 1}.</IonText>
                    <FlexboxGrid justify="center" className="tag-btn-wrapper">
                      <img
                        src={`${API_URL}${item?.status_id?.image}`}
                        style={{
                          width: 35,
                          height: 35,
                          marginRight: 5,
                          marginLeft: 5,
                          borderRadius: "50%",
                        }}
                      />
                    </FlexboxGrid>
                    <FlexboxGrid
                      className="route_column"
                      onClick={() => {
                        history.push(`/edit-leads/${item._id}`);
                      }}
                    >
                      <IonText className="textFlex">{item.first_name} {item.last_name}</IonText>
                      <IonText className="textFlex">
                        {item.street_address?.length > 0 ? item.street_address : ""}
                        {/* {item.apt?.length > 0 ? `, ${item.apt}` : ""}
                  {item.city?.length > 0 ? `, ${item.city}` : ""}
                  {item.state?.length > 0 ? `, ${item.state}` : ""}
                  {item.zip?.length > 0 ? `, ${item.zip}` : ""}
                  {item.country?.length > 0 ? `, ${item.country}` : ""} */}
                      </IonText>
                    </FlexboxGrid>
                    <IoMap
                      style={{ fontSize: 20, marginRight: 10, color: "#0005" }}
                      onClick={() => {
                        window.location.href = `http://maps.google.com/maps?q=loc:${item.latitude},${item.longitude}(${item.street_address})`
                      }}
                    />
                  </FlexboxGrid>
                </SwipeableListItem>
              ))}
            </SwipeableList>
          </div>
          <FlexboxGrid justify="center" style={{ margin: "15px 0px" }}>
            <Button
              color="blue"
              className="btn"
              onClick={() => {
                if (routeId) {
                  handleUpdateRoute()
                } else {
                  handleCreateRoute()
                }
              }}
              disabled={(formData.leads?.length === 0 || formData.name === "" || formData.starting_address === "" || formData.ending_address === "")}
            >
              {btnLoading ? <IonSpinner color="#fff" name="dots" /> : "Route It!"}
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  formData: state.routes.formData,
  profile: state.profile.profile,
  route: state.routes.route,
});
const mapDispatchToProps = {
  routesSetFormData,
  createRoute,
  fetchRouteDetails,
  updateRoute,
  fetchLocationFromLongLat
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRoute);
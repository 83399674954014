export const LEADS_LOADING = "LEADS_LOADING";
export const LEADS_SET_DATA = "LEADS_SET_DATA";
export const LEADS_SET_DETAILS = "LEADS_SET_DETAILS";
export const LEADS_REMOVE_LEAD = "LEADS_REMOVE_LEAD";
export const LEADS_SET_FILTERED_LEADS = "LEADS_SET_FILTERED_LEADS";
export const LEADS_SET_APPOINTMENTS = "LEADS_SET_APPOINTMENTS";
export const LEADS_SET_LOCATION_PREDICTION = 'LEADS_SET_LOCATION_PREDICTION';
export const LEADS_SET_MAP_LEADS = "LEADS_SET_MAP_LEADS";
export const LEADS_SET_MAP_FILTERS = "LEADS_SET_MAP_FILTERS";
export const LEADS_SET_PAGE   = "LEADS_SET_PAGE";
export const LEADS_SET_ALL_DATA = "LEADS_SET_ALL_DATA";
import React, { useState, useEffect } from "react";
import { IonText, useIonAlert } from "@ionic/react";
import {
	FlexboxGrid,
	Button,
	Table,
	Pagination,
	IconButton,
	Icon,
	Divider,
	InputPicker
} from "rsuite";
import { FiRefreshCw, FiSettings, FiEdit2, FiTrash, FiX } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";
import "./Users.css";

//redux
import { connect } from "react-redux";
import { fetchAllSellers, deleteUserWithAllRecord, deleteUserWithReassignLeads } from "../../stores/users/actions";

const DeleteUserPopup: React.FC<{
	open: Boolean;
	close: Function;
	count: Number;
	fetchAllSellers: Function;
	allSellers: any[];
	deleteUserWithAllRecord: Function;
	seller: any;
	deleteUserWithReassignLeads: Function
}> = ({ open, close, count, fetchAllSellers, allSellers, deleteUserWithAllRecord, seller, deleteUserWithReassignLeads }) => {
	const [present] = useIonAlert();
	const history = useHistory();
	const [sellerMod, setSellerMod] = useState<any[]>([]);
	const [selectedSeller, setSelectedSeller] = useState<string>("");
	const handleDeleteSeller = () =>{
		present({
			cssClass: "my-css",
			header: "Delete Seller",
			message: "Are you sure you want to delete this seller with all the leads and routes? This action is irreversible",
			buttons: [
				"Cancel",
				{
					text: "Ok", handler: async(d) => {
						await deleteUserWithAllRecord(seller.id);
						close();
					}
				},
			],
			onDidDismiss: (e) => console.log("did dismiss"),
		})
	}
	const handleReassignAndDeleteSeller = () =>{
		present({
			cssClass: "my-css",
			header: "Reassign & Delete Seller",
			message: "Are you sure you want to reassign leads and delete this seller? This action is irreversible",
			buttons: [
				"Cancel",
				{
					text: "Ok", handler: async(d) => {
						await deleteUserWithReassignLeads(seller.id, selectedSeller);
						close();
					}
				},
			],
			onDidDismiss: (e) => console.log("did dismiss"),
		})
	}
	useEffect(() => {
		let sellersTemp: any = [];
		allSellers.map((seller: any) => {
			sellersTemp.push({
				value: seller._id,
				label: `${seller.firstName} ${seller.lastName}`
			})
		})
		setSellerMod(sellersTemp)
	}, [allSellers])
	useEffect(() => {
		fetchAllSellers();
	}, [])
	return (
		<FlexboxGrid className={`home-floating-modal ${open && "open"}`}>
			<FlexboxGrid className="home-floating-modal-body">
				<FlexboxGrid
					className="home-floating-modal-row"
					justify="space-between"
					alignment="center"
				>
					<IonText>This user has {count} leads.</IonText>
					<IconButton
						className="close-btn"
						icon={<FiX style={{ fontSize: 16, color: "#f00" }} />}
						circle
						size="xs"
						onClick={() => close()}
					/>
				</FlexboxGrid>
				<FlexboxGrid
					className="home-floating-modal-row"
					justify="start"
					alignment="center"
					style={{ padding: 0 }}
				>
					<select className="select-box" onChange={(e)=>setSelectedSeller(e.target.value)} value={selectedSeller}>
						<option value="" disabled>Select another seller</option>
						{sellerMod.map((seller:any, i:number)=>(
							<option value={seller.value}>{seller.label}</option>
						))}
					</select>
				</FlexboxGrid>
				<FlexboxGrid
					className="home-floating-modal-row"
					justify="center"
					alignment="center"
					style={{ paddingTop: 0, marginTop: 30 }}
				>
					<Button
						color="blue"
						className="btn-modal"
						onClick={()=>handleReassignAndDeleteSeller()}
					>
						Reassign and delete
					</Button>
					<Button
						color="red"
						className="btn-modal"
						onClick={()=>handleDeleteSeller()}
					>
						Delete all records
					</Button>
				</FlexboxGrid>
			</FlexboxGrid>
		</FlexboxGrid>
	);
};
const mapStateToProps = (state: any) => ({
	allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
	fetchAllSellers,
	deleteUserWithAllRecord,
	deleteUserWithReassignLeads
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserPopup);
import { STATS_LOADING, STATS_SET_DATA, STATS_SET_PAGE } from "./constants";
const initialState = {
  loading: false,
  stats: [],
  stat: {},
  count: 0,
  activePage: 1,
  limit: 50,
  offset: 0,
};
export function statsReducer(state = initialState, action) {
  switch (action.type) {
    case STATS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case STATS_SET_DATA:
      return {
        ...state,
        stats: action.payload.stats,
        count: action.payload.count,
      };
    case STATS_SET_PAGE:
      return {
        ...state,
        activePage: action.payload.activePage,
        offset: action.payload.offset,
      };
    default:
      return state;
  }
}

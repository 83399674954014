import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import './minimal.css';

const MinimalView: React.FC<{ children: any }> = ({ children }) => {
  return (
    <IonPage>
      <IonContent className="minimal_content" >{children}</IonContent>
    </IonPage>
  );
};

export default MinimalView;

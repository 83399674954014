import React, { useState } from "react";
import { IonText } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  Divider,
  IconButton,
  Icon,
} from "rsuite";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import {
  FaTrashAlt,
} from "react-icons/fa";
import { IoLocateOutline, IoFlagSharp } from "react-icons/io5";
import "./CreateRoute.css";

const CreateRoute: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <FlexboxGrid className="CreateRoute">
        <FlexboxGrid className="header">
          <IconButton
            className="back-btn"
            icon={<FiArrowLeft style={{ fontSize: 18 }} />}
            circle
            size="xs"
            onClick={() => {
              history.goBack();
            }}
          />
          <IonText className="header_text">June 17, 2021</IonText>
        </FlexboxGrid>
        <FlexboxGrid className="CreateRoute-inner">
          <Form className="w-100">
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Route Name</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value="June 17, 2021"
              />
            </FormGroup>
            <FlexboxGrid className="inputFlex">
              <IoFlagSharp style={{ fontSize: 20, marginRight: 10 }} />
              <FormControl
                name="text"
                type="text"
                className="inputFlex-input"
                placeholder="Starting address"
              />
              <ButtonToolbar>
                <IconButton
                  icon={<IoLocateOutline style={{ fontSize: 20 }} />}
                  circle
                  size="sm"
                />
              </ButtonToolbar>
            </FlexboxGrid>
            <FlexboxGrid className="inputFlex">
              <IoFlagSharp style={{ fontSize: 20, marginRight: 10 }} />
              <FormControl
                name="text"
                type="text"
                className="inputFlex-input"
                placeholder="Ending address"
              />
              <ButtonToolbar>
                <IconButton
                  icon={<IoLocateOutline style={{ fontSize: 20 }} />}
                  circle
                  size="sm"
                />
              </ButtonToolbar>
            </FlexboxGrid>
            <FlexboxGrid justify="end">
              <Button
                color="blue"
                className="btn"
                onClick={() => {
                  history.push("/add-leads-1");
                }}
              >
                <Icon icon="plus" /> Add Leads
              </Button>
            </FlexboxGrid>
          </Form>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => (
            <FlexboxGrid className="flex-row" key={i}>
              <IonText className="flexOrder">1.</IonText>
              <FlexboxGrid justify="center" className="tag-btn-wrapper">
                <IconButton
                  className="tag-btn ni"
                  icon={<FaTrashAlt style={{ fontSize: 14 }} />}
                  circle
                  size="sm"
                />
              </FlexboxGrid>
              <FlexboxGrid className="route_column">
                <IonText className="textFlex">Medrano Chicas</IonText>
                <IonText className="textFlex">
                  14 Huntley Ct, Sterling, VA 20165
                </IonText>
              </FlexboxGrid>
            </FlexboxGrid>
          ))}
          <FlexboxGrid justify="center" style={{ margin: "15px 0px" }}>
            <Button
              color="blue"
              className="btn"
              onClick={() => {
                history.push('/Routes');
              }}
            >
              Route It!
            </Button>
          </FlexboxGrid>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};

export default CreateRoute;

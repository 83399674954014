import React, { useState } from "react";
import "./Sidebar.css";
import { IonText } from "@ionic/react";
import {
  Drawer,
  ButtonToolbar,
  Button,
  FlexboxGrid,
  Radio,
  IconButton,
  Icon,
  Dropdown,
  Sidenav,
  Nav,
} from "rsuite";
import { FiMenu, FiActivity, FiMap } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { authLogout } from "../../../stores/auth/actions";

const Sidebar: React.FC<{
  title: string;
  authLogout: Function;
  profile: any
}> = ({ title, authLogout, profile }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
  };
  const toggleDrawer = () => {
    setShow(true);
  };
  return (
    <>
      <FlexboxGrid className="admin-header">
        <Button
          onClick={toggleDrawer}
          color="blue"
          appearance="ghost"
          className="MenuTooglleButton"
        >
          <FiMenu className="admin-header-menuIcon" />
        </Button>
        <IonText className="admin-header-text">{title}</IonText>
      </FlexboxGrid>
      <Drawer show={show} size={"xs"} placement={"left"} onHide={close}>
        <Drawer.Header>
          <Drawer.Title>Admin Panel</Drawer.Title>
        </Drawer.Header>
        {/* <Drawer.Body> */}
        <Sidenav
          defaultOpenKeys={["1", "2", "3", "4"]}
          activeKey="1"
          style={{ marginTop: 15, background: "#fff" }}
        >
          <Sidenav.Body>
            <Nav>
              <Dropdown eventKey="1" title="Stats" icon={<Icon icon="bar-chart" />}>
                {/* <Link to="/admin/office-results">
                  <Dropdown.Item
                    eventKey="1-1"
                    onPress={() => {
                      history.push("/admin/office-results");
                    }}
                  >
                    Office
                  </Dropdown.Item>
                </Link> */}
                <Link to="/admin/sellers">
                  <Dropdown.Item
                    eventKey="1-2"
                    onPress={() => {
                      history.push("/admin/sellers");
                    }}
                  >
                    Sellers
                  </Dropdown.Item>
                </Link>
              </Dropdown>
              <Dropdown eventKey="2" title="Leads" icon={<Icon icon="map" />}>
                <Link to="/admin/leads">
                  <Dropdown.Item
                    eventKey="2-1"
                    onPress={() => {
                      history.push("/admin/sellers");
                    }}
                  >
                    View Leads
                  </Dropdown.Item>
                </Link>
                <Link to="/admin/add-leads">
                  <Dropdown.Item eventKey="2-2">Add Leads </Dropdown.Item>
                </Link>
                <Link to="/admin/upload">
                  <Dropdown.Item eventKey="2-3">Upload CSV</Dropdown.Item>
                </Link>
              </Dropdown>
              {profile.role === "ADMIN" && (
                <Dropdown eventKey="3" title="Users" icon={<Icon icon="user" />}>
                  <Link to="/admin/add-users">
                    <Dropdown.Item eventKey="3-1">Add User</Dropdown.Item>
                  </Link>
                  <Link to="/admin/view-users">
                    <Dropdown.Item eventKey="3-2">View User</Dropdown.Item>
                  </Link>
                </Dropdown>
              )}
              <Dropdown
                eventKey="4"
                title="Settings"
                icon={<Icon icon="gear-circle" />}
              >
                <Link to="/admin/profile">
                  <Dropdown.Item eventKey="4-1">Profile</Dropdown.Item>
                </Link>
                {profile.role === "ADMIN" && (
                  <>
                    <Link to="/admin/notification">
                      <Dropdown.Item eventKey="4-1">Notifications</Dropdown.Item>
                    </Link>
                    <Link to="/admin/status">
                      <Dropdown.Item eventKey="4-2">Statuses</Dropdown.Item>
                    </Link>
                  </>
                )}
                <Link to="/profile">
                  <Dropdown.Item eventKey="4-2">Go to App</Dropdown.Item>
                </Link>
                <a onClick={() => authLogout()}>
                  <Dropdown.Item eventKey="4-2">Logout</Dropdown.Item>
                </a>
              </Dropdown>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        {/* </Drawer.Body> */}
      </Drawer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.auth.profile,
});
const mapDispatchToProps = {
  authLogout,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
import React, { useEffect, useState, useRef } from "react";
import { IonText } from "@ionic/react";
import {
  FlexboxGrid,
  ButtonToolbar,
  IconButton,
  Icon,
} from "rsuite";
import { useHistory, useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { API_URL } from "../../config";
//redux
import { connect } from "react-redux";
import { fetchRouteDetails } from "../../stores/routes/actions";

const DrawRoutes: React.FC<{
  fetchRouteDetails: Function;
  route: any
}> = ({ fetchRouteDetails, route }) => {
  const markerRef = useRef<any>(null);
  const location = useLocation();
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const history = useHistory();
  const fetchCurrentLocation = async () => {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(
        async position => {
          let lat = position.coords.latitude,
            lng = position.coords.longitude;
          resolve({
            lat,
            lng
          })
        },
        err => reject(err));
    })
  }
  const addYourLocationButton = (map: any) => {
    var controlDiv = document.createElement('div') as any;
    var firstChild = document.createElement('button');
    firstChild.style.backgroundColor = '#fff';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '40px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0';
    firstChild.title = 'Your Location';
    controlDiv.appendChild(firstChild);

    var secondChild = document.createElement('img') as any;
    secondChild.src = "/assets/icon/my_location.png";
    secondChild.style.height = '30px';
    firstChild.appendChild(secondChild);

    (window as any).google.maps.event.addListener(map, 'center_changed', function () {
      secondChild.style['background-position'] = '0 0';
    });

    firstChild.addEventListener('click', function () {
      let position = JSON.parse(localStorage.getItem("CURRENT_POSTION") as any);
      if (position) {
          var latlng = new (window as any).google.maps.LatLng(position.lat, position.lng);
          map.panTo(latlng);
      } 
    });

    controlDiv.index = 1;
    map.controls[(window as any).google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
  }
  async function initialize(lat:any, lng:any) {
    let center: any = await fetchCurrentLocation();
    localStorage.setItem("CURRENT_POSTION", JSON.stringify({lat:center.lat,lng:center.lng}))
    let latTemp: any = center.lat;
    let lngTemp: any = center.lng;
    if(!isNaN(lat) && !isNaN(lng)){
      latTemp = lat;
      lngTemp = lng;
    }
    var mapOptions = {
      zoom: 14,
      center: new (window as any).google.maps.LatLng(parseFloat(latTemp), parseFloat(lngTemp)),
      mapTypeId: (window as any).google.maps.MapTypeId.ROADMAP,
      draggable: true,
      streetViewControl: false,
    };
    let map = new (window as any).google.maps.Map(document.getElementById('map_canvas'),
      mapOptions);
    setMapInstance(map);
    addYourLocationButton(map)
  }
  const resetMarkers = () => {
    markers.map((marker: any) => {
      marker.setMap(null);
    })
  }
  const handleFetchRouteDetails = async (routeId: string) => {
    await fetchRouteDetails(routeId);
  }
  const drawPolygon = () =>{
    if(route.leads.length <= 1){
      route.leads.map((lead:any, i:number)=>{
        let marker = new (window as any).google.maps.Marker({
          lead,
          position: { lat: parseFloat(lead.latitude), lng: parseFloat(lead.longitude) },
          mapInstance,
          icon: {
            url: `${API_URL}${lead.status_id?.image}`,
            scaledSize: new (window as any).google.maps.Size(30, 30),
          },
          label: {color: '#000', fontSize: '14px', fontWeight: '600',text: (i+1).toString()},
        });
        (window as any).google.maps.event.addDomListener(marker, 'click', function() {
          history.push(`/edit-leads/${lead._id}`)
        });
        marker.setMap(mapInstance)
      })
      return
    }
    var nextAddress = 0;
    var addresses:any = [];
    for(let i=0; i< route.leads.length; i++){
      if(route.leads[i] && route.leads[i+1]){
        addresses.push([route.leads[i].latitude, route.leads[i].longitude])
        addresses.push([route.leads[i+1].latitude, route.leads[i+1].longitude])
      }
    }
    var directionsDisplay:any;
    var directionsService:any = new (window as any).google.maps.DirectionsService();
    var bounds:any;
    // directionsDisplay.setMap(mapInstance);
    bounds = new (window as any).google.maps.LatLngBounds();
    var delayFactor = 0;

    function calcRoute(start:any, end:any, next:Function) {
      var request = {
        origin: start,
        destination: end,
        travelMode: (window as any).google.maps.TravelMode.DRIVING
      };
      directionsService.route(request,
        function(result:any, status:any) {
          if (status == 'OK') {
    
            directionsDisplay = new (window as any).google.maps.DirectionsRenderer({
              suppressBicyclingLayer: true,
              suppressMarkers: true,
              preserveViewport: true // don't zoom to fit the route
            });
            directionsDisplay.setMap(mapInstance);
            directionsDisplay.setDirections(result);
            // combine the bounds of the responses
            bounds.union(result.routes[0].bounds);
            // zoom and center the map to show all the routes
            if(bounds){
              mapInstance.fitBounds(bounds);
            }
          }
          // ====== Decode the error status ======
          else {
            console.log("status=" + status + " (start=" + start + ", end=" + end + ")");
            // === if we were sending the requests to fast, try this one again and increase the delay
            if (status == (window as any).google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
              nextAddress--;
              delayFactor++;
              setTimeout(function () {
                calcRoute(start, end, next);
              }, delayFactor * 1000);
            } else {
              var reason = "Code " + status;
              var msg = 'start="' + start + ' end="' + end + '"" error=' + reason + '(delayFactor=' + delayFactor + 'ms)<br>';
              console.log(msg);
            }
          }
        });
    }
    function theNext() {
      for(let i=0; i<addresses.length; i++){
        // setTimeout(()=>{
          let latStart = addresses[i]?.[0];
          let lngStart = addresses[i]?.[1];
          let latEnd = addresses[i+1]?.[0];
          let lngEnd = addresses[i+1]?.[1];
          if(!isNaN(latStart) && !isNaN(lngStart) && !isNaN(latEnd) && !isNaN(lngEnd)){
            var start = new (window as any).google.maps.LatLng(latStart, lngStart);
            var end = new (window as any).google.maps.LatLng(latEnd, lngEnd);
            calcRoute(start, end, theNext)
          }
        // }, delayFactor)
      }
      // We're done. Show map bounds
      mapInstance.fitBounds(bounds);
    }
    theNext();
    let path:any[] = [];
    route.leads.map((lead:any, i:number)=>{
      path.push({ lat: parseFloat(lead.latitude), lng: parseFloat(lead.longitude) })
      let marker = new (window as any).google.maps.Marker({
        lead,
        position: { lat: parseFloat(lead.latitude), lng: parseFloat(lead.longitude) },
        mapInstance,
        icon: {
          url: `${API_URL}${lead.status_id?.image}`,
          scaledSize: new (window as any).google.maps.Size(30, 30),
        },
        label: {color: '#000', fontSize: '14px', fontWeight: '600',text: (i+1).toString()},
      });
      (window as any).google.maps.event.addDomListener(marker, 'click', function() {
        history.push(`/edit-leads/${lead._id}`)
      });
      marker.setMap(mapInstance)
    })
    // const poly = new (window as any).google.maps.Polygon({
    //   map: mapInstance,
    //   path: path,
    //   strokeColor: "#f00",
    // });
  }
  const updateLocation = function(pos:any) {
    var coordinates = pos.coords;
    var position = {
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    };
    localStorage.setItem("CURRENT_POSTION", JSON.stringify({lat:coordinates.latitude,lng:coordinates.longitude}))
    if (markerRef.current === null) {
      markerRef.current = new (window as any).google.maps.Marker({ 
        map: mapInstance,
        position: position,
        icon: {
          path: (window as any).google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillOpacity: 1,
          strokeWeight: 2,
          fillColor: '#5384ED',
          strokeColor: '#ffffff',
        }
      });
      // mapInstance.setCenter(position);
    }else {
      if(markerRef.current.setPosition){
        markerRef.current.setPosition(position);
      }
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let routeId: any = query.get('routeId');
    let lat: any = query.get('lat');
    let lng: any = query.get('lng');
    if (routeId) {
      (window as any).google.maps.event.addDomListener(window, 'load', initialize(lat, lng));
      handleFetchRouteDetails(routeId);
    }
  }, [location]);
  
  useEffect(() => {
    let watchId:any = null;
    if (Array.isArray(route?.leads) && mapInstance) {
      drawPolygon();
      var geolocation_options = {
        enableHighAccuracy: false,
        timeout: 8000,
        maximumAge: 3000
      };
      watchId = navigator.geolocation.watchPosition(updateLocation, ()=>{}, geolocation_options);
    }
    return ()=>{
      navigator.geolocation.clearWatch(watchId);
    }
  }, [route, mapInstance])
  useEffect(() => {
    return () => {
      resetMarkers();
    }
  }, [])
  return (
    <>
      <FlexboxGrid className="home">
        <FlexboxGrid className="header">
          <IconButton
            className="back-btn"
            icon={<FiArrowLeft style={{ fontSize: 18 }} />}
            circle
            size="xs"
            onClick={() => {
              history.goBack();
            }}
          />
          <IonText className="header_text">Map Page</IonText>
        </FlexboxGrid>
        <FlexboxGrid className="map">
          <div id="map_canvas"></div>
        </FlexboxGrid>
        {/* <ButtonToolbar className="home-floating-btns">
          <IconButton
            icon={<Icon icon="plus" />}
            size="lg"
            onClick={() => {
              history.push("/add-leads-3");
            }}
          />
        </ButtonToolbar> */}
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  route: state.routes.route,
});
const mapDispatchToProps = {
  fetchRouteDetails
};
export default connect(mapStateToProps, mapDispatchToProps)(DrawRoutes);
import { FC, useEffect, useState } from "react";
import {
	Modal,
	Button,
	FlexboxGrid,
	InputPicker,
	DatePicker,
	CheckPicker
} from 'rsuite';
import { IonText } from "@ionic/react";
import { API_URL } from "../../config/";
import moment from "moment";
//redux
import { connect } from "react-redux";
import {fetchAllSellers} from "../../stores/users/actions";

const FilterLeadModal: FC<{
	open: boolean;
	statuses: any[];
	handleClose: () => void;
	onSubmit: (filters: any) => void;
	fetchAllSellers: Function;
	profile:any;
	allSellers: any[];
}> = ({
	statuses,
	open,
	handleClose,
	onSubmit,
	fetchAllSellers,
	profile,
	allSellers
}) => {
		const [selectedStatuses, setSelectedStatuses] = useState<any[]>([]);
		const [selectedOwner, setSelectedOwner] = useState<string[]>([]);
		const [isSelectAll, setSelectAll] = useState<boolean>(false)
		const [dateFilters, setDateFilters] = useState({
			selectedDateFilter: "created_on",
			selectedQuickDate: "all_time",
			fromDate: new Date(),
			toDate: new Date()
		})
		const handleSelectAll = () => {
			if (isSelectAll) {
				setSelectAll(false)
				setSelectedStatuses([]);
			} else {
				setSelectAll(true)
				setSelectedStatuses(statuses);
			}
		}
		const handleSelectRow = (item: any, checked: boolean) => {
			if (checked) {
				setSelectedStatuses((status: any) => {
					let statusArr: any[] = [...status];
					let filteredStatuses = status.filter((sts: any) => sts._id === item._id);
					if (!filteredStatuses.length) {
						statusArr.push(item);
					}
					return statusArr;
				});
			} else {
				setSelectedStatuses((status: any) => {
					let filteredStatuses = status.filter(
						(status: any) => status._id !== item._id
					);
					return filteredStatuses;
				});
			}
		};
		const dateFilterOptions = [
			{
				label: "Date Created",
				value: "created_on",
			},
			{
				label: "Last Updated",
				value: "updated_on",
			},
			{
				label: "Appointment Date",
				value: "appointment_date",
			},
		];
		const quickDateFilterOptions = [{
			label: "All Time",
			value: "all_time"
		},{
			label: "Custom",
			value: "custom"
		},{
			label: "Today",
			value: "today"
		},{
			label: "Yesterday",
			value: "yesterday"
		},{
			label: "This Week",
			value: "current_week"
		},{
			label: "This Month",
			value: "current_month"
		},{
			label: "This Year",
			value: "current_year"
		}]
		useEffect(()=>{
			const type = dateFilters.selectedQuickDate
			let fromDate = new Date(),
				toDate = new Date()
			if(type === "yesterday"){
				fromDate = toDate = moment().subtract(1, "d").toDate()
			}else if(type === "current_week"){
				fromDate = moment().startOf("week").toDate()
			}else if(type === "current_month"){
				fromDate = moment().startOf("month").toDate()
			}else if(type === "current_year"){
				fromDate = moment().startOf("year").toDate()
			}
			setDateFilters({
				...dateFilters,
				fromDate,
				toDate
			})
		}, [dateFilters.selectedQuickDate])
		useEffect(()=>{
			if(profile?.role === "ADMIN" || profile?.role === "MANAGER"){
				fetchAllSellers()
			}
		}, [profile])
		const isAllTime = dateFilters.selectedQuickDate === "all_time"
		return (
			<Modal
				size={"md"}
				show={open}
				onHide={handleClose}
			>
				<Modal.Header>
					<Modal.Title style={{ textAlign: "center" }}>Filters</Modal.Title>
				</Modal.Header>
				<hr />
				<Modal.Body>
					<FlexboxGrid
						justify="space-between"
						alignment="center"
						className="w-100 row"
					>
						<IonText className="header_text">Filter Date By</IonText>
						<InputPicker
							data={dateFilterOptions}
							style={{ width: 140, borderRadius: 0, margin: "0 10px" }}
							placeholder="Select*"
							onChange={(value) => setDateFilters({
								...dateFilters,
								selectedDateFilter: value
							})}
							value={dateFilters.selectedDateFilter}
							cleanable={false}
						/>
					</FlexboxGrid>
					<FlexboxGrid
						justify="space-between"
						alignment="center"
						className="w-100 row"
					>
						<IonText className="header_text">Quick Date</IonText>
						<InputPicker
							data={quickDateFilterOptions}
							style={{ width: 140, borderRadius: 0, margin: "0 10px" }}
							placeholder="Select*"
							onChange={(value) => setDateFilters({
								...dateFilters,
								selectedQuickDate: value
							})}
							value={dateFilters.selectedQuickDate}
							cleanable={false}
						/>
					</FlexboxGrid>
					<FlexboxGrid
						justify="space-between"
						alignment="center"
						className="w-100 row"
					>
						<IonText className="header_text">From Date</IonText>
						<DatePicker 
							appearance="subtle"
							placeholder="From Date" 
							disabled={isAllTime}
							style={{
								width: 150
							}}
							value={dateFilters.fromDate}
							onChange={(value) => setDateFilters({
								...dateFilters,
								fromDate: value
							})}
							cleanable={false}
						/>
					</FlexboxGrid>
					<FlexboxGrid
						justify="space-between"
						alignment="center"
						className="w-100 row"
					>
						<IonText className="header_text">To Date</IonText>
						<DatePicker 
							appearance="subtle"
							placeholder="To Date" 
							disabled={isAllTime}
							style={{
								width: 150
							}}
							value={dateFilters.toDate}
							onChange={(value) => setDateFilters({
								...dateFilters,
								toDate: value
							})}
							cleanable={false}
						/>
					</FlexboxGrid>
					{(profile?.role === "ADMIN" || profile?.role === "MANAGER") && (
						<FlexboxGrid
							justify="space-between"
							alignment="center"
							className="w-100 row"
						>
							<IonText className="header_text">Lead Owner</IonText>
							<CheckPicker
								data={allSellers.map(s => ({
									value: s._id,
									label: `${s.firstName} ${s.lastName}`
								}))}
								style={{ width: 140, borderRadius: 0, margin: "0 10px" }}
								placeholder="Select Owner(s)"
								onChange={(value) => setSelectedOwner(value)}
								value={selectedOwner}
								cleanable={true}
							/>
						</FlexboxGrid>
					)}
					<FlexboxGrid
						justify="space-between"
						alignment="center"
						className="w-100 row"
					>
						<IonText className="header_text">Lead Statuses</IonText>
						<Button className="" onClick={() => handleSelectAll()}>
							{isSelectAll ? "Deselect all" : "Select All"}
						</Button>
					</FlexboxGrid>
					<FlexboxGrid justify="center" className="row">
						{statuses.map((status, i) => (
							<FlexboxGrid
								justify="center"
								className={`tag-btn-wrapper ${selectedStatuses.filter((sts: any) => sts._id === status._id).length ? "status-selected" : "status-images-wrapper"}`}
								key={i}
								onClick={() => handleSelectRow(status, !selectedStatuses.filter((sts: any) => sts._id === status._id).length)}
							>
								<img
									src={`${API_URL}${status.image}`}
								/>
								<IonText className="status-name">{status.name}</IonText>
							</FlexboxGrid>
						))}
					</FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => {
						onSubmit({
							selectedStatuses,
							dateFilters,
							selectedOwner
						})
						handleClose()
					}} appearance="primary">
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
const mapStateToProps = (state: any) => ({
	statuses: state.statuses.statuses,
	profile: state.profile.profile,
	allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
	fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterLeadModal);
import React, { useEffect, useState } from "react";
import { Nav } from "rsuite";
import { NavLink } from "react-router-dom";
import "./Bottom.css";

const Bottom: React.FC = () => {
  const [active, setActive] = useState("Map");
  const handleSelect = (activeKey: any) => {
    setActive(activeKey);
  };

  return (
    <>
      <Nav
        appearance="subtle"
        justified
        reversed
        active={active}
        onSelect={handleSelect}
        className="navMain"
      >
        <NavLink to="/profile" className={`navItems`}>
          <Nav.Item eventKey="Profile">Profile</Nav.Item>
        </NavLink>
        <NavLink to="/home" className={`navItems`}>
          <Nav.Item eventKey="Map">Map</Nav.Item>
        </NavLink>

        <NavLink to="/Routes" className={`navItems`}>
          <Nav.Item eventKey="Routes">Routes</Nav.Item>
        </NavLink>

        <NavLink to="/leads" className={`navItems`}>
          <Nav.Item eventKey="Leads">Leads</Nav.Item>
        </NavLink>
      </Nav>
    </>
  );
};

export default Bottom;

import React, { useState } from "react";
import { IonText, IonImg } from "@ionic/react";
import {
  FlexboxGrid,
  Button,
  Uploader,
  Steps,
} from "rsuite";
import { FiRefreshCw, FiSettings, FiEdit2 } from "react-icons/fi";
import { useHistory, Link } from "react-router-dom";
import "./Upload.css";
import CSVReader from "react-csv-reader";
import { API_URL } from "../../config";
// components
import Sidebar from "../../components/template/sidebar/Sidebar";

const Upload: React.FC<{}> = () => {
  const history = useHistory();
  const [file, setFile] = useState<any>(null);
  const handleImport = async (data: any, info: any) => {
    setFile(info);
    let headersTemp = [];
    for (const [key, value] of Object.entries(data[0])) {
      headersTemp.push(value);
    }
    sessionStorage.setItem("CSV_HEADERS", JSON.stringify(headersTemp))
    sessionStorage.setItem("CSV_DATA", JSON.stringify(data))
  };
  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <Sidebar title="Upload CSV" />
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center">
            <Link to="/admin/upload-mapping">
              <Button color="blue" disabled={file===null}>Next</Button>
            </Link>
          </FlexboxGrid>
          <FlexboxGrid style={{ marginTop: 20 }}>
            <FlexboxGrid.Item className="UploadModalBtn mw-100">
              <label
                htmlFor="csv_import_btn"
                className="uploader"
                style={{ width: "95vw", height: 200 }}
              >
                <IonImg
                  src="http://simpleicon.com/wp-content/uploads/cloud-upload-1.png"
                  className="uploader_icon"
                />
                <br />
                <IonText className="uploaderText">
                {file !== null ? file?.name : "Click here to upload CSV"}
                </IonText>
              </label>
              <CSVReader
                onFileLoaded={(data, fileInfo) =>
                  handleImport(data, fileInfo)
                }
                onError={(err) => console.log(err)}
                inputId="csv_import_btn"
                inputStyle={{ display: "none" }}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {/* <Uploader
            action="//jsonplaceholder.typicode.com/posts/"
            draggable
            style={{ width: "100%" }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: 10,
                color: "#aaa",
                paddingTop: 100,
                paddingBottom: 100,
              }}
            >
              Click or Drag files to this area to upload CSV
            </div>
          </Uploader>  */}
        </FlexboxGrid>
        <FlexboxGrid style={{ margin: 10 }}>
          <p>
            For an example of a properly formated csv you can download
            <a href={`${API_URL}uploads/aquafeel_sample.csv`} download style={{ marginLeft: 5 }}>
              Sample.csv
            </a>
          </p>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "50px 15px", width: "100%" }}>
          <Steps current={0} style={{ flex: 1 }}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Match Fields" />
            <Steps.Item title="Review" />
          </Steps>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};

export default Upload;

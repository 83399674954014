import { MAP_SET_CENTER } from "./constants";
const initialState = {
  center: null
};
export function mapsReducer(state = initialState, action) {
  switch (action.type) {
    case MAP_SET_CENTER:
      return {
        ...state,
        center: {...action.payload},
      };
    default:
      return state;
  }
}

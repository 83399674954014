import axios from "axios";
import { API_URL } from "../../config";
import { GOOGLE_KEY } from "../../config";
export function _fetchLeads(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}leads/get?user_id=${user_id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteLead(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}leads/delete?id=${id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createLead(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/add`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchLeadDetails(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}leads/details?id=${id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateLead(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/edit`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchLeadsByStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/get-by-status`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchAppointments(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/get-by-month`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function _fetchLocationPredictions(destination) {
  return new Promise((resolve, reject) => {
    let url = `https://cors-handle.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${GOOGLE_KEY}&input=${destination}&types=address`; //components=country:ca&
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchLocationDetails(destination) {
  return new Promise((resolve, reject) => {
    let url = `https://cors-handle.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?address=${destination}&sensor=true&key=${GOOGLE_KEY}`;
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchLocationFromLongLat(geometry) {
  return new Promise((resolve, reject) => {
    let url = `https://cors-handle.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${geometry}&key=${GOOGLE_KEY}`;
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateBulkStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/bulk-status-update`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchAllLeads(limit, offset, params, token) {
  return new Promise((resolve, reject) => {
    let url = `${API_URL}leads/list-all?limit=${limit}&offset=${offset}`
    if(params){
      const u = new URLSearchParams(params).toString();
      url = `${url}&${u}`
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _searchLeads(searchKey, searchValue) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}leads/search?search_key=${searchKey}&search_value=${searchValue}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _filterLeads(params) {
  return new Promise((resolve, reject) => {
    const u = new URLSearchParams(params).toString();
    axios
      .get(`${API_URL}leads/filter?${u}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createLeadBulk(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/add-bulk`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteLeadBulk(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/delete-bulk`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _assignSellerBulk(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}leads/bulk-assign-seller`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
import React from "react";
import { IonPage, IonHeader, IonToolbar, IonContent } from "@ionic/react";

// components
import "./adminView.css";

const AdminView: React.FC<{ children: any }> = ({ children }) => {
  return (
    <IonPage>
      <IonContent className="adminView-Content" fullscreen>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default AdminView;

import axios from "axios";
import { API_URL } from "../../config";
export function _fetchNotifications(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}notifications/list`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createNotification(formData) {
  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}notifications/add`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _deleteNotification(id) {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}notifications/delete?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _editNotification(formData) {
  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}notifications/edit`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
import axios from "axios";
import { API_URL } from "../../config";
export function _fetchStats(limit, offset, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}stats/list?limit=${limit}&offset=${offset}`, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
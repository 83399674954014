import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

//redux
import { connect } from "react-redux";
import {fetchProfile} from "../../stores/profile/actions";
import {fetchStatuses} from "../../stores/status/actions";

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    restricted,
    access,
    scope,
    ...rest
  } = props;
  useEffect(()=>{
    if(props.profile?._id){
      props.fetchProfile(props.profile._id);
      props.fetchStatuses();
    }
  }, [props.profile])
  useEffect(()=>{
    axios.get("https://cors-handle.herokuapp.com/")
  }, [])
  if (access === "public")
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          (props.token !== null) && restricted ? (
            <>
            {(props.profile.role === "ADMIN" || props.profile.role === "MANAGER") ? (
              <Redirect to="/admin/sellers" />
            ) : (
              <Redirect to="/profile" />
            )}
            </>
          ) : (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )
        }
      />
    );
  else if (access === "private")
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          (props.token !== null) ? (
            (scope?.split(",")?.includes(props.profile.role) || props.profile.role === "ADMIN")  ? (
            <Layout>
              <Component {...matchProps} />
            </Layout>
            ) : (
              <>
              {(props.profile.role === "ADMIN" || props.profile.role === "MANAGER") ? (
                <Redirect to="/admin/sellers" />
              ):(
                <Redirect to="/profile" />
              )}
              </>
            )
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
  profile: state.auth.profile,
});
const mapDispatchToProps = {
  fetchProfile,
  fetchStatuses
};
export default connect(mapStateToProps, mapDispatchToProps)(RouteWithLayout);
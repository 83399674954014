import React, { useEffect, useState } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  IconButton,
  Uploader,
  Alert
} from "rsuite";
import { API_URL } from "../../config/index";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./EditStatus.css";

//redux
import { connect } from "react-redux";
import {editStatus} from "../../stores/status/actions";
const EditStatus: React.FC<{
  statuses: any[];
  editStatus: Function;
}> = ({statuses, editStatus}) => {
  const history = useHistory();
  const match = useRouteMatch<any>();
  const [name, setName] = useState<string>("");
  const [image, setImage] = useState<any>(null);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({});

  const handleUpdateStatus = async() =>{
    try{
      if(status?._id){
        setBtnLoading(true);
        let formData = new FormData();
        formData.append("image", image === null ? status.image : image);
        formData.append("name", name);
        formData.append("id", status?._id);
        await editStatus(formData);
        Alert.success("Status updated successfully");
        history.goBack();
      }else{
        Alert.error("Status not found!");
      }
    }catch(err){
      console.log(err)
      Alert.error("Please try again with some different values");
    }finally{
      setBtnLoading(false);
    }
  }

  useEffect(()=>{
    if(statuses.length > 0){
      let id = match?.params?.id;
      let statusTemp = statuses.filter((status:any)=> status._id === id)
      if(statusTemp.length > 0){
        setName(statusTemp[0]?.name);
        setStatus(statusTemp[0])
      }else{
        Alert.error("Status not found!")
      }
    }
  }, [statuses])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Edit Status</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="FlexContainerInner">
          {/* <IonText className="subHeaderTextAddLeads">ADD NOTIFICATIONS</IonText> */}
          <Form 
            className="w-100 formCard" 
            style={{ marginBottom: 20 }}
            onSubmit={handleUpdateStatus}
          >
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Upload Photo</ControlLabel>
              <Uploader
                accept=".png,.gif,.jpg,.jpeg,.webp"
                listType="picture-text"
                multiple={false}
                autoUpload={false}
                draggable
                onChange={(e)=>{
                  setImage(e[0]?.blobFile)
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    color: "#aaa",
                    paddingTop: 100,
                    paddingBottom: 100,
                  }}
                >
                  Click or Drag files to this area to upload
                </div>
              </Uploader>
            </FormGroup>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">
                Add Status Name
              </ControlLabel>
              <FormControl 
                name="text" 
                type="text" 
                className="input" 
                value={name}
                onChange={(value)=> setName(value)}
                required
              />
            </FormGroup>
          {/* */}
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button 
              color="blue" 
              className="btn"
              disabled={btnLoading}
              type="submit"
            >
              {btnLoading ? <IonSpinner name="dots" color="#fff"/> : "Update"}
            </Button>
          </FlexboxGrid>
          </Form>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
});
const mapDispatchToProps = {
  editStatus
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStatus);
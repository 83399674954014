import { ROUTES_LOADING, ROUTES_SET_DATA, ROUTES_SET_DETAILS, ROUTES_REMOVE_ROUTE, ROUTES_SET_FORMDATA, ROUTES_RESET_FORMDATA } from "./constants";
const initialState = {
  loading: false,
  routes: [],
  route: {},
  formData: {
    name: "",
    starting_address: "",
    ending_address: "",
    starting_address_long: "",
    starting_address_lat: "",
    ending_address_long: "",
    ending_address_lat: "",
    leads: [],
    selectedStatuses: []
  }
};
export function routeReducer(state = initialState, action) {
  switch (action.type) {
    case ROUTES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ROUTES_SET_DATA:
      return {
        ...state,
        routes: action.payload,
      };
    case ROUTES_SET_DETAILS:
      return {
        ...state,
        route: action.payload,
      };
    case ROUTES_REMOVE_ROUTE:
      return {
        ...state,
        routes: [...state.routes.filter(route => route._id !== action.payload)],
      };
    case ROUTES_SET_FORMDATA:
      return {
        ...state,
        formData: action.payload
      }
    case ROUTES_RESET_FORMDATA:
      return {
        ...state,
        formData: {
          name: "",
          starting_address: "",
          ending_address: "",
          starting_address_long: "",
          starting_address_lat: "",
          ending_address_long: "",
          ending_address_lat: "",
          leads: [],
          selectedStatuses: []
        }
      }
    default:
      return state;
  }
}

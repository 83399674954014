import React, { useState, useEffect } from "react";
import { IonText, useIonAlert, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  ButtonToolbar,
  IconButton,
  Icon,
} from "rsuite";
import "./Leads.css";
import { useHistory } from "react-router-dom";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import { API_URL } from "../../config/";

//redux
import { connect } from "react-redux";
import { fetchLeads, deleteLead } from "../../stores/leads/actions";

const Leads: React.FC<{
  leads: any[];
  fetchLeads: Function;
  profile: any;
  deleteLead: Function
}> = ({ leads, fetchLeads, profile, deleteLead }) => {
  const history = useHistory();
  const [present] = useIonAlert();
  const [loading, setLoading] = useState<boolean>(true);
  const handleFetchLeads = async () => {
    try {
      await fetchLeads(profile._id);
    } catch (err) {

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (profile?._id) {
      handleFetchLeads();
    }
  }, [profile])
  return (
    <>
      <ButtonToolbar className="home-floating-btns">
        <IconButton
          icon={<Icon icon="plus" />}
          size="lg"
          onClick={() => {
            history.push("/add-leads-3");
          }}
        />
        {/* <IconButton
          icon={<Icon icon="filter" />}
          size="lg"
          onClick={() => {
            history.push("/filter");
          }}
        /> */}
      </ButtonToolbar>
      <FlexboxGrid style={{ height: 70 }} />
      {loading ? (
        <FlexboxGrid justify="center" >
          <IonSpinner name="bubbles" color="#000" />
        </FlexboxGrid>
      ) : (
        <FlexboxGrid justify="center" >
          {leads.length === 0 && (
            <IonText style={{ fontSize: 18, width: "100%", textAlign: "center" }}>No leads found!</IonText>
          )}
          <SwipeableList>
            {leads.map((item, i) => (
              <SwipeableListItem
                key={i}
                swipeLeft={{
                  content: (
                    <FlexboxGrid className="routes-item-delete">
                      {" "}
                      <IonText className="routes-item-delete-text">
                        {" "}
                        Delete Lead{" "}
                      </IonText>{" "}
                    </FlexboxGrid>
                  ),
                  action: () =>
                    present({
                      cssClass: "my-css",
                      header: "Delete Lead",
                      message: "Are you sure you want to delete this Lead. The action is irreversible",
                      buttons: [
                        "Cancel",
                        {
                          text: "Ok", handler: (d) => {
                            deleteLead(item._id)
                          }
                        },
                      ],
                      onDidDismiss: (e) => console.log("did dismiss"),
                    }),
                }}
              >
                <FlexboxGrid
                  className="flex-row"
                  style={{ padding: 15 }}
                  key={i}
                  onClick={() => {
                    history.push(`/edit-leads/${item._id}`);
                  }}
                >
                  <FlexboxGrid justify="center" className="tag-btn-wrapper">
                    <img
                      src={`${API_URL}${item?.status_id?.image}`}
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </FlexboxGrid>
                  <FlexboxGrid className="route_column">
                    <IonText className="textFlex">{item.first_name} {item.last_name}</IonText>
                    <IonText className="textFlex">
                      {item.street_address?.length > 0 ? item.street_address : ""}
                      {/* {item.apt?.length > 0 ? `, ${item.apt}` : ""}
                      {item.city?.length > 0 ? `, ${item.city}` : ""}
                      {item.state?.length > 0 ? `, ${item.state}` : ""}
                      {item.zip?.length > 0 ? `, ${item.zip}` : ""}
                      {item.country?.length > 0 ? `, ${item.country}` : ""} */}
                    </IonText>
                  </FlexboxGrid>
                </FlexboxGrid>
              </SwipeableListItem>
            ))}
          </SwipeableList>
        </FlexboxGrid>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  leads: state.leads.leads,
  profile: state.profile.profile,
});
const mapDispatchToProps = {
  fetchLeads,
  deleteLead
};
export default connect(mapStateToProps, mapDispatchToProps)(Leads);
import React, { useState } from "react";
import { IonText } from "@ionic/react";
import {
  Container,
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  Tag,
  Divider,
  IconButton,
  Icon,
  Checkbox,
  DatePicker,
  Table,
  DateRangePicker,
  InputPicker,
} from "rsuite";
import { FiRefreshCw } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./OfficeResults.css";

// components
import Sidebar from "../../components/template/sidebar/Sidebar";

const OfficeResults: React.FC<{}> = () => {
  const history = useHistory();

  const fakeLargeData = [
    {
      id: 1,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 2,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 3,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 4,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 5,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 6,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 7,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 8,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 9,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
    {
      id: 10,
      name: "Manasa Park",
      dk: 15,
      cm: 15,
      appt: 3,
      demo: 1,
      sale: 0,
      app: "100%",
      dem: "33%",
      cl: "0%",
    },
  ];

  const fakeData2 = [
    {
      label: "Eugenia",
      value: "Eugenia",
      role: "Master",
    },
    {
      label: "Kariane",
      value: "Kariane",
      role: "Master",
    },
    {
      label: "Louisa",
      value: "Louisa",
      role: "Master",
    },
    {
      label: "Marty",
      value: "Marty",
      role: "Master",
    },
    {
      label: "Kenya",
      value: "Kenya",
      role: "Master",
    },
    {
      label: "Hal",
      value: "Hal",
      role: "Developer",
    },
    {
      label: "Julius",
      value: "Julius",
      role: "Developer",
    },
    {
      label: "Travon",
      value: "Travon",
      role: "Developer",
    },
    {
      label: "Vincenza",
      value: "Vincenza",
      role: "Developer",
    },
    {
      label: "Dominic",
      value: "Dominic",
      role: "Developer",
    },
    {
      label: "Pearlie",
      value: "Pearlie",
      role: "Guest",
    },
    {
      label: "Tyrel",
      value: "Tyrel",
      role: "Guest",
    },
    {
      label: "Jaylen",
      value: "Jaylen",
      role: "Guest",
    },
    {
      label: "Rogelio",
      value: "Rogelio",
      role: "Guest",
    },
  ];

  return (
    <>
      <FlexboxGrid className="FlexContainer">
        <Sidebar title='Office Results' />
        <FlexboxGrid className="FlexContainerInner">
          <FlexboxGrid className="subHeader" justify="end" alignItems="center" >
            <IonText className="subHeaderLabel">Date Range:</IonText>
            <DateRangePicker style={{ width: 224 }} />
            <FiRefreshCw className="mx-5" />
            <InputPicker data={fakeData2} style={{ width: 224 }} placeholder="Select Quick Date" />
          </FlexboxGrid>
          <Table
            virtualized
            height={400}
            className="table"
            data={fakeLargeData}
            onRowClick={(data) => {
              console.log(data);
            }}
          >
            <Table.Column width={200} align="center">
              <Table.HeaderCell className='table-row'>Name</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="name" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DK</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="dk" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>CM</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="cm" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>APPT</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="appt" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DEMO</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="demo" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>Sale</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="sale" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>APP%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="app" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>DEM%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="dem" />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell className='table-row'>CL%</Table.HeaderCell>
              <Table.Cell className='table-row' dataKey="cl" />
            </Table.Column>
          </Table>
        </FlexboxGrid>
      </FlexboxGrid>
    </>
  );
};

export default OfficeResults;

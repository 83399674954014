import axios from "axios";
import { API_URL } from "../../config";
export function _fetchRoutes(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}routes/list?user_id=${user_id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteRoute(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}routes/delete?id=${id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createRoute(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}routes/add`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchRouteDetails(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}routes/details?id=${id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateRoute(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}routes/edit`, formData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
import React, { useState, useEffect } from "react";
import { FlexboxGrid, Button, Avatar, Icon, IconButton } from "rsuite";
import { IonText, IonSpinner, IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  FiRefreshCcw,
  FiHome,
  FiHelpCircle,
  FiShield,
  FiLock,
  FiCalendar,
  FiSliders
} from "react-icons/fi";
import "./Profile.css";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";
//redux
import { connect } from "react-redux";
import { authLogout } from "../../stores/auth/actions";
import { fetchAppointments } from "../../stores/leads/actions";
import { uploadAvatar, setLocation } from "../../stores/profile/actions";

const Profile: React.FC<{
  authLogout: Function;
  profile: any;
  loading: boolean;
  fetchAppointments: Function;
  appointments: any[];
  setLocation: Function;
  uploadAvatar: Function;
}> = ({ authLogout, profile, loading, fetchAppointments, appointments, uploadAvatar, setLocation }) => {
  const history = useHistory();
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);

  const getInitials = (name: any) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  }
  const handleChangeMonth = (date: any) => {
    var begin = moment(date).format("YYYY-MM-01");
    var end = moment(date).format("YYYY-MM-") + moment().daysInMonth();
    fetchAppointments({ date_start: begin, date_end: end, user_id: profile._id })
  }
  const fetchEventsForDay = (date: any) => {
    const filteredEvents = appointments.filter((appointment: any) => moment(appointment.appointment_date).isSame(date))
    setSelectedEvents([...filteredEvents]);
  }
  const setCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
    async position => {
      console.log(position.coords);
      setLocation({longitude: position.coords.longitude, latitude: position.coords.latitude})
    },
    err => console.log(err));
  }
  const handleUploadAvatar = (e: any) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    uploadAvatar(formData, profile._id);
  }
  useEffect(() => {
    if (profile?._id) {
      var begin = moment().format("YYYY-MM-01");
      var end = moment().format("YYYY-MM-") + moment().daysInMonth();
      fetchAppointments({ date_start: begin, date_end: end, user_id: profile._id });
      setCurrentLocation();
    }
  }, [profile])
  useEffect(() => {
    let eventsTemp: any[] = [];
    appointments.map((appoint: any) => {
      eventsTemp.push({
        id: appoint._id,
        // title: `${appoint.first_name} ${appoint.last_name}`,
        title: <FiCalendar />,
        allDay: true,
        start: new Date(appoint.appointment_date),
        end: new Date(appoint.appointment_date),
      })
    })
    setEvents([...eventsTemp]);
  }, [appointments])
  return (
    <>
      <FlexboxGrid className="profile">
        {/* <ButtonToolbar className="home-floating-btns">
          <IconButton
            icon={<Icon icon="plus" />}
            size="lg"
            onClick={() => {
              history.push("/add-leads-3");
            }}
          />
          <IconButton
            icon={<Icon icon="filter" />}
            size="lg"
            onClick={() => {
              history.push("/filter");
            }}
          />
        </ButtonToolbar> */}
        <FlexboxGrid style={{ height: 0 }} />
        {loading && (
          <div className="loading-wrapper">
            <IonSpinner name="bubbles" />
          </div>
        )}
        <FlexboxGrid style={{ margin: "0px auto" }}>
          {profile.avatar?.length > 0 ? (
            <label  style={{ position: "relative" }} htmlFor="avatar-input">
              <img src={`${API_URL}uploads/${profile.avatar}`} alt="" className="profile-image" />
              <Icon icon='camera' className="camera-icon" style={{left: "50%"}} />
              <input type="file" id="avatar-input" onChange={handleUploadAvatar} />
            </label>
          ) : (
            <label style={{ position: "relative" }} htmlFor="avatar-input">
              <Avatar
                className="profile-image"
                style={{ background: "#edfae1", color: "#4caf50" }}
              >
                {getInitials(`${profile.firstName} ${profile.lastName}`)}
                <Icon icon='camera' className="camera-icon" />
                <input type="file" id="avatar-input" onChange={handleUploadAvatar} />
              </Avatar>
            </label>
          )}
          <FlexboxGrid
            style={{
              marginLeft: 15,
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingTop: 15,
            }}
          >
            <IonText className="profile-name">{profile.firstName} {profile.lastName}</IonText>
            <IonText className="profile-email">{profile.email}</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <FlexboxGrid className="profile-Horizontal-Scroll" >
          {selectedEvents.map((item: any, i: number) => (
            <Link to={`/edit-leads/${item._id}`} key={i}>
              <div className="profile-Horizontal-Scroll-items"  >
                <Icon icon='calendar' size="lg" style={{ color: "#3174ad", marginRight: 10 }} />
                <div className="profile-Horizontal-Scroll-items-inner"  >
                  <IonText className="profile-Horizontal-Scroll-items-inner-title">{`${item.first_name} ${item.last_name}`}</IonText>
                  <IonText className="profile-Horizontal-Scroll-items-inner-subtitle">{moment(item.appointment_time).format("h:mm A")}</IonText>
                </div>
              </div>
            </Link>
          ))}
        </FlexboxGrid>
        <FlexboxGrid style={{ height: "45%", margin: "25px auto" }}>
          <Calendar
            step={60}
            showMultiDayTimes
            defaultDate={new Date()}
            localizer={localizer}
            selectable={true}
            events={events}
            views={['month']}
            onSelectEvent={(event: any) => {
              fetchEventsForDay(event.start)
            }}
            onSelectSlot={(event: any) => {
              fetchEventsForDay(event.start)
            }}
            onNavigate={(date) => {
              handleChangeMonth(date)
            }}
          />
        </FlexboxGrid>

        <Button
          onClick={() => {
            history.push("/edit-profile");
          }}
          className="homebtn"
        >
          <Icon icon="edit" className="homebtnIcon" /> Edit Profile
        </Button>
        <Button
          onClick={() => {
            history.push("/change-password");
          }}
          className="homebtn"
        >
          <FiRefreshCcw className="homebtnIcon" /> Change Password
        </Button>
        {(profile.role === "ADMIN" || profile.role === "MANAGER") && (
          <Button
            onClick={() => {
              history.push("/admin/sellers");
            }}
            className="homebtn"
          >
            <FiSliders className="homebtnIcon" /> Go to Admin
          </Button>
        )}
        {/* <Button
          onClick={() => {
            history.push("/");
          }}
          className="homebtn"
        >
          <FiHome className="homebtnIcon" /> About Us
        </Button>
        <Button href="mailto:example@email.com" className="homebtn">
          <FiHelpCircle className="homebtnIcon" /> Support
        </Button>
        <Button
          onClick={() => {
            history.push("/");
          }}
          className="homebtn"
        >
          <FiShield className="homebtnIcon" /> Terms and Condition
        </Button>
        <Button
          onClick={() => {
            history.push("/");
          }}
          className="homebtn"
        >
          <FiLock className="homebtnIcon" /> Privacy Policy
        </Button> */}
        <Button
          onClick={() => {
            authLogout()
          }}
          className="homebtn"
        >
          <Icon icon="sign-out" className="homebtnIcon" /> Logout
        </Button>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  appointments: state.leads.appointments,
});
const mapDispatchToProps = {
  authLogout,
  fetchAppointments,
  setLocation,
  uploadAvatar
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
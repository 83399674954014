import React, { useState, useEffect } from "react";
import { useIonAlert } from "@ionic/react";
import Highlighter from "react-highlight-words";
import {
	FlexboxGrid,
	Form,
	FormControl,
	Button,
	Checkbox,
	Table,
	InputPicker,
	Pagination,
} from "rsuite";
import { FiSettings, FiEdit2, FiTrash } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { useHistory, Link } from "react-router-dom";
import "./Leads.css";
import { API_URL } from "../../config";
import moment from "moment";
// components
import Sidebar from "../../components/template/sidebar/Sidebar";
import FilterLeadModal from "./FilterLead";
import BulkStatusUpdateModal from "./BulkStatusUpdateModal";
//redux
import { connect } from "react-redux";
import {
	fetchAllLeads,
	leadsSetPage,
	searchLeads,
	deleteLead,
	deleteLeadBulk,
	filterLeads
} from "../../stores/leads/actions";

const LeadsAdmin: React.FC<{
	leadCount: number,
	limit: number,
	offset: number,
	activePage: number,
	fetchAllLeads: Function,
	leadsSetPage: Function,
	leads: any[],
	loading: boolean,
	searchLeads: Function,
	statuses: any[],
	deleteLead: Function,
	deleteLeadBulk: Function;
	filterLeads: Function
}> = ({
	leadCount,
	limit,
	offset,
	activePage,
	fetchAllLeads,
	leadsSetPage,
	leads,
	loading,
	searchLeads,
	statuses,
	deleteLead,
	deleteLeadBulk,
	filterLeads
}) => {
		const history = useHistory();
		const [present] = useIonAlert();
		const [selectedRows, setSelectedRows] = useState<any[]>([]);
		const [isCheckedAll, setCheckedAll] = useState<boolean>(false);
		const [leadsData, setLeadsData] = useState<any[]>([]);
		const [selectedSearchKey, setSelectedSearchKey] = useState<string>("all");
		const [searchValue, setSearchValue] = useState<string>("");
		const [statusData, setStatusData] = useState<any[]>([]);
		const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false)
		const [bulkStatusUpdateModalOpen, setBulkStatusUpdateModalOpen] = useState<boolean>(false)
		const [filters, setFilters] = useState({})
		const searchKey = [
			{
				label: "All",
				value: "all",
			},
			// {
			// 	label: "Status",
			// 	value: "status_id",
			// },
			{
				label: "First name",
				value: "first_name",
			},
			{
				label: "Last name",
				value: "last_name",
			},
			{
				label: "Address",
				value: "street_address",
			},
			{
				label: "State",
				value: "state",
			},
			{
				label: "City",
				value: "city",
			},
			{
				label: "Postal code",
				value: "zip",
			},
			// {
			// 	label: "Lead owner",
			// 	value: "owned_by",
			// },
		];
		const handlePageChange = (pageNumber: number) => {
			let newOffset = ((pageNumber - 1) * limit);
			let _filters:any = filters
			if (searchValue.length >= 2) {
				_filters.search_key = selectedSearchKey
				_filters.search_value = searchValue
			}
			fetchAllLeads(limit, newOffset, _filters);
			leadsSetPage({
				activePage: pageNumber,
				offset: newOffset,
				limit: 50
			})
		};
		const formatHighlighter = (
			text: string,
			searchKey: string,
		) => {
			let searchWords = [searchKey];
			return (
				<Highlighter
					searchWords={searchWords}
					autoEscape={true}
					textToHighlight={String(text)}
					highlightStyle={{ background: "#ff0" }}
				/>
			);
		};
		const handleRefresh = async () => {
			await fetchAllLeads(50, 0);
			leadsSetPage({
				activePage: 1,
				offset: 0,
				limit: 50
			})
			setSearchValue("")
			setSelectedSearchKey("all")
		}
		const handleDeleteLead = (item: any) => {
			present({
				cssClass: "my-css",
				header: "Delete Lead",
				message: "Are you sure you want to delete this lead? This action is irreversible",
				buttons: [
					"Cancel",
					{
						text: "Ok", handler: (d) => {
							deleteLead(item.id)
						}
					},
				],
				onDidDismiss: (e) => console.log("did dismiss"),
			})
		}
		const handleBulkDelete = async () => {
			present({
				cssClass: "my-css",
				header: "Delete Leads",
				message: "Are you sure you want to delete these leads? This action is irreversible",
				buttons: [
					"Cancel",
					{
						text: "Ok", handler: async (d) => {
							await deleteLeadBulk({ leadIds: selectedRows.map(row => row.id) })
							handleRefresh();
						}
					},
				],
				onDidDismiss: (e) => console.log("did dismiss"),
			})
		}
		const handleSelectRow = (item: any, checked: boolean) => {
			if (checked) {
				setSelectedRows((lead) => {
					let leadsArr = [...lead];
					let filteredLeads = lead.filter((prp) => prp.id === item.id);
					if (!filteredLeads.length) {
						leadsArr.push(item);
					}
					return leadsArr;
				});
			} else {
				setSelectedRows((lead) => {
					let filteredLeads = lead.filter(
						(prop) => prop.id !== item.id
					);
					return filteredLeads;
				});
			}
		};
		const handleSelectAll = (checked: boolean) => {
			if (checked) {
				setSelectedRows(leadsData);
			} else {
				setSelectedRows([]);
			}
		};
		const handleSetFilters = (filters: any) => {
			let params = {}
			if (filters.dateFilters?.selectedQuickDate !== "all_time") {
				params = {
					field: filters.dateFilters?.selectedDateFilter,
					fromDate: moment(filters.dateFilters?.fromDate).format("YYYY-MM-DD"),
					toDate: moment(filters.dateFilters?.toDate).format("YYYY-MM-DD"),
				}
			}
			if (Array.isArray(filters?.selectedStatuses) && filters?.selectedStatuses?.length) {
				params = {
					...params,
					status_id: filters.selectedStatuses?.map((f: any) => f._id)?.join(",")
				}
			}
			if (filters?.selectedOwner?.length) {
				params = {
					...params,
					owner_id: filters?.selectedOwner
				}
			}
			// filterLeads(params)
			leadsSetPage({
				activePage: 1,
				offset: 0,
				limit: 50
			})
			fetchAllLeads(limit, 0, params);
			setFilters(params)
		}
		useEffect(() => {
			fetchAllLeads(limit, offset);
		}, []);
		useEffect(() => {
			let usersTemp: any[] = [];
			leads.map((lead: any) => {
				usersTemp.push({
					id: lead._id,
					statusImage:
						<div>
							<img src={`${API_URL}${lead.status_id?.image}`} className="lead-status-img" />
							<small style={{ marginLeft: 10 }}>{lead.status_id?.name}</small>
						</div>,
					firstName: lead.first_name,
					lastName: lead.last_name,
					address: lead.street_address,
					state: lead.state,
					city: lead.city,
					postalCode: lead.zip,
					leadOwner: lead.owned_by,
					apointment: moment(lead.appointment_date).isValid() ? `${moment(lead.appointment_date).format("DD/MM/YYYY")}, ${moment(lead.appointment_time).format("h:mm A")}` : "N/A",
					dateCreated: moment(lead.created_on).format("DD/MM/YYYY, h:mm A"),
					lastUpdated: moment(lead.created_on).format("DD/MM/YYYY, h:mm A"),
					created_by: lead.created_by
				})
			})
			setLeadsData([...usersTemp])
			handleSelectAll(isCheckedAll)
		}, [leads])
		useEffect(() => {
			let statusTemp: any[] = [];
			statuses.map((status: any) => {
				statusTemp.push({
					label: status.name,
					value: status._id
				})
			})
			setStatusData([...statusTemp])
		}, [statuses])
		useEffect(() => {
			const timeout = setTimeout(() => {
				if (searchValue.length >= 2) {
					fetchAllLeads(limit, offset, {search_key: selectedSearchKey, search_value: searchValue})
				} else {
					handleRefresh()
				}
			}, 1000);
			return () => clearTimeout(timeout);
		}, [searchValue, selectedSearchKey]);
		return (
			<>
				<FlexboxGrid className="FlexContainer">
					<Sidebar title="Leads" />
					<FlexboxGrid className="FlexContainerInner">
						<FlexboxGrid className="filterMenu" justify="space-between" alignItems="center">
							<FaFilter
								color="#1F4690"
								size={20}
								onClick={() => setFilterModalOpen(true)}
							/>
							<p>Total Leads: {leadCount}</p>
							<div>
								<Link to="/admin/add-leads">
									<Button color="blue">New</Button>
								</Link>
								<Button
									disabled={selectedRows.length === 0}
									color="blue"
									onClick={()=>setBulkStatusUpdateModalOpen(true)}
									style={{
										marginLeft: 10
									}}>
									Update Status
								</Button>
								<Button
									disabled={selectedRows.length === 0}
									color="red"
									onClick={handleBulkDelete}
									style={{
										marginLeft: 10
									}}>
									Delete Bulk
								</Button>
							</div>
						</FlexboxGrid>
						<Form className="w-100">
							<div
								className='bg-primary w-100'
								style={{
									padding: 10,
									display: "flex",
									justifyContent: "center"
								}}>
								<FormControl
									name="text"
									type="text"
									className="input"
									placeholder="Search*"
									value={searchValue}
									onChange={(value) => setSearchValue(value)}
								/>
								<InputPicker
									data={searchKey}
									style={{ width: 140, borderRadius: 0, margin: "0 10px" }}
									placeholder="Select*"
									onChange={(value) => setSelectedSearchKey(value)}
									value={selectedSearchKey}
									cleanable={false}
								/>
							</div>
						</Form>
						<Table
							virtualized
							height={600}
							className="table"
							data={leadsData}
							loading={loading}
							style={{ paddingBottom: 20 }}
						>
							<Table.Column width={50} align="center" fixed>
								<Table.HeaderCell className="table-row">
									<Checkbox
										style={{ marginTop: -7, width: 50, height: 15 }}
										onChange={(value, checked) => {
											handleSelectAll(checked);
											setCheckedAll(checked)
										}}
									></Checkbox>
								</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => {
										return (
											<Checkbox
												style={{
													marginLeft: 0,
													width: 50,
													height: 15,
													marginTop: -7,
												}}
												onChange={(value, checked) => handleSelectRow(rowData, checked)}
												checked={selectedRows.filter((sn) => sn.id === rowData.id).length ? true : false}
											></Checkbox>
										)
									}}
								</Table.Cell>
							</Table.Column>
							<Table.Column width={100} align="center">
								<Table.HeaderCell className="table-row">Status</Table.HeaderCell>
								<Table.Cell dataKey="statusImage" className="table-row" />
							</Table.Column>

							<Table.Column width={150}>
								<Table.HeaderCell className="table-row">
									First Name
								</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.firstName, searchValue)}
								</Table.Cell>
							</Table.Column>

							<Table.Column width={150}>
								<Table.HeaderCell className="table-row">
									Last Name
								</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.lastName, searchValue)}
								</Table.Cell>
							</Table.Column>

							<Table.Column width={200}>
								<Table.HeaderCell className="table-row">Address</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.address, searchValue)}
								</Table.Cell>
							</Table.Column>

							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">State</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.state, searchValue)}
								</Table.Cell>
							</Table.Column>

							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">City</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.city, searchValue)}
								</Table.Cell>
							</Table.Column>

							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">
									Postal Code
								</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.postalCode, searchValue)}
								</Table.Cell>
							</Table.Column>
							{/* <Table.Column width={150}>
								<Table.HeaderCell className="table-row">
									Lead Owner
								</Table.HeaderCell>
								<Table.Cell>
									{(rowData: any) => formatHighlighter(rowData.leadOwner, searchValue)}
								</Table.Cell>
							</Table.Column> */}
							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">
									Apointment
								</Table.HeaderCell>
								<Table.Cell className="table-row" dataKey="apointment" />
							</Table.Column>
							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">
									Lead Owner
								</Table.HeaderCell>
								<Table.Cell >
									{(rowData: any) => {
										return (
											<>
												<Link to={`/admin/edit-user/${rowData?.created_by?._id}`}>
													{`${rowData?.created_by?.firstName} ${rowData?.created_by?.lastName}`}
												</Link>
											</>
										);
									}}
								</Table.Cell>
							</Table.Column>
							<Table.Column width={200}>
								<Table.HeaderCell className="table-row">
									Date Created
								</Table.HeaderCell>
								<Table.Cell className="table-row" dataKey="dateCreated" />
							</Table.Column>

							<Table.Column width={200}>
								<Table.HeaderCell className="table-row">
									Last Updated
								</Table.HeaderCell>
								<Table.Cell className="table-row" dataKey="lastUpdated" />
							</Table.Column>

							<Table.Column width={100}>
								<Table.HeaderCell className="table-row">
									<FiSettings />
								</Table.HeaderCell>
								<Table.Cell >
									{(rowData: any) => {
										return (
											<>
												<Link to={`/admin/edit-leads/${rowData.id}`}>
													<Button style={{ marginTop: -7, borderRadius: 0 }}>
														<FiEdit2 />
													</Button>
												</Link>
												<Button
													style={{ marginTop: -7, borderRadius: 0 }}
													onClick={() => handleDeleteLead(rowData)}
												>
													<FiTrash color={"#f00"} />
												</Button>
											</>
										);
									}}
								</Table.Cell>
							</Table.Column>
						</Table>
						<FlexboxGrid className="pagination-wrapper" justify="end" alignItems="center">
							<Pagination
								pages={Math.ceil(leadCount / limit) || 1}
								activePage={activePage}
								prev
								last
								next
								first
								onSelect={handlePageChange}
								maxButtons={10}
							/>
						</FlexboxGrid>
					</FlexboxGrid>
				</FlexboxGrid>
				<FilterLeadModal
					open={filterModalOpen}
					handleClose={() => setFilterModalOpen(false)}
					onSubmit={handleSetFilters}
				/>
				<BulkStatusUpdateModal 
					open={bulkStatusUpdateModalOpen}
					handleClose={() => setBulkStatusUpdateModalOpen(false)}
					selectedRows={selectedRows}
				/>
			</>
		);
	};
const mapStateToProps = (state: any) => ({
	leadCount: state.leads.count,
	limit: state.leads.limit,
	offset: state.leads.offset,
	activePage: state.leads.activePage,
	leads: state.leads.allLeads,
	loading: state.leads.loading,
	statuses: state.statuses.statuses,
});
const mapDispatchToProps = {
	fetchAllLeads,
	leadsSetPage,
	searchLeads,
	deleteLead,
	deleteLeadBulk,
	filterLeads
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsAdmin);
import axios from "axios";
import { API_URL } from "../../config";
export function _fetchUsers(limit, offset) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}users/list?limit=${limit}&offset=${offset}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createUser(formData) {
  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}users/add`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _deleteUser(id) {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}users/delete?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _changeUser(id) {
  return new Promise((resolve, reject) => {
    axios
    .put(`${API_URL}users/enable?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _editUser(formData) {
  return new Promise((resolve, reject) => {
    axios
    .put(`${API_URL}users/edit`, formData)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _fetchUserDetails(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}users/details?id=${id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchAllSellers(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}users/list-all-sellers`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _getLeadsCount(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}users/get-leads-count?id=${user_id}`)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteUserWithAllRecord(id) {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}users/delete-all-record?id=${id}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _deleteUserWithReassignLeads(id, assign_to) {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}users/reassign-and-delete?id=${id}&assign_to=${assign_to}`)
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
import React, { useEffect, useState } from "react";
import { IonText, IonSpinner } from "@ionic/react";
import {
  FlexboxGrid,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  IconButton,
  DatePicker,
  Alert,
  InputPicker
} from "rsuite";
import AutoCompleteComponent from "../../components/common/AutoComplete";
import { FiArrowLeft } from "react-icons/fi";
import { IoLocateOutline } from "react-icons/io5";

import { useHistory } from "react-router-dom";
import "./AddLeadsAdmin.css";
import { API_URL } from "../../config/index";

//redux
import { connect } from "react-redux";
import { createLead, fetchLocationFromLongLat } from "../../stores/leads/actions";
import {fetchUsers, fetchAllSellers} from "../../stores/users/actions";

const AddLeadsAdmin: React.FC<{
  createLead: Function;
  statuses: any[];
  fetchLocationFromLongLat: Function;
  fetchUsers: Function;
  users: any[];
  fetchAllSellers: Function;
  profile:any;
  allSellers: any[];
}> = ({ createLead, statuses, fetchLocationFromLongLat, users, fetchUsers, fetchAllSellers, profile, allSellers }) => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    business_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    phone2: "",
    email: "",
    street_address: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    longitude: "",
    latitude: "",
    appointment_date: "",
    appointment_time: "",
    status_id: "",
    owned_by: "",
    note: "",
    user_id: ""
  })
  const [usersData, setUsersData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sellerMod, setSellerMod] = useState<any[]>([]);
  const [selectedSellerId, setSelectedSellerId] = useState<string>("");

  const handleCreateLead = async () => {
    try {
      if(state.user_id === ""){
        Alert.warning("You have to choose a seller to assign this lead");
        return;
      }
      if(state.status_id === ""){
        Alert.warning("You have to choose a status for this lead");
        return;
      }
      setLoading(true);
      await createLead(state);
      Alert.success('Lead created successfully!');
      history.goBack();
    } catch (err) {
      Alert.error('Something went wrong!')
    } finally {
      setLoading(false);
    }
  }
  const fetchCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      async position => {
        const resp = await fetchLocationFromLongLat(`${position.coords.latitude}, ${position.coords.longitude}`);
        setState({
          ...state,
          city: resp?.city,
          state: resp?.state,
          zip: resp?.postalCode,
          country: resp?.country,
          street_address: resp?.location,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      },
      err => console.log(err));
  }
  useEffect(() => {
    fetchUsers(100, 0);
  }, []);
  useEffect(()=>{
    let usersTemp:any[] = [];
    users.filter((user:any)=> user.role === "SELLER").map((user:any)=>{
      usersTemp.push({
        label: `${user.firstName} ${user.lastName}`,
        value: user._id
      })
    })
    setUsersData([...usersTemp])
  }, [users])
  useEffect(()=>{
    if(profile?.role === "ADMIN" || profile?.role === "MANAGER"){
      fetchAllSellers()
    }
  }, [profile])
  useEffect(()=>{
    let sellersTemp:any=[];
    allSellers.map((seller:any)=>{
      sellersTemp.push({
        value: seller._id,
        label: `${seller.firstName} ${seller.lastName}`
      })
    })
    setSellerMod(sellersTemp)
  }, [allSellers])
  return (
    <>
      <FlexboxGrid className="AddLeads">
        <FlexboxGrid className="header">
          <FlexboxGrid className="">
            <IconButton
              className="back-btn"
              icon={<FiArrowLeft style={{ fontSize: 18 }} />}
              circle
              size="xs"
              onClick={() => {
                history.goBack();
              }}
            />
            <IonText className="header_text">Add Leads</IonText>
          </FlexboxGrid>
        </FlexboxGrid>
        <Form
          className="w-100"
          style={{ marginBottom: 20 }}
          onSubmit={handleCreateLead}
        >
          <FlexboxGrid className="AddLeadsInner">
            <IonText className="subHeaderTextAddLeads">CONTACT INFO</IonText>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Business Name</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.business_name}
                onChange={(value) => setState({ ...state, business_name: value })}
              />
            </FormGroup>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">First Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.first_name}
                    onChange={(value) => setState({ ...state, first_name: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Last Name</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.last_name}
                    onChange={(value) => setState({ ...state, last_name: value })}
                    required
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">
                    Phone Number
                  </ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.phone}
                    onChange={(value) => setState({ ...state, phone: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">
                    Alternative Number
                  </ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.phone2}
                    onChange={(value) => setState({ ...state, phone2: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FormGroup className="w-100">
              <ControlLabel className="inputLable">Email</ControlLabel>
              <FormControl
                name="text"
                type="text"
                className="input"
                value={state.email}
                onChange={(value) => setState({ ...state, email: value })}
              />
            </FormGroup>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">
                    Street Address
                    <IoLocateOutline size={20}
                      style={{
                        marginLeft: 10,
                        color: "#1F4690",
                        border: "1px solid rgb(33, 150, 243)",
                        borderRadius: "50%"
                      }}
                      onClick={() => fetchCurrentLocation()}
                    />
                  </ControlLabel>
                  <AutoCompleteComponent
                    className="input"
                    onSelect={(value: any) => {
                      setState({ ...state, city: value?.city, state: value?.state, zip: value?.postalCode, country: value?.country, longitude: value?.geometry?.lng, latitude: value?.geometry?.lat, street_address: value?.street_address })
                    }}
                    value={state.street_address}
                    onChange={(value:any) => setState({ ...state, street_address: value })}
                    placeholder=""
                  />
                  {/* <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.street_address}
                    onChange={(value) => setState({ ...state, street_address: value })}
                  /> */}
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Apt/Suite</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.apt}
                    onChange={(value) => setState({ ...state, apt: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">City</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.city}
                    onChange={(value) => setState({ ...state, city: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">State</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.state}
                    onChange={(value) => setState({ ...state, state: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100" style={{ marginBottom: 20 }}>
                  <ControlLabel className="inputLable">Zip Code</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.zip}
                    onChange={(value) => setState({ ...state, zip: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <FormGroup className="w-100">
                  <ControlLabel className="inputLable">Country</ControlLabel>
                  <FormControl
                    name="text"
                    type="text"
                    className="input"
                    value={state.country}
                    onChange={(value) => setState({ ...state, country: value })}
                  />
                </FormGroup>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <IonText className="subHeaderTextAddLeads">
              APOINTMENT/CALLBACK TIME
            </IonText>
            <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <DatePicker
                  onChange={(value) => setState({ ...state, appointment_date: value })}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={{ width: "47%" }}>
                <DatePicker
                  format="HH:mm"
                  onChange={(value) => setState({ ...state, appointment_time: value })}
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <IonText className="subHeaderTextAddLeads">STATUS</IonText>
            <FlexboxGrid
              className="status-wrapper"
              justify="start"
              alignment="center"
              style={{ padding: 0 }}
            >
              {statuses.map((status, i) => (
                <FlexboxGrid
                  justify="center"
                  className={`tag-btn-wrapper ${state.status_id === status._id ? "status-selected" : ""}`}
                  key={i}
                  onClick={() => setState({ ...state, status_id: status._id })}
                >
                  <img
                    src={`${API_URL}${status.image}`}
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: 5,
                      borderRadius: "50%",
                    }}
                  />
                  <IonText>{status.name}</IonText>
                </FlexboxGrid>
              ))}
            </FlexboxGrid>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Note</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  onChange={(value) => setState({ ...state, note: value })}
                  value={state.note}
                />
              </FormGroup>
            </Form>
            {/* <IonText className="subHeaderTextAddLeads">LEAD OWNERSHIP</IonText>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Owned by</ControlLabel>
                <FormControl
                  name="text"
                  type="text"
                  className="input"
                  placeholder="Jorge Martinez"
                  onChange={(value) => setState({ ...state, owned_by: value })}
                  value={state.owned_by}
                />
              </FormGroup>
            </Form> */}
            <IonText className="subHeaderTextAddLeads">ASSIGN TO SELLER</IonText>
            <Form className="w-100" style={{ marginBottom: 20, marginTop: 15 }}>
              <FormGroup className="w-100">
                <ControlLabel className="inputLable">Choose seller</ControlLabel>
                <InputPicker
                  data={sellerMod}
                  style={{ width: 150 }}
                  placeholder="Select*"
                  onChange={(value) => setState({ ...state, user_id: value })}
                  key={JSON.stringify(sellerMod)}
                  className="input"
                />
              </FormGroup>
            </Form>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            className="w-100"
            style={{ margin: "15px 0px" }}
          >
            <Button
              color="blue"
              className="btn"
              type="submit"
              disabled={loading}
            >
              {loading ? <IonSpinner name="dots" /> : "Add"}
            </Button>
          </FlexboxGrid>
        </Form>
      </FlexboxGrid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  statuses: state.statuses.statuses,
  users: state.users.users,
  profile: state.profile.profile,
  allSellers: state.users.allSellers,
});
const mapDispatchToProps = {
  createLead,
  fetchLocationFromLongLat,
  fetchUsers,
  fetchAllSellers
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLeadsAdmin);